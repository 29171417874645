import { HttpClient, HttpParams } from "@angular/common/http";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AuthEnum, FormsStateEnum,UserRole } from "app/enums/app.enums";
import * as Survey from "survey-angular";
import * as deLang from "../../assets/language/language_de.json";
import * as enLang from "../../assets/language/language_en.json";
import * as frLang from "../../assets/language/language_fr.json";
import * as SurveyAnalytics from "../surveyanalytics/survey.analytics";
import { DataTables } from "survey-analytics/survey.analytics.datatables.js";

declare function ShowLoading();
declare function HideLoading();
declare function setTheme(obj);
declare var $: any;
declare var APIURL;
declare var configJson;
declare var objforCloseButton;
Survey.JsonObject.metaData.addProperty("question", { name: "marks:number" });
Survey.JsonObject.metaData.addProperty("itemvalue", { name: "marks:number" });
enum FormType {
  POLL = 0,
  QUIZ = 1,
}
var json = {
  elements: [
    {
      type: "text",
      inputType: "text",
      name: "code",
    },
  ],
};
var options1 = {
  answersOrder: "reverse",
  haveCommercialLicense: true,
};
// var CurrentLangStrings;
@Component({
  selector: "survey-analytics",
  templateUrl: "./survey.analytics.component.html" ,
  styleUrls: ['./survey.analytics.component.css'],
  host: { 'id': 'analytics-container' }
})
export class SurveyAnalyticsComponent implements OnInit {

  @Input() json: any;
  @Output() surveySaved: EventEmitter<Object> = new EventEmitter();
  access_token: "";
  analyticsResponses = [];
  counter = 1;
  dataBindingMaxTime = 10;
  dataBindingMinTime = 5;
  dataBindingTimeout;
  editsurveyId: "";
  formResultJson;
  formType: any = 0;
  myLanguage = "en";
  objForClose: any;
  otherResponses = [];
  preventDefaultLoad = false;
  responseReceived = 0;
  scrollPosition = null;
  horizontalScrollPosition=null;
  surveyName: "";
  surveyTitle: "";
  rbcFormsObj;
  apiResponse;
  btnLoader=false;
  isExportedToCsv=false;
  analyticsResultHeight;
  currentTabIndex=0;
  CurrentLangStrings
  userRole=UserRole;
  questionJson;
  normalizeData;
  apiData;
  surveyQuestions;
  refresh_token="";
  KeyClaokApiUrl;


  constructor(private http: HttpClient) {
    window["getAuthDetails"] = this.getAuthDetails;
  }

  getAuthDetails = (obj) => {
    try {
      if(obj && obj.theme){
        setTheme(obj.theme);
      this.rbcFormsObj=obj;
      console.debug("[this.rbcFormsObj]::::",this.rbcFormsObj);
      if(this.rbcFormsObj.userrole==UserRole.STUDENT || this.rbcFormsObj.userrole==UserRole.GUEST){
        const tabs=document.getElementsByClassName('mat-tab-header');
        const exportBtn=document.getElementById('export-csv-parent');
        if(exportBtn){
          exportBtn.classList.add('d-none');
        }
        if(tabs[0]){
          tabs[0].classList.add('d-none');
        }
      }
      }
      this.access_token = obj.access_token;
      this.refresh_token = obj.refresh_token;
      this.editsurveyId = obj.id;
      this.formType = obj.type;
      try {
        if (this.formType == FormType.POLL)
          document.getElementById("headerIcon").innerHTML =
            '<svg class="svgFormIconwhite"><use xlink:href="assets/sprite.svg#polling_icon"></use></svg>';
        else if (this.formType == FormType.QUIZ)
          document.getElementById("headerIcon").innerHTML =
            '<svg class="svgFormIconwhite"><use xlink:href="assets/sprite.svg#quiz_icon"></use></svg>';
      } catch { }

      this.objForClose = { interactionid: obj.id, state: FormsStateEnum.CLOSED };
      objforCloseButton = this.objForClose;
      this.OnLoadCreator(this.access_token, obj.id, obj.state);
      if (this.myLanguage == "en") this.CurrentLangStrings = enLang;
      else if (this.myLanguage == "fr") this.CurrentLangStrings = frLang;
      else if (this.myLanguage == "de") this.CurrentLangStrings = deLang;
      // }
    } catch (e) {
      // console.error(e);
    }
  };
  isJson(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return {};
    }
    return JSON.parse(str);
  }
  renewSessionAuthorization() {
    var retry = 0;
    const body = new HttpParams()
      .set('grant_type', AuthEnum.GRANTTYPE_REFRESHTOKEN)
      .set('client_id', AuthEnum.CLIENTID)
      .set('client_secret', AuthEnum.CLIENTSECRET)
      .set('refresh_token', this.refresh_token);
    return new Promise((resolve, reject) => {
      try {
        var request = new XMLHttpRequest();
        request.onreadystatechange = () => {
          if (request.readyState === 4) {
            var response = this.isJson(request.response);
            if (request.status === 200 && response.access_token) {
              this.access_token = response.access_token;
            this.refresh_token = response.refresh_token;
              resolve(response);
            } else {
              reject(response);
            }
          }
        };

        request.open('POST', this.KeyClaokApiUrl + 'token');
        request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        request.onload = () => { };
        request.send(body.toString());
      } catch (error) { }
    });
  }
  renderAnalyticsResults(
    JsonbyCreator,
    dataForResponse,
    options1,
    normalizedData
  ) {
    const resultPage=document.querySelectorAll("#surveyAnalyticsContainer .sa-panel__content");
    if (resultPage[0]) {
      this.analyticsResultHeight = resultPage[0]["offsetHeight"];
    }
    var panelNode = document.getElementById("surveyAnalyticsContainer");
    panelNode.innerHTML = "";
    const wordCloud = new Survey.SurveyModel(json);
    const survey = new Survey.SurveyModel(JsonbyCreator);
    const surveyQuestions = survey.getAllQuestions();
    setTimeout(() => {
      const question = document.querySelectorAll(".sa-question__content");
      // const questionStatement=document.querySelectorAll(".sa-question__content");
      // if (surveyQuestions.length === question.length) {
      surveyQuestions.forEach((element, i) => {
        if (element.contentMode === "image") {
          question[i].id = element.id;
          const imageQuestion = document.getElementById(question[i].id);
          const svgContainer = document.querySelectorAll(`#${question[i].id} .svg-container`);
          // const questionStatement=document.querySelectorAll(`#${question[i].id} .sa-question__title--draggable`);
          for (let i = 0; i < svgContainer.length; i++) {
            svgContainer[i].classList.add("analytics-img__svg-container");
            // questionStatement[i].setAttribute("id",`question-statement-${[i]}`);
          }
          if (!this.isImageAlreadyExists(imageQuestion.children)) {
            const imageQuestionContent = document.createElement("div")
            imageQuestionContent.setAttribute("id", `${question[i].id}-img-content`);
            imageQuestionContent.setAttribute("class", "analytics-image-question-content");
            const imageDiv = document.createElement("div");
            imageDiv.setAttribute("id", `${question[i].id}_imgQuestion`);
            imageDiv.setAttribute("class", "analytics-image-div");
            imageQuestion.appendChild(imageQuestionContent);
            imageQuestionContent.append(imageDiv, imageQuestion.children[1])
            const imagePickerChoices = element.choices.length;
            for (let index = 0; index < imagePickerChoices; index++) {
              const image = document.createElement("img");
              image.setAttribute("id", `${question[i].id}_div__img${index}`);
              image.classList.add("analytics-img");
              image.src = element.choices[index].imageLink;
              imageDiv.appendChild(image);
            }
          }
        }
      });
      setTimeout(() => {
        const questionDropdown = document.getElementsByClassName("sa-question__select");

        for (let index = 0; index < questionDropdown.length; index++) {
          if (questionDropdown[index].childElementCount > 2) {
            const questionDropdownPrnt = questionDropdown[index].closest('.sa-question__select-wrapper');
            questionDropdownPrnt.classList.add("display-none");
          }
        }
      }, 1);
    }, 5);

    SurveyAnalytics.textHelper.getStopWords().push("....");
    if (wordCloud.getAllQuestions())
      var table = new SurveyAnalytics.VisualizationPanel(
        wordCloud.getAllQuestions(),
        dataForResponse,
        options1,
      );
    table.render(panelNode);
    document.getElementById("surveyAnalyticsContainer").innerHTML = "";
    if (survey.getAllQuestions())
      var table = new SurveyAnalytics.VisualizationPanel(
        survey.getAllQuestions(),
        normalizedData,
        options1,
      );
    table.render(document.getElementById("surveyAnalyticsContainer"));
  }
  OnLoadCreator = (access_token: string, editsurveyId: string, state: string) => {
    try {
      Survey.dxSurveyService.serviceUrl = "";
      var JsonbyCreator = "";

      var xhr1 = new XMLHttpRequest();
      xhr1.open(
        "GET",
        APIURL +
        "Interaction/GetResults?Id=" +
        editsurveyId +
        "&results=" +
        state
      );
      xhr1.setRequestHeader(
        "Content-Type",
        "application/x-www-form-urlencoded"
      );
      xhr1.setRequestHeader('Authorization', 'Bearer ' + access_token);
      xhr1.setRequestHeader('client_id', AuthEnum.CLIENTID);
      xhr1.setRequestHeader('client_secret', AuthEnum.CLIENTSECRET);
      xhr1.onload = () => {
        var result = xhr1.response ? JSON.parse(xhr1.response) : [];
        if (result && result.responseCode &&  result.responseCode === 200) {
        this.apiResponse=result;
        this.exportIntoCsv(this.apiResponse);
        JsonbyCreator = JSON.parse(result.interactions[0].data);
        this.formResultJson = JsonbyCreator;
        this.surveyTitle = result.interactions[0].title;
        document.getElementById("lblFormTitle").innerHTML = this.surveyTitle;

        // console.debug(result.interactions[0].data);
        const survey = new Survey.SurveyModel(JsonbyCreator);
        // const wordCloud = new Survey.SurveyModel(json);

        try {
          if (this.myLanguage == "en") this.CurrentLangStrings = enLang;
          else if (this.myLanguage == "fr") this.CurrentLangStrings = frLang;
          else if (this.myLanguage == "de") this.CurrentLangStrings = deLang;
          survey.editorLocalization.locales["en"] = this.CurrentLangStrings.default;
          survey.editorLocalization.locales["fr"] = this.CurrentLangStrings.default;
          survey.editorLocalization.locales["de"] = this.CurrentLangStrings.default;
          survey.editorLocalization.currentLocale = this.myLanguage;
        } catch { }

        //Result
        var Rdata = result.interactions[0].sessions[0].attendance;
        var dataFinal = [];
        // students respose
        Rdata.forEach((value) => {
          if (value.presencestatus == true)
            dataFinal.push(JSON.parse(value.data));
        });

        try {
          const labelTxt = document.querySelector(
            ".other-label"
          ) as HTMLElement | null;
          if (labelTxt != null) {
            labelTxt.innerText = this.CurrentLangStrings.default.ed.othersResponse;
          }
          if (this.formType == FormType.QUIZ) {
            document.getElementById("totalScore").innerHTML =
              this.CurrentLangStrings.default.ed.totalmarks +
              ": " +
              result.interactions[0].totalmarks;
            SurveyAnalytics.PlotlySetup.onPlotCreating.add((model, options) => {
              var maxmarks = document.createElement("span");
              maxmarks.setAttribute(
                "style",
                "float:right; padding:5px 17px 0 4px;position:relative;bottom:6px;color:var(--amigo-primary-color);"
              );
              maxmarks.innerHTML =
                model.question["marks"] == undefined
                  ? "0" + this.CurrentLangStrings.default.ed.marks
                  : model.question["marks"] +
                  " " +
                  this.CurrentLangStrings.default.ed.marks; //maxmarks;
            });
          }
        } catch { }
        var normalizedData = dataFinal.map((item) => {
          try {
            delete item.email;
          } catch { }

          survey.getAllQuestions().forEach((q) => {
            if (item[q.name] === undefined) {
              item[q.name] = "";
            }
          });
          try {
            item["Email"] = item.email;
          } catch { }
          return item;
        });
        this.analyticsResponses = normalizedData;
        let commentData = [];
        // Number of Students Records Who have Attempted the Quiz
        for (let key in normalizedData) {
          var object = normalizedData[key];

          // Number of questions attempted by each student
          for (let key in object) {
            var obj = object[key];
            if (key.includes("-Comment")) {
              commentData.push(object[key]);
            }
          }
        }
        const commentAnswersArr = [];
        // Extract comment keys from normalizedData array
        const commentKeys = [];
        var remooveDuplicate = [];
        for (let i = 0; i < normalizedData.length; i++) {
          const element = normalizedData[i];
          // if (i==0) {
          const keys = Object.keys(element);
          keys.forEach((el) => {
            if (el.includes("-Comment")) {
              // commentKeys.push( {[el]: element[el]});

              commentKeys.push(el);
            }
          });
        }
        commentKeys.forEach((commentKey) => {
          commentAnswersArr[commentKey] = [];
          for (let i = 0; i < normalizedData.length; i++) {
            const element = normalizedData[i];
            commentAnswersArr[commentKey].push(element[commentKey]);
          }
        });
        let data = [];
        var newArray = [];
        var otherRespose = [];
        const othersKeys = [];
        const studentsOtherSelection = [];
        var undefinedinObj = 0;
        for (let i in commentAnswersArr) {
          data = commentAnswersArr[i];
          newArray = [];
          othersKeys.push([i]);
          data.forEach((element) => {
            if (!element) {
              undefinedinObj++;
            }
            otherRespose.push(element);
          });
          var studentsResponses = otherRespose.length - undefinedinObj;
          otherRespose = [];
          undefinedinObj = 0;
          studentsOtherSelection.push(studentsResponses);
          var newname = "code";
          data.forEach((elements, i) => {
            let ele = elements;
            elements = newname;
            newArray.push(elements, ele);
          });
          commentAnswersArr[i] = newArray;
        }
        var convertKeyPair = [];
        for (let key in commentAnswersArr) {
          var keyPair = commentAnswersArr[key];
          convertKeyPair = [];
          for (var i = 0; i < keyPair.length; i += 2) {
            var a = {};
            a[keyPair[i]] = keyPair[i + 1];
            convertKeyPair.push(a);
          }
          commentAnswersArr[key] = convertKeyPair;
        }
        //Adding the name for wordCloud
        const newCommentData = [];
        var newname = "code";
        commentData.forEach((elements) => {
          let ele = elements;
          elements = newname;
          newCommentData.push(elements, ele);
        });

        //converting into Key Pair

        var res = [];

        for (var i = 0; i < newCommentData.length; i += 2) {
          var o = {};
          o[newCommentData[i]] = newCommentData[i + 1];
          res.push(o);
        }
        var dataForResponse = [];
        for (let keys in commentAnswersArr) {
          dataForResponse = [];
          const obj = commentAnswersArr[keys];
          dataForResponse.push(obj);
        }
        this.otherResponses = dataForResponse;
        this.questionJson=JsonbyCreator;
        this.normalizeData=normalizedData;
        this.apiData=JsonbyCreator;
        this.surveyQuestions=survey.getAllQuestions();
        if (this.counter == 1) {
          HideLoading();
          setTimeout(() => {
            this.renderAnalyticsResults(
              this.formResultJson,
              this.otherResponses,
              options1,
              this.analyticsResponses
            );
            this.counter++
          // this.renderDescriptioninAnalytics(JsonbyCreator,survey.getAllQuestions())
          }, 10);
        }
        if (normalizedData.length > this.responseReceived) {
          this.renderAnalyticsResults(
            JsonbyCreator,
            dataForResponse[0],
            options1,
            normalizedData
          );
        }
        setTimeout(() => {
          const resposeText = document.querySelectorAll(
            ".sa-visualizer__footer-title"
          );
          for (let i = 0; i < resposeText.length; i++) {
            if (!studentsOtherSelection) {
              document.getElementById(resposeText[i].id).innerText = `${this.CurrentLangStrings.default.ed.show
                }${"0"}${this.CurrentLangStrings.default.ed.resposes}`;
            }
            else {
              let studentsOther = studentsOtherSelection.shift();
              if (!studentsOther) {
                document.getElementById(resposeText[i].id).innerText = `${this.CurrentLangStrings.default.ed.show
                  }${"0"}${this.CurrentLangStrings.default.ed.resposes}`;
              }
              else {
                document.getElementById(
                  resposeText[i].id
                ).innerText = `${this.CurrentLangStrings.default.ed.show}${studentsOther}${this.CurrentLangStrings.default.ed.resposes}`;
              }
            }
          }
        }, 10);

        SurveyAnalytics.SelectBasePlotly.types = ["bar"]; //doughnut
        SurveyAnalytics.BooleanPlotly.types = ["bar"];

        SurveyAnalytics.PlotlySetup.onPlotCreating.add(function (
          visualizer,
          options
        ) { });
        var contentContainer =
          document.getElementsByClassName("content-container")[0];
        $("#htmlInteraction").addClass("height-100");
        $("#app").addClass("height-100");
        $("#basebody").addClass("center-align-body");
        $("#appRoot").addClass("width-100");
        document
          .getElementsByClassName("content-container")[0]
          .scrollTo(0, this.scrollPosition);
          document.getElementsByClassName("dataTables_scrollBody")[0]
          .scrollTo(this.horizontalScrollPosition, 0);
        this.dataBindingTimeout =
          Math.floor(
            Math.random() *
            (this.dataBindingMaxTime - this.dataBindingMinTime + 1)
          ) + this.dataBindingMinTime;
        this.dataBindingTimeout = this.dataBindingTimeout * 1000;
        setTimeout(() => {
          this.scrollPosition =
            document.getElementsByClassName("content-container")[0].scrollTop;
            this.horizontalScrollPosition =
            document.getElementsByClassName("dataTables_scrollBody")[0].scrollLeft;
          this.responseReceived = normalizedData.length;
          this.OnLoadCreator(this.access_token, editsurveyId, state);
        }, this.dataBindingTimeout);
      }
      else if(xhr1.status == 401){
        this.renewSessionAuthorization().then(() => {
          xhr1.open(
            "GET",
            APIURL +
            "Interaction/GetResults?Id=" +
            editsurveyId +
            "&results=" +
            state
          );
          xhr1.setRequestHeader(
            "Content-Type",
            "application/x-www-form-urlencoded"
          );
          xhr1.setRequestHeader('Authorization', 'Bearer ' + this.access_token);
          xhr1.setRequestHeader('client_id', AuthEnum.CLIENTID);
          xhr1.setRequestHeader('client_secret', AuthEnum.CLIENTSECRET);
          xhr1.onload = () => {
            var result = xhr1.response ? JSON.parse(xhr1.response) : [];
            if (result && result.responseCode &&  result.responseCode === 200) {
            this.apiResponse=result;
            this.exportIntoCsv(this.apiResponse);
            JsonbyCreator = JSON.parse(result.interactions[0].data);
            this.formResultJson = JsonbyCreator;
            this.surveyTitle = result.interactions[0].title;
            document.getElementById("lblFormTitle").innerHTML = this.surveyTitle;
            const survey = new Survey.SurveyModel(JsonbyCreator);
            try {
              if (this.myLanguage == "en") this.CurrentLangStrings = enLang;
              else if (this.myLanguage == "fr") this.CurrentLangStrings = frLang;
              else if (this.myLanguage == "de") this.CurrentLangStrings = deLang;
              survey.editorLocalization.locales["en"] = this.CurrentLangStrings.default;
              survey.editorLocalization.locales["fr"] = this.CurrentLangStrings.default;
              survey.editorLocalization.locales["de"] = this.CurrentLangStrings.default;
              survey.editorLocalization.currentLocale = this.myLanguage;
            } catch { }
            var Rdata = result.interactions[0].sessions[0].attendance;
            var dataFinal = [];
            Rdata.forEach((value) => {
              if (value.presencestatus == true)
                dataFinal.push(JSON.parse(value.data));
            });
            try {
              const labelTxt = document.querySelector(
                ".other-label"
              ) as HTMLElement | null;
              if (labelTxt != null) {
                labelTxt.innerText = this.CurrentLangStrings.default.ed.othersResponse;
              }
              if (this.formType == FormType.QUIZ) {
                document.getElementById("totalScore").innerHTML =
                  this.CurrentLangStrings.default.ed.totalmarks +
                  ": " +
                  result.interactions[0].totalmarks;
                SurveyAnalytics.PlotlySetup.onPlotCreating.add((model, options) => {
                  var maxmarks = document.createElement("span");
                  maxmarks.setAttribute(
                    "style",
                    "float:right; padding:5px 17px 0 4px;position:relative;bottom:6px;color:var(--amigo-primary-color);"
                  );
                  maxmarks.innerHTML =
                    model.question["marks"] == undefined
                      ? "0" + this.CurrentLangStrings.default.ed.marks
                      : model.question["marks"] +
                      " " +
                      this.CurrentLangStrings.default.ed.marks; //maxmarks;
                });
              }
            } catch { }
            var normalizedData = dataFinal.map((item) => {
              try {
                delete item.email;
              } catch { }

              survey.getAllQuestions().forEach((q) => {
                if (item[q.name] === undefined) {
                  item[q.name] = "";
                }
              });
              try {
                item["Email"] = item.email;
              } catch { }
              return item;
            });
            this.analyticsResponses = normalizedData;
            let commentData = [];
            // Number of Students Records Who have Attempted the Quiz
            for (let key in normalizedData) {
              var object = normalizedData[key];

              // Number of questions attempted by each student
              for (let key in object) {
                var obj = object[key];
                if (key.includes("-Comment")) {
                  commentData.push(object[key]);
                }
              }
            }
            const commentAnswersArr = [];
            // Extract comment keys from normalizedData array
            const commentKeys = [];
            var remooveDuplicate = [];
            for (let i = 0; i < normalizedData.length; i++) {
              const element = normalizedData[i];
              // if (i==0) {
              const keys = Object.keys(element);
              keys.forEach((el) => {
                if (el.includes("-Comment")) {
                  // commentKeys.push( {[el]: element[el]});

                  commentKeys.push(el);
                }
              });
            }
            commentKeys.forEach((commentKey) => {
              commentAnswersArr[commentKey] = [];
              for (let i = 0; i < normalizedData.length; i++) {
                const element = normalizedData[i];
                commentAnswersArr[commentKey].push(element[commentKey]);
              }
            });
            let data = [];
            var newArray = [];
            var otherRespose = [];
            const othersKeys = [];
            const studentsOtherSelection = [];
            var undefinedinObj = 0;
            for (let i in commentAnswersArr) {
              data = commentAnswersArr[i];
              newArray = [];
              othersKeys.push([i]);
              data.forEach((element) => {
                if (!element) {
                  undefinedinObj++;
                }
                otherRespose.push(element);
              });
              var studentsResponses = otherRespose.length - undefinedinObj;
              otherRespose = [];
              undefinedinObj = 0;
              studentsOtherSelection.push(studentsResponses);
              var newname = "code";
              data.forEach((elements, i) => {
                let ele = elements;
                elements = newname;
                newArray.push(elements, ele);
              });
              commentAnswersArr[i] = newArray;
            }
            var convertKeyPair = [];
            for (let key in commentAnswersArr) {
              var keyPair = commentAnswersArr[key];
              convertKeyPair = [];
              for (var i = 0; i < keyPair.length; i += 2) {
                var a = {};
                a[keyPair[i]] = keyPair[i + 1];
                convertKeyPair.push(a);
              }
              commentAnswersArr[key] = convertKeyPair;
            }
            //Adding the name for wordCloud
            const newCommentData = [];
            var newname = "code";
            commentData.forEach((elements) => {
              let ele = elements;
              elements = newname;
              newCommentData.push(elements, ele);
            });

            //converting into Key Pair

            var res = [];

            for (var i = 0; i < newCommentData.length; i += 2) {
              var o = {};
              o[newCommentData[i]] = newCommentData[i + 1];
              res.push(o);
            }
            var dataForResponse = [];
            for (let keys in commentAnswersArr) {
              dataForResponse = [];
              const obj = commentAnswersArr[keys];
              dataForResponse.push(obj);
            }
            this.otherResponses = dataForResponse;
            this.questionJson=JsonbyCreator;
            this.normalizeData=normalizedData;
            this.apiData=JsonbyCreator;
            this.surveyQuestions=survey.getAllQuestions();
            if (this.counter == 1) {
              HideLoading();
              setTimeout(() => {
                this.renderAnalyticsResults(
                  this.formResultJson,
                  this.otherResponses,
                  options1,
                  this.analyticsResponses
                );
                this.counter++
              // this.renderDescriptioninAnalytics(JsonbyCreator,survey.getAllQuestions())
              }, 10);
            }
            if (normalizedData.length > this.responseReceived) {
              this.renderAnalyticsResults(
                JsonbyCreator,
                dataForResponse[0],
                options1,
                normalizedData
              );
            }
            setTimeout(() => {
              const resposeText = document.querySelectorAll(
                ".sa-visualizer__footer-title"
              );
              for (let i = 0; i < resposeText.length; i++) {
                if (!studentsOtherSelection) {
                  document.getElementById(resposeText[i].id).innerText = `${this.CurrentLangStrings.default.ed.show
                    }${"0"}${this.CurrentLangStrings.default.ed.resposes}`;
                }
                else {
                  let studentsOther = studentsOtherSelection.shift();
                  if (!studentsOther) {
                    document.getElementById(resposeText[i].id).innerText = `${this.CurrentLangStrings.default.ed.show
                      }${"0"}${this.CurrentLangStrings.default.ed.resposes}`;
                  }
                  else {
                    document.getElementById(
                      resposeText[i].id
                    ).innerText = `${this.CurrentLangStrings.default.ed.show}${studentsOther}${this.CurrentLangStrings.default.ed.resposes}`;
                  }
                }
              }
            }, 10);

            SurveyAnalytics.SelectBasePlotly.types = ["bar"]; //doughnut
            SurveyAnalytics.BooleanPlotly.types = ["bar"];

            SurveyAnalytics.PlotlySetup.onPlotCreating.add(function (
              visualizer,
              options
            ) { });
            var contentContainer =
              document.getElementsByClassName("content-container")[0];
            $("#htmlInteraction").addClass("height-100");
            $("#app").addClass("height-100");
            $("#basebody").addClass("center-align-body");
            $("#appRoot").addClass("width-100");
            document
              .getElementsByClassName("content-container")[0]
              .scrollTo(0, this.scrollPosition);
              document.getElementsByClassName("dataTables_scrollBody")[0]
              .scrollTo(this.horizontalScrollPosition, 0);
            this.dataBindingTimeout =
              Math.floor(
                Math.random() *
                (this.dataBindingMaxTime - this.dataBindingMinTime + 1)
              ) + this.dataBindingMinTime;
            this.dataBindingTimeout = this.dataBindingTimeout * 1000;
            setTimeout(() => {
              this.scrollPosition =
                document.getElementsByClassName("content-container")[0].scrollTop;
                this.horizontalScrollPosition =
                document.getElementsByClassName("dataTables_scrollBody")[0].scrollLeft;
              this.responseReceived = normalizedData.length;
              this.OnLoadCreator(this.access_token, editsurveyId, state);
            }, this.dataBindingTimeout);
          }};
          xhr1.send();
          })
      }
    }



      xhr1.send();
    } catch (error) { }

  };
  ngOnInit() {
    this.KeyClaokApiUrl = `${configJson["authHostUrl"]}protocol/openid-connect/`;
   }
  isImageAlreadyExists(imageQuestionChildren: HTMLCollection): boolean {
    let isImageExists = false;
    for (let i = 0; i < imageQuestionChildren.length; i++) {
      if (
        imageQuestionChildren[i].id &&
        imageQuestionChildren[i].id.includes("-img-content")
      ) {
        isImageExists = true;
        break;
      }
    }
    return isImageExists;
  }
  exportIntoCsv(apiResponse) {
    const header=document.getElementById('lblFormTitle');
    document.title=header.innerHTML + '-' + new Date().toLocaleDateString() + "@" + new Date().toLocaleTimeString();
    this.btnLoader=true;
    if(this.currentTabIndex==0){
    const analyticsContainer=document.getElementById('analytics-csvContainer');
    if(analyticsContainer){
    analyticsContainer.classList.add('d-none');
    }
    }
    var survey = new Survey.SurveyModel(
      apiResponse.interactions[0].data
    );
    // survey results data object
    var data = [];
    var email = [];
    var firstName=[];
    var lastName=[];
    const userEmailObj = {
      name: "Useremail",
      displayName: this.CurrentLangStrings.default.ed.email,
      dataType: 0,
      isVisible: true,
      isPublic: true,
      location: 0,
    };
    const firstNameObj = {
      name: "FirstName",
      displayName: this.CurrentLangStrings.default.ed.firstName,
      dataType: 0,
      isVisible: true,
      isPublic: true,
      location: 0,
    };
    const lastNameObj = {
      name: "LastName",
      displayName: this.CurrentLangStrings.default.ed.lastName,
      dataType: 0,
      isVisible: true,
      isPublic: true,
      location: 0,
    };
    apiResponse.interactions[0].sessions[0].attendance.forEach(
      (element) => {
        if(element.data && element.email && element.firstname && element.lastname){
        data.push(JSON.parse(element.data));
        email.push(element.email);
        firstName.push(element.firstname);
        lastName.push(element.lastname);
        }
      }
    );
    // EO survey results data object
    var normalizedData = data.map(function (item) {
      survey.getAllQuestions().forEach(function (q) {
        if (!item[q.name]) {
          item[q.name] = "";
        }
      });
      return item;
    });
    const dataTables = DataTables.initJQuery((<any>window)["jQuery"]);
    var table = new DataTables(<any>survey, normalizedData, null);
    table.haveCommercialLicense=true;
    table._columns.unshift(userEmailObj);
    table._columns.unshift(lastNameObj);
    table._columns.unshift(firstNameObj);
    if(this.currentTabIndex==0){
      table.render(document.getElementById('analytics-csvContainer'));
      }
      else{
    table.render(document.getElementById("csvContainer"));
      }
    table._rows.forEach((element, i) => {
      var userEmailObj = element.rowData;
      userEmailObj["Useremail"] = email[i];
      userEmailObj["FirstName"] = firstName[i];
      userEmailObj["LastName"] = lastName[i];
    });
    if(this.currentTabIndex==0){
      table.render(document.getElementById('analytics-csvContainer'));
      }
      else{
    table.render(document.getElementById("csvContainer"));
      }
    this.btnLoader=false;
  }
        clickDefaultExportBtn(){
        this.exportIntoCsv(this.apiResponse);
        const exportCsv = document.getElementsByClassName("buttons-csv");
        if (exportCsv[0]) {
          exportCsv[0]["click"]();
        }
        this.isExportedToCsv=true;
  }
  tabClick(tab) {
    this.currentTabIndex=tab.index;
    if(tab.index==1){
      // this.renderDescriptioninAnalytics(this.apiData,this.surveyQuestions)
      this.exportIntoCsv(this.apiResponse);
      const tableButtons=document.querySelectorAll("#analytics-container .dt-button");
      for (let index = 0; index < tableButtons.length; index++) {
       tableButtons[index].classList.add("default-blue-fill-btn");

      }
    }
    else{
      this.renderAnalyticsResults(
        this.questionJson,
        undefined,
        options1,
        this.normalizeData
      );
      if(this.isExportedToCsv){
        const resultPage=document.querySelectorAll("#surveyAnalyticsContainer .sa-panel__content");
        if (resultPage[0]) {
          resultPage[0]['style'].height = this.analyticsResultHeight+"px";
        }
      }
    }
    setTimeout(() => {
      // this.renderDescriptioninAnalytics(this.apiData,this.surveyQuestions)
    }, 100);

  }
  renderDescriptioninAnalytics(json,surveyQuestions){
    var questionId;
    surveyQuestions.forEach((question,i)=>{
      questionId=question.id;
      if(question.name==json.pages[0].elements[i].name && json.pages[0].elements[i].imageDescription.includes('data:image')){
        this.renderImageDescription(json,question,i)
      }
      if(question.name==json.pages[0].elements[i].name && json.pages[0].elements[i].description){
        this.renderTextDescription(json,question,i)
      }
      const descriptionParent= document.createElement('div');
      descriptionParent.classList.add('analytics-description');
      descriptionParent.setAttribute('id',`descPaent_${question.id}`);
      const textDescription=document.getElementById(`textDescParent_${question.id}`);
      const imageDescription=document.getElementById(`descImgParent_${question.id}`);
      if(textDescription && imageDescription){
        descriptionParent.append(imageDescription,textDescription);
      }
      else if(textDescription && !imageDescription){
      descriptionParent.append(textDescription);
      }
      else if(!textDescription && imageDescription){
        descriptionParent.append(imageDescription);
        }
        const questionContent=document.getElementsByClassName('sa-question__content');
        const questionTile=document.getElementsByClassName('sa-question__title');
        questionContent[i].setAttribute('id',`${question.id}_sa-question__content`);
        questionTile[i].setAttribute('id',`${question.id}_sa-question__title`)
        // questionContent[i].appendChild(descriptionParent);
        questionContent[i].insertBefore(descriptionParent,questionContent[i].children[1]);
        // console.log("questionContent[i][1]::",questionContent[i].firstChild)
      });

      // setTimeout(() => {
      //   debugger

      // });

      // }, 50);
  }
  renderImageDescription(json,question,index){
    if(!document.getElementById(`descImgParent_${question.id}`)){
    const descriptionImgParent=document.createElement('div');
    descriptionImgParent.setAttribute('id',`descImgParent_${question.id}`);
    descriptionImgParent.classList.add('analytics-image-desc');
    const img=document.createElement('img');
    img.classList.add('desc-preview-img');
    img.src=json.pages[0].elements[index].imageDescription;
    img.setAttribute('id',`descImg_${question.id}`);
    descriptionImgParent.appendChild(img);
    const questionContent=document.getElementsByClassName('sa-question__content');
    const questionTile=document.getElementsByClassName('sa-question__title');
    questionContent[index].setAttribute('id',`${question.id}_sa-question__content`);
    questionTile[index].setAttribute('id',`${question.id}_sa-question__title`)
    questionContent[index].appendChild(descriptionImgParent);
    }
  }
  renderTextDescription(json,question,index){
    if(!document.getElementById(`textDescParent_${question.id}`)){
    const textDescriptionParent=document.createElement('div');
    textDescriptionParent.classList.add('textDescParent');
    textDescriptionParent.setAttribute('id',`textDescParent_${question.id}`);
    const descriptionSpan=document.createElement('span');
    descriptionSpan.classList.add('text-descritption-span');
    descriptionSpan.innerHTML=json.pages[0].elements[index].description;
    textDescriptionParent.appendChild(descriptionSpan);
    const questionContent=document.getElementsByClassName('sa-question__content');
    const questionTile=document.getElementsByClassName('sa-question__title');
    questionContent[index].setAttribute('id',`${question.id}_sa-question__content`);
    questionTile[index].setAttribute('id',`${question.id}_sa-question__title`)
    questionContent[index].appendChild(textDescriptionParent);
    }
  }

}
