<div class="FormHeader width-100 h-100px">
  <div class="h-50px form-header" >
  <div class="FormName FormNamePosition d-flex">
      <div class="backbluecircle" id="headerIcon">
          <svg class="svgFormIconwhite">
      <use id="header-icon-href" xlink:href=""></use>
    </svg>
<!--
    <svg *ngIf="formObject.type==formTypeEnum.POLL;else typeQuiz" id="polling_icon" class="svgFormIconwhite" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 16 16" style="enable-background:new 0 0 16 16;" xml:space="preserve">
  <style type="text/css">
  </style>
  <rect x="6" y="1.5"  width="10" height="1"/>
  <g>
    <g>
      <path  d="M2,4C0.9,4,0,3.1,0,2s0.9-2,2-2s2,0.9,2,2S3.1,4,2,4z M2,1.5C1.7,1.5,1.5,1.7,1.5,2S1.7,2.5,2,2.5
        S2.5,2.3,2.5,2S2.3,1.5,2,1.5z"/>
    </g>
  </g>
  <rect x="6" y="7.5"  width="10" height="1"/>
  <g>
    <g>
      <path  d="M2,10c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S3.1,10,2,10z M2,7.5C1.7,7.5,1.5,7.7,1.5,8S1.7,8.5,2,8.5
        S2.5,8.3,2.5,8S2.3,7.5,2,7.5z"/>
    </g>
  </g>
  <rect x="6" y="13.5"  width="10" height="1"/>
  <g>
    <g>
      <path  d="M2,16c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S3.1,16,2,16z M2,13.5c-0.3,0-0.5,0.2-0.5,0.5s0.2,0.5,0.5,0.5
        s0.5-0.2,0.5-0.5S2.3,13.5,2,13.5z"/>
    </g>
  </g>
</svg>
<ng-template #typeQuiz>
  <svg id="quiz_icon" class="svgFormIconwhite" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 16 16" style="enable-background:new 0 0 16 16;" xml:space="preserve">
<style type="text/css">
</style>
<rect x="12" y="14"  width="2" height="2"/>
<g>
  <path  d="M13,6.6c-1.7,0-3,1.4-3,3.1V10h1.9V9.7c0-0.7,0.5-1.2,1.1-1.2s1.1,0.5,1.1,1.2c0,0.5-0.3,0.8-0.8,1.1
    c-0.5,0.4-1.2,1-1.3,2.1h2c0.1-0.5,0.5-0.8,0.8-1.1c0.5-0.5,1.2-1.1,1.2-2.1C16,8,14.7,6.6,13,6.6z"/>
</g>
<rect x="4" y="4"  width="8" height="1"/>
<rect x="4" y="7"  width="4" height="1"/>
<rect x="4" y="10"  width="4" height="1"/>
<g>
  <path  d="M1.5,15C1.2,15,1,14.8,1,14.5v-13C1,1.2,1.2,1,1.5,1h13C14.8,1,15,1.2,15,1.5v3.1h1V1.5C16,0.7,15.3,0,14.5,0
    h-13C0.7,0,0,0.7,0,1.5v13C0,15.3,0.7,16,1.5,16H10v-1H1.5z"/>
</g>
</svg>
</ng-template> -->
      </div>
      <label id="lblFormTitle" class="FormNameLabel creator-label"></label>
      <div class="edit-form-name margin-left-10 cursor-pointer" id="edit-form-icons" (click)="editFormName()">
        <svg class="svgFormName">
          <use id="edit-name-icon" xlink:href=""></use>
        </svg>
        </div>
  </div>
</div>
  <div class="divRightButtons" id="creatorbtnDiv">
      <!-- <div class="pdf-export" id="pdf-export-btn" (click)="exportToPdf()">
        Export
      </div> -->
      <a href="javascript:PreviewForm()" id="lnkPreview" title="Preview" class="backgraysquare">
          <svg class="svgFormName marginr20" id="preview-svg">
      <use xlink:href="assets/sprite.svg#viewicon20"></use>
    </svg></a>
    <div (click)="saveSurvey()" id="lnkSave" title="Save" class="backgraysquare">
      <!-- <a href="javascript:saveandpublishMySurvey()" id="lnkSave" title="Save" class="backgraysquare"> -->
         <svg class="svgFormName marginr20" id="saveSvg">
      <use xlink:href="assets/sprite.svg#saveicon20"></use>
    </svg>
  <!-- </a> -->
    </div>
      <a href="javascript:confirmClose()" title="Close" id="lnkClose" class="backgraysquare"> <svg class="svgFormName ">
      <use xlink:href="assets/sprite.svg#closeicon20"></use>
    </svg></a>

  </div>
</div>
<div id="creatorAddDiv">
  <div class="dropup AddQuestionOption" id="QuestionPop">
      <button mat-button [matMenuTriggerFor]="menu" class="dropdown-toggle">
    <div class="dropdown-content">
      <svg width="16" height="16" class="addQuestionsvgwhite">
        <use xlink:href="assets/sprite.svg#add_16_icon" />
      </svg>
      <span id="newQuestion" class="btnPrimary new-question">Add Question</span>
      <span class="caret"></span>
    </div>
  </button>
      <mat-menu #menu="matMenu" class="addQuestionMenu">
          <button mat-menu-item (click)="addSingleChoice()">
      <svg width="16" height="16" class="addQuestionsvg">
        <use xlink:href="assets/sprite.svg#SingleChoice_16" />
      </svg>
      <span id="singleselect" class="dropdown-text">Single Choice</span>
    </button>
          <button mat-menu-item (click)="addMultiChoice()">
      <svg width="16" height="16" class="addQuestionsvg">
        <use xlink:href="assets/sprite.svg#Multiplechoice_16" />
      </svg>
      <span id="multiselect" class="dropdown-text">Multiple Choice</span>
    </button>
          <button mat-menu-item (click)="addDescriptive()">
      <svg width="16" height="16" class="addQuestionsvg">
        <use xlink:href="assets/sprite.svg#paragraph" />
      </svg>
      <span id="type-description" class="dropdown-text">Free Text</span>
    </button>
          <button mat-menu-item (click)="AddImage()">
            <svg width="18" height="18" class="addQuestionsvg">
              <use xlink:href="assets/sprite.svg#image-picker" />
            </svg>
      <span id="image-type" class="dropdown-text">Image</span>
    </button>
      </mat-menu>
  </div>
  <div class="export-oprenforrespose-parent">
      <!-- open for response btn -->
  <div class="menuSaveAndShare">
      <button mat-button onclick="ShareandClose()" class="btnSaveAndShare" id="btnShare">
         <span id="openforResponse" class="btnPrimary labelSaveAndShare">Open for responses</span>
      </button>
  </div>
  <!-- export btn -->
  <div class="export-btn-parent creator-export-btn-parent">
    <button matripple class="rbcButtons btnFilledBlue" (click)="exportToPdf()">
    <span *ngIf="!btnLoader;else showDots">{{((CurrentLangStrings?.default?.ed?.export) ?(CurrentLangStrings?.default?.ed?.export) :('Export'))}}</span>
    <ng-template #showDots>
          <div class="dots-container">
              <div class="dot"></div>
              <div class="dot"></div>
              <div class="dot"></div>
          </div>
      </ng-template>
  </button>
    </div>
  </div>
  <!-- <div class="least_one_question">
    <h1>Please add Atleast one Question</h1>
  </div> -->
</div>
<div class="container- creator-container">
  <h2></h2>
  <survey-creator (surveySaved)="onSurveySaved($event)" (rbcFormObject)="onObjectReceived($event)" (formsTitle)="onFromTitleReceived($event)"></survey-creator>
</div>
<!-- Descriptive Modal -->
<div class="modal builder-page__modal" id="descriptiveModelSurvey" tabindex="-1" role="dialog" style="display: none" aria-labelledby="descriptiveModelSurveyLabel">
<div class="modal__dialog" role="document">
    <div class="modal__content descriptive__modal__content">
        <div class="modal__header dialog-header">
          <div class="model__icon--content">
            <svg width="24" height="24" class="answerkeysvg"><use xlink:href="assets/sprite.svg#Answer_Key"></use></svg>
        </div>
        <span class="spanHeaderEditor" id="creator-modal-text">Set Marks<div style="margin-left:auto;"></div></span>
        </div>
        <div class="question_statement">
          <span class="question_text" id="creator-question-text">Question:</span><h4 class="question-statement" id="descriptive_statement">
          </h4>
          </div>
          <div class="descriptive__body__content">
        <div class="descriptive__modal__body">

            <label for="colFormLabel" class="col-sm-1 col-form-label marksText" id="creator-body-text">Marks</label>
            <div class="col-sm-11 comment_points_parent">
                <input type="number" class="form-control setmarks" id="descriptiveQuestionPoints" min="0" value="0" max="100">
            </div>
            <div id="surveyContainerInPopupCommnet"></div>
        </div>
        <div class="modal__footer">
          <button type="button" id="descriptive_cancel_btn" class="modal__button modal__button--defaul" data-dismiss="modal" (click)="closeDescriptiveModal()">
            Cancel
          </button>
            <button type="button"  class="modal__button modal__button--defaul" data-dismiss="modal" (click)="setDescriptiveMarks()">
                Update
              </button>
        </div>
        </div>
    </div>
</div>
</div>

<!-- <div ng-controller="AppCtrl" class="md-padding" id="popupContainer" ng-cloak>


<div class="dialog-demo-content" layout="row" layout-wrap layout-margin layout-align="center">
  <div class="md-primary md-raised" ng-click="showAlert($event)"   >
    Alert Dialog
  </div>
</div>
<p class="footer">Note: The <b>Confirm</b> dialog does not use <code>$mdDialog.clickOutsideToClose(true)</code>.</p>

<div ng-if="status" id="status">
  <b layout="row" layout-align="center center" class="md-padding">
    {{status}}
  </b>
</div>



</div> -->
