
    <div class="scoreParent marks-parent-header">
    <div class="creator-pdf-export">
    </div>
      <div id="totalScore" class="scoreHeader creator-score"></div>
    </div>
    <div id="creator-parent">
      <div id="zero-question-content" class="displaynone">
        <div id="textSvgParent">
          <div class="z-index-999" id="content-icon">
            <svg id="null-icon" class="svgFormIconwhite questionContentIcon">
              <use xlink:href="" id="empty-icon-href"></use>
            </svg>
          </div>
          <div id="nullText" class="z-index-999">
            Please add a question to start
          </div>
          <div
            class="dropup AddQuestionOptions add-question-btn"
            id="QuestionPopUp"
          >
            <button
              id="BtnQuestionPopUp"
              mat-button
              [matMenuTriggerFor]="qustionMenu"
              class="dropdown-toggle question-content-toggle"
            >
              <div class="dropdown-content">
                <svg width="16" height="16" class="addQuestionsvgwhite">
                  <use xlink:href="assets/sprite.svg#add_16_icon" />
                </svg>
                <span id="newQuestion" class="btnPrimary new-question"
                  >Add Question</span
                >
                <span class="caret"></span>
              </div>
            </button>
            <mat-menu #qustionMenu="matMenu" class="addQuestionMenu">
              <button mat-menu-item (click)="AddSCQ()">
                <svg width="16" height="16" class="addQuestionsvg">
                  <use xlink:href="assets/sprite.svg#SingleChoice_16" />
                </svg>
                <span id="singleselect" class="dropdown-text"
                  >Single Choice</span
                >
              </button>
              <button mat-menu-item (click)="AddMCQ()">
                <svg width="16" height="16" class="addQuestionsvg">
                  <use xlink:href="assets/sprite.svg#Multiplechoice_16" />
                </svg>
                <span id="multiselect" class="dropdown-text"
                  >Multiple Choice</span
                >
              </button>
              <button mat-menu-item (click)="AddTXT()">
                <svg width="16" height="16" class="addQuestionsvg">
                  <use xlink:href="assets/sprite.svg#paragraph" />
                </svg>
                <span id="type-description" class="dropdown-text"
                  >Free Text</span
                >
              </button>
              <button mat-menu-item (click)="AddImageQuestion()">
                <svg width="18" height="18" class="addQuestionsvg">
                  <use xlink:href="assets/sprite.svg#image-picker" />
                </svg>
                <span id="image-type" class="dropdown-text">Image</span>
              </button>
            </mat-menu>
          </div>
        </div>
      </div>
      <div id="surveyCreatorContainer"></div>
    </div>
    <style>
      .basebody {
        background-color: var(--amigo-secondary-bg-color) !important;
      }
    </style>
  