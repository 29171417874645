export enum FormsStateEnum {
    ALL = 'all',
    ATTEMPT = 'attempt',
    CLOSED = 'closed',
    CREATORCLOSED = 'creatorclosed',
    EDIT = 'edit',
    EMPTYIMAGEITEM = 'emptyimageitem',
    FINISHED = 'finished',
    NEW = 'new',
    NONE = 'none',
    PREVIEW = 'preview',
    RELOAD = 'reload',
    RENAMEFORM = "renameform",
    REVIEW = 'review',
    REVIEWED = 'reviewed',
    SAVED = 'saved',
    SELF = 'self',
    SHARED = 'shared',
    UNSUPPORTEDFILE = "unsupportedfile",
    UPDATED = 'updated',
}
export enum FormType{
  POLL = 0,
  QUIZ = 1,
}
export enum UserRole{
  TEACHER = 'Teacher',
  STUDENT = 'Student',
  GUEST ="Guest"
}
export enum DescriptionType{
  IMAGE = 'Image',
  TEXT = 'Text',
}
export enum AuthEnum {
  CLIENTID = "rbc-classroom-portal",
  CLIENTSECRET = "fAq7GFI5CCc6IVeJOPPYAdfacnjLSGBA",
  GRANTTYPE_PASSWORD = "password",
  GRANTTYPE_REFRESHTOKEN = "refresh_token",
  }

export enum FormsEventsEnum {
    FORMS_ONUPDATEASSESSMENTMARKS = 'forms_onupdateassessmentmarks',
    FORMS_ANWERKEYEVENT = 'forms_anwerkeyevent',
    FORMS_ONUPDATEFREETEXTMARKS = 'forms_onUpdateFreeTextMarks',
    FORMS_NONEPRESSED = 'forms_nonepressed',
    FORMS_ONFREETEXTCOPY = 'forms_onfreetextcopy',
    FORMS_ONEMPTYIMAGEITEM = 'forms_onemptyimageitem',
    FORMS_ONUNSUPPORTEDFILE = 'forms_onunsupportedfile',
    FORMS_ONITEMDELETE = 'forms_onitemdelete',
    FORMS_ONNEWITEMADDED = 'forms_onnewitemadded',
    FORMS_DESCRIPTIONIMAGEDRAGENDED = 'forms_descriptionimagedragended',
    FORMS_ITEMADDEDTHROUGHPLUS = 'forms_itemaddedthrougnhplus',
    FORMS_IMAGEITEMIMAGEPLUS='forms_itemaddedthrougnhimageplus',
    FORMS_ITEMDRAGGED='forms_itemdragged',
    FORMS_QUESTIONDROPPED='forms_questiondropped',
}
