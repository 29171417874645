/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../components/survey.component.ngfactory";
import * as i2 from "../components/survey.component";
import * as i3 from "@angular/router";
import * as i4 from "@angular/common/http";
import * as i5 from "./viewer.page";
var styles_SurveyViewerPage = [];
var RenderType_SurveyViewerPage = i0.ɵcrt({ encapsulation: 2, styles: styles_SurveyViewerPage, data: {} });
export { RenderType_SurveyViewerPage as RenderType_SurveyViewerPage };
export function View_SurveyViewerPage_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "dropup  PreviewtopDiv width-100 header-bg-color"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "FormName FormNamePosition"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "div", [["class", "backbluecircle"], ["id", "headerIcon"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "label", [["class", "FormNameLabel"], ["id", "lblFormTitle"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 2, "div", [["class", "container"], ["id", "AttemptContainer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "survey", [["id", "student-attempt-container"]], null, [[null, "submitSurvey"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submitSurvey" === en)) {
        var pd_0 = (_co.sendData($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_SurveyComponent_0, i1.RenderType_SurveyComponent)), i0.ɵdid(6, 114688, null, 0, i2.SurveyComponent, [i3.Router, i4.HttpClient], { json: [0, "json"] }, { submitSurvey: "submitSurvey" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.json; _ck(_v, 6, 0, currVal_0); }, null); }
export function View_SurveyViewerPage_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "viewer-page", [], null, null, null, View_SurveyViewerPage_0, RenderType_SurveyViewerPage)), i0.ɵdid(1, 49152, null, 0, i5.SurveyViewerPage, [], null, null)], null, null); }
var SurveyViewerPageNgFactory = i0.ɵccf("viewer-page", i5.SurveyViewerPage, View_SurveyViewerPage_Host_0, {}, {}, []);
export { SurveyViewerPageNgFactory as SurveyViewerPageNgFactory };
