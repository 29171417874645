<div class="dropup  PreviewtopDiv width-100 header-bg-color">
    <div class="FormName FormNamePosition">
        <!-- AddtopClose -->
        <div class="backbluecircle" id="headerIcon">
            <!-- <svg class="svgFormIconwhite">
        <use xlink:href="assets/sprite.svg#polling_icon"></use>
      </svg> -->
        </div>
        <label id="lblFormTitle" class="FormNameLabel"></label>
    </div>

    <!-- <a href="javascript:confirmClose()" title="Close" class="backgraysquare PreviewtopBtn"> <svg class="svgFormName ">
  <use xlink:href="assets/sprite.svg#closeicon20"></use>
</svg></a> -->
</div>
<div class="container containerPreview" id="assessmentID" style="padding-top: 0px;">
    <!-- <label id="pageHeaderViewer" style="font-size: 12px; font-weight: bold;"></label> -->
    <surveyassessment [json]="dummyJson" (submitSurvey)="sendData($event)"  (formObject)="onObjectReceived($event)"></surveyassessment>

</div>

<!-- Descriptive Question Assessment Modal -->
<div class="modal builder-page__modal" id="descriptiveAssessmentModelSurvey" tabindex="-1" role="dialog" style="display: none" aria-labelledby="descriptiveAssessmentModelSurveyLabel">
    <div class="modal__dialog" role="document">
        <div class="modal__content descriptive__modal__content">
            <div class="modal__header dialog-header">
                <div class="model__icon--content">
                    <svg width="24" height="24" class="answerkeysvg"><use xlink:href="assets/sprite.svg#Answer_Key"></use></svg>
                </div>
                <span class="spanHeaderEditor" id="assessment-modal-text">Set Marks<div style="margin-left:auto;"></div></span>
            </div>
            <div class="question_statement">
                <span class="question_text" id="assessment-question-text">Question:</span>
                <h4 class="question-statement" id="descriptive_tilte">
                </h4>
            </div>
            <div class="descriptive__body__content">
                <div class="descriptive__modal__body">

                    <label for="colFormLabel" class="col-sm-1 col-form-label marksText" id="assessment-body-text">Marks</label>
                    <div class="col-sm-11 comment_points_parent">
                        <input type="number" class="form-control setmarks" id="descriptive-assessment-points" min="0" value="0" max="100">
                    </div>
                    <div id="surveyContainerInPopupCommnet"></div>
                </div>
                <div class="modal__footer">
                    <button type="button" id="descriptive_cancel_btn" class="modal__button modal__button--defaul" data-dismiss="modal" (click)="closeAssessmentmodal()">
              Cancel
            </button>
                    <button type="button" class="modal__button modal__button--defaul" data-dismiss="modal" (click)="setAssessmentMarks()">
                  Update
                </button>
                </div>
            </div>
        </div>
    </div>
</div>
