<div id="app">
  <nav class="navbar navbar-default">
    <div class="container-fluid">
      <!-- <div class="navbar-header">
        <a class="navbar-brand" href="/">{{ title }}</a>
      </div> -->
      <ul class="nav navbar-nav">
        <!-- <li>
          <a routerLink="">Home</a>
        </li> -->
        <li>
          <a routerLink="/survey">Form</a>
        </li>
        <!-- <li>
          <a routerLink="/creator">Creator</a>
        </li>
        <li>
          <a routerLink="/pdfexport">PDF Export</a>
        </li>
        <li>
          <a routerLink="/analytics">Analytics</a>
        </li>
        <li>
          <a routerLink="/analyticstabulator">Results Table</a>
        </li>
        <li>
          <a routerLink="/analyticsdatatables">Results Table (IE Support)</a>
        </li> -->
      </ul>
    </div>
  </nav>
  <router-outlet class="view"></router-outlet>
</div>
