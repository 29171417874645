import * as tslib_1 from "tslib";
import { HttpClient, HttpParams } from "@angular/common/http";
import { AfterViewInit, ChangeDetectorRef, EventEmitter, NgZone, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material";
import { Router } from "@angular/router";
import * as SurveyKo from "survey-knockout";
import * as Survey from "../../../src/survey.angular.js";
// import * as SurveyCreator from "../../../src/amigocreator/survey-creator";
import { FormsEventsEnum, FormsStateEnum, AuthEnum } from "app/enums/app.enums.js";
import * as widgets from "../../../src/surveyjs-widgets.js";
import * as deLang from "../../assets/language/language_de.json";
import * as enLang from "../../assets/language/language_en.json";
import * as frLang from "../../assets/language/language_fr.json";
import * as SurveyCreator from "../surveycreator/survey-creator";
import { init as initCustomWidget } from "./customwidget";
import { PopupModalComponent } from "./popup-modal/popup-modal.component";
widgets.icheck(SurveyKo);
widgets.select2(SurveyKo);
widgets.inputmask(SurveyKo);
widgets.jquerybarrating(SurveyKo);
widgets.jqueryuidatepicker(SurveyKo);
widgets.nouislider(SurveyKo);
widgets.select2tagbox(SurveyKo);
//widgets.signaturepad(SurveyKo);
widgets.sortablejs(SurveyKo);
widgets.ckeditor(SurveyKo);
widgets.autocomplete(SurveyKo);
widgets.bootstrapslider(SurveyKo);
//widgets.emotionsratings(SurveyKo);
initCustomWidget(SurveyKo);
SurveyCreator.StylesManager.applyTheme("omar");
var Main;
var targetEvent;
var targetBtnId;
var questionEvent;
var CurrentLangStrings;
var buttonMarks;
var jsonDef;
var updateDescriptiveEvent;
var freeTextCopyEvent;
var draftCurrentQuestion;
// = {
//   "title": "",
//   "pages": [
//     {
//       "name": "page1",
//       "elements": [
//         {
//           "type": "checkbox",
//           "name": "Question 1",
//           "title": "Question 1",
//           "isRequired": true,
//           "choices": [
//             {
//               "value": "item1",
//               "text": "option 1"
//             },
//             {
//               "value": "item2",
//               "text": "option 2"
//             },
//             {
//               "value": "item3",
//               "text": "option 3"
//             }
//           ]
//         }
//       ]
//     }
//   ],
//   "showProgressBar": "top"
// };
var jsonDefQuiz = {
    title: "",
    pages: [
        {
            name: "page1",
            elements: [
                {
                    type: "checkbox",
                    name: "Question 1",
                    title: "Question 1",
                    choices: [
                        {
                            value: "item1 ",
                            text: "option 1",
                        },
                        {
                            value: "item 2",
                            text: "option 2",
                        },
                        {
                            value: "item3",
                            text: "option 3",
                        },
                    ],
                },
            ],
        },
    ],
    showProgressBar: "top",
};
var FormType;
(function (FormType) {
    FormType[FormType["POLL"] = 0] = "POLL";
    FormType[FormType["QUIZ"] = 1] = "QUIZ";
    FormType[FormType["ASSIGNMENT"] = 2] = "ASSIGNMENT";
})(FormType || (FormType = {}));
var SurveyCreatorComponent = /** @class */ (function () {
    function SurveyCreatorComponent(router, http, zone, dialog, changeDetectorRef) {
        var _this = this;
        this.router = router;
        this.http = http;
        this.zone = zone;
        this.dialog = dialog;
        this.changeDetectorRef = changeDetectorRef;
        this.isStateNew = true;
        this.myLanguage = "en";
        this.formType = null;
        this.isQuestionDropped = false;
        this.isPropertyClosed = false;
        this.doAllQuestionhaveMarks = true;
        this.descriptionImgJson = {
            pages: [{ elements: [] }]
        };
        this.showContent = false;
        this.isDraftForm = false;
        // CurrentLangStrings:any;
        this.surveySaved = new EventEmitter();
        this.rbcFormObject = new EventEmitter();
        this.formsTitle = new EventEmitter();
        this.onEmptyImageItem = function (e) {
            try {
                if (e) {
                    var emptyImageOption = {
                        state: FormsStateEnum.EMPTYIMAGEITEM
                    };
                    closeWindow(emptyImageOption);
                }
            }
            catch (error) {
                console.log(error);
            }
        };
        this.onitemdelete = function (e) {
            try {
                if (e.detail) {
                    _this.addImgInDescription(e.detail.question);
                }
            }
            catch (error) {
                console.log(error);
            }
        };
        this.onnewitemadded = function (e) {
            try {
                if (e.detail) {
                    _this.addImgInDescription(e.detail);
                }
            }
            catch (error) {
                console.log(error);
            }
        };
        this.itemdragged = function (e) {
            try {
                if (e.detail) {
                    _this.addImgInDescription(e.detail);
                }
            }
            catch (error) {
                console.log(error);
            }
        };
        this.itemaddedthrougnhimageplus = function (e) {
            try {
                if (e.detail) {
                    _this.addImgInDescription(e.detail);
                }
            }
            catch (error) {
                console.log(error);
            }
        };
        this.descriptionimagedragended = function (e) {
            try {
                if (e.detail) {
                    _this.addImgInDescription(_this.targetDroppedQuestion);
                    console.log("e.detail:::", e.detail);
                    _this.isQuestionDropped = e.detail;
                }
            }
            catch (error) {
                console.log(error);
            }
        };
        // anwerKeyEvent = (e) => {
        //   try {
        //     this.materailModalEvent = e;
        //     console.log("materailModalEvent", this.materailModalEvent);
        //     if (!this.materailModalEvent) {
        //       this.displayErrorModal();
        //     }
        //   } catch (error) {
        //     console.log(error);
        //   }
        // }
        this.onUpdateFreeTextMarks = function (e) {
            try {
                updateDescriptiveEvent = e;
                if (e) {
                    var surveyJson_1 = JSON.parse(_this.surveyCreator.text);
                    var obtainedMarks = 0;
                    var updatedMarks = 0;
                    var marks = document.getElementById("descriptiveQuestionPoints");
                    var commentMarks_1 = marks["value"];
                    var clickedBtn = document.getElementById(targetBtnId);
                    clickedBtn.textContent = CurrentLangStrings.default.ed.setMarks + " ( " + commentMarks_1 + " " + CurrentLangStrings.default.ed.marks + " )";
                    clickedBtn.title = CurrentLangStrings.default.ed.setMarks + " ( " + commentMarks_1 + " " + CurrentLangStrings.default.ed.marks + " )";
                    if (surveyJson_1) {
                        window["childJson"] = surveyJson_1.pages[0].elements;
                    }
                    surveyJson_1.pages[0].elements.forEach(function (element) {
                        if (element.type == "comment" && targetEvent == element.name) {
                            updatedMarks = parseInt(commentMarks_1);
                            element.marks = updatedMarks;
                            questionEvent.marks = updatedMarks;
                            element.obtainedMarks = obtainedMarks;
                        }
                        // this.SaveSurvey(JSON.stringify(surveyJson), true);
                        _this.calculateScore(surveyJson_1);
                    });
                    document.getElementById("descriptiveQuestionPoints")["value"] = 0;
                }
            }
            catch (error) {
                console.log(error);
            }
        };
        // nonePressed = (e) => {
        //   try {
        //     this.noneEvent = e;
        //     if (e) {
        //       this.addNoneItem();
        //     }
        //   } catch (error) {
        //     console.log(error);
        //   }
        // }
        this.onUnsupportedFile = function (e) {
            try {
                if (e) {
                    var unSupportedFiletype = {
                        state: FormsStateEnum.UNSUPPORTEDFILE
                    };
                    closeWindow(unSupportedFiletype);
                }
            }
            catch (error) {
                console.log(error);
            }
        };
        this.onFreeTextCopy = function (e) {
            try {
                freeTextCopyEvent = e;
            }
            catch (error) {
                console.log(error);
            }
        };
        this.getAuthDetails = function (obj) {
            try {
                _this.formObject = obj;
                _this.rbcFormObject.emit(obj);
                _this.disableButtons(false);
                try {
                    _this.myLanguage = obj.language;
                    sessionStorage.setItem("currLang", obj.language);
                }
                catch (_a) { }
                try {
                    if (obj.bubbleid != "" && obj.bubbleid != undefined) {
                        document.getElementById("btnShare").style.display = "block";
                        if (!obj.id) {
                            HideLoading();
                        }
                    }
                    else {
                        document.getElementById("btnShare").style.display = "none";
                        if (!obj.bubbleid && !obj.id) {
                            HideLoading();
                        }
                        else if (obj.bubbleid && _this.isStateNew) {
                            HideLoading();
                        }
                    }
                }
                catch (_b) { }
                try {
                    _this.isStateNew = obj.state == FormsStateEnum.NEW ? true : false;
                    if (obj.state == FormsStateEnum.NEW || obj.state == FormsStateEnum.EDIT) {
                        document.getElementById("edit-name-icon").setAttribute("xlink:href", "assets/sprite.svg#rename-edit-icon");
                    }
                    else {
                        document.getElementById('edit-form-icons').classList.remove('cursor-pointer');
                    }
                }
                catch (_c) { }
                sessionStorage.setItem("interactionID", obj.id);
                _this.access_token = obj.access_token;
                _this.refresh_token = obj.refresh_token;
                _this.editsurveyId = obj.id;
                _this.formType = obj.type;
                _this.objSate = obj.state;
                try {
                    console.debug("[Forms] :: Selected type Poll - ", _this.formType == FormType.POLL);
                    if (_this.formType === FormType.POLL) {
                        document
                            .getElementById("empty-icon-href")
                            .setAttribute("xlink:href", "assets/sprite.svg#filterPoll_120");
                        document
                            .getElementById("header-icon-href")
                            .setAttribute("xlink:href", "assets/sprite.svg#polling_icon");
                        document.getElementById("totalScore").classList.add("displaynone");
                    }
                    else if (_this.formType === FormType.QUIZ) {
                        document
                            .getElementById("empty-icon-href")
                            .setAttribute("xlink:href", "assets/sprite.svg#filterQuiz_120");
                        document
                            .getElementById("header-icon-href")
                            .setAttribute("xlink:href", "assets/sprite.svg#quiz_icon");
                    }
                }
                catch (_d) { }
                try {
                    sessionStorage.setItem("QuestionType", _this.formType);
                    // if (this.formType == FormType.QUIZ) {
                    document.getElementById("Qanswerkey").style.display = "block";
                    // }
                    // else {
                    //   console.log(document.getElementById("Qanswerkey"));
                    //   document.getElementById("Qanswerkey").style.display = "none";
                    //   console.log("NO answerkey");
                    // }
                }
                catch (_e) { }
                try {
                    _this.surveyTitle = obj.title;
                    _this.sectionsReceived = obj.bubbleid;
                    document.getElementById("lblFormTitle").innerHTML = _this.surveyTitle;
                    window['formTitle'] = _this.surveyTitle;
                    _this.formsTitle.emit(window['formTitle']);
                }
                catch (_f) { }
                try {
                    _this.objForClose = {
                        interactionid: obj.id,
                        state: FormsStateEnum.CREATORCLOSED,
                        type: obj.type,
                        bubbleid: obj.bubbleid,
                    };
                    objforCloseButton = _this.objForClose;
                }
                catch (_g) { }
                _this.OnLoadCreator(obj.access_token, obj.id);
            }
            catch (e) { }
        };
        this.getData = function (access_token, editsurveyId) {
            try {
                // const headers = { AuthToken: access_token };
                var headers = {
                    Authorization: 'Bearer ' + _this.access_token,
                    client_id: AuthEnum.CLIENTID,
                    client_secret: AuthEnum.CLIENTSECRET
                };
                var self = _this;
                _this.http.get(APIURL + "interaction/GetById?Id=" + editsurveyId, { headers: headers, }) //in the case of draft,template enable and disbale buttons
                    .subscribe(function (data) {
                    _this.formTitle = data.interactions[0].title;
                    var surveyCreatorJson = JSON.parse(data.interactions[0].data);
                    // self.descriptionImgJson=JSON.parse(data.interactions[0].data);
                    // self.descriptionImgJson.pages[0].elements.forEach(element => {
                    //   if(!element.description || !element.description.includes('data:image')){
                    //     let deletedElement = self.descriptionImgJson['pages'][0].elements.indexOf(element);
                    //     self.descriptionImgJson['pages'][0].elements.splice(deletedElement, 1);
                    //   }
                    // });
                    if (data.interactions[0].bubbleid != "" && _this.objSate === 'edit') {
                        document.getElementById("btnShare").classList.add("d-block");
                    }
                    // this.enableAndDisableBtns(surveyCreatorJson.pages[0].elements);
                    if (surveyCreatorJson.pages[0].elements.length === 0) {
                        var zerQuestionContent = document.getElementById("zero-question-content");
                        zerQuestionContent.className = "d-block";
                    }
                    if (data.responseCode == 200) {
                        if (!_this.isStateNew) {
                            _this.surveyTitle = data.interactions[0].title;
                            document.getElementById("lblFormTitle").innerHTML =
                                _this.surveyTitle;
                            window['formTitle'] = _this.surveyTitle;
                        }
                        HideLoading();
                        try {
                            //
                            // const imgDescription=[];
                            // console.log("data.interactions[0].data",JSON.parse(data.interactions[0].data));
                            // var questionJson=JSON.parse(data.interactions[0].data);
                            // questionJson.pages[0].elements.forEach(element => {
                            //   if(element.description && element.description.includes('data:image')){
                            //     imgDescription.push(element.description);
                            //   }
                            // });
                            // if(imgDescription.length>0){
                            //   const imgDescriptionJson=data.interactions[0].data;
                            //   questionJson.pages[0].elements.forEach(element => {
                            //     if(element.description && element.description.includes('data:image')){
                            //     element.description='';
                            //     }
                            //   });
                            //   self.surveyCreator.text = JSON.stringify(questionJson);
                            // }
                            // else{
                            self.surveyCreator.text = data.interactions[0].data;
                            // }
                            // setTimeout(() => {
                            //   self.surveyCreator.survey.activePage.elementsValue.forEach((element,i) => {
                            //     if(!element.rows){
                            //       this.addImgInDescription(element,true,self.descriptionImgJson)
                            //     }
                            //   });
                            // }, 100);
                            if (surveyCreatorJson.pages[0].elements.length && self.surveyCreator.survey.activePage.elementsValue.length) {
                                surveyCreatorJson.pages[0].elements.forEach(function (element, i) {
                                    if (element) {
                                        _this.addImgInDescription(self.surveyCreator.survey.activePage.elementsValue[i], true, surveyCreatorJson);
                                    }
                                });
                            }
                            self.isActive = data.interactions[0].active;
                            if (_this.formType === FormType.QUIZ) {
                                var surveyQuestions_1 = self.surveyCreator.survey.activePage.elementsValue;
                                setTimeout(function () {
                                    surveyQuestions_1.forEach(function (element) {
                                        // setTimeout(() => {
                                        //   const questionDropDown=document.querySelectorAll(`#${element.id} .svda_question_action`)
                                        //   if(questionDropDown[3]){
                                        //   questionDropDown[3].classList.add("displaynone");
                                        //   const questionIconsSeprator=document.querySelectorAll(`#${element.id} .svda_action_separator`);
                                        //   questionIconsSeprator[0].classList.add("separator-display-content");
                                        //   }
                                        // }, 50);
                                        if (element.rows) {
                                            var questionComment = document.getElementById(element.id);
                                            var questionTextarea = document.getElementById("" + element.id + "i");
                                            if (questionTextarea) {
                                                questionTextarea["rows"] = 5;
                                                element.rows = 5;
                                                questionTextarea.style.resize = "none";
                                                questionTextarea.classList.add("comment-textarea");
                                            }
                                            // const editIconAllignment=document.querySelector(`#${element.id} .sv-title-actions__title`);
                                            // editIconAllignment.classList.add("comment-edit-icon");
                                            var questionMarks = element.marks;
                                            var btnParent = document.createElement("div");
                                            btnParent.id = "btnParent_" + element.id;
                                            btnParent.classList.add("pointer-event");
                                            var btn = document.createElement("div");
                                            btn.className = "appended-btn-txt";
                                            btn.id = "btn_" + element.id;
                                            // const btnTxt="Set Marks";
                                            btn.innerText = "" + CurrentLangStrings.default.ed.setMarks + " ( " + questionMarks + " " + CurrentLangStrings.default.ed.marks + " )";
                                            btn.classList.add("appended_btn", "creator-appended-btn", "svda-add-new-item");
                                            btn.title = "" + CurrentLangStrings.default.ed.setMarks + " ( " + questionMarks + ' ' + CurrentLangStrings.default.ed.marks + " )";
                                            btnParent.style.display = "flex";
                                            btnParent.style.justifyContent = "flex-end";
                                            btnParent.style.paddingTop = "2rem";
                                            btnParent.style.width = "calc(100% - 14px)";
                                            // btnParent.style.pointerEvents="all";
                                            btnParent.appendChild(btn);
                                            questionComment.appendChild(btnParent);
                                            btn.addEventListener("click", function (e) {
                                                targetBtnId = e.currentTarget["id"];
                                                // targetEvent = e["path"][2].name;
                                                targetEvent = e['currentTarget']['offsetParent']['name'];
                                                surveyQuestions_1.forEach(function (element) {
                                                    if (element.id == e['currentTarget']['offsetParent'].id) {
                                                        questionEvent = element;
                                                    }
                                                });
                                                console.debug("[Forms] :: Question Marks - ", questionEvent.marks);
                                                document.getElementById("descriptiveQuestionPoints")["value"] = questionEvent.marks;
                                                var statement = document.getElementById("descriptive_statement");
                                                while (statement.firstChild) {
                                                    statement.firstChild.remove();
                                                }
                                                var descriptiveStatement = document.createElement("span");
                                                statement.appendChild(descriptiveStatement);
                                                descriptiveStatement.innerHTML = questionEvent.title;
                                                displayPopupDescriptivemodal();
                                                preventInputMarks("#descriptiveQuestionPoints", document.getElementById("descriptiveQuestionPoints")["max"]);
                                            });
                                        }
                                    });
                                }, 10);
                            }
                            else {
                                var surveyQuestions_2 = self.surveyCreator.survey.activePage.elementsValue;
                                setTimeout(function () {
                                    surveyQuestions_2.forEach(function (element) {
                                        // setTimeout(() => {
                                        //   const questionDropDown=document.querySelectorAll(`#${element.id} .svda_question_action`)
                                        //   if(questionDropDown[3]){
                                        //   questionDropDown[3].classList.add("displaynone");
                                        //   const questionIconsSeprator=document.querySelectorAll(`#${element.id} .svda_action_separator`);
                                        //   questionIconsSeprator[0].classList.add("separator-display-content");
                                        //   }
                                        // }, 50);
                                        if (element.rows) {
                                            var questionTextarea = document.getElementById("" + element.id + "i");
                                            if (questionTextarea) {
                                                questionTextarea.style.resize = "none";
                                                questionTextarea.classList.add("comment-textarea");
                                                questionTextarea["rows"] = 5;
                                                element.rows = 5;
                                            }
                                        }
                                    });
                                }, 10);
                            }
                            _this.calculateScore(JSON.parse(self.surveyCreator.text));
                        }
                        catch (e) {
                            console.log("exception::: ", e);
                        }
                        self.surveyCreator.saveandpublishSurveyFunc =
                            self.saveandpublishMySurvey;
                    }
                });
            }
            catch (_a) { }
            // HideLoading();
        };
        this.savepublishandShareMySurvey = function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var surveyJson, imageQuestion, i, imageQuestionId, imagePickerItems, isImgInDescription, imgPreview;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        surveyJson = JSON.parse(this.surveyCreator.text);
                        this.surveyQuestionEvent = this.surveyCreator.survey.getAllQuestions();
                        if (!(JSON.parse(this.surveyCreator.text).pages[0].elements != null &&
                            JSON.parse(this.surveyCreator.text).pages[0].elements.length > 0)) return [3 /*break*/, 3];
                        imageQuestion = document.querySelectorAll('.image-picker_question');
                        if (imageQuestion.length > 0) {
                            for (i = 0; i < imageQuestion.length; i++) {
                                imageQuestionId = imageQuestion[i].id;
                                imagePickerItems = document.querySelectorAll("#" + imageQuestionId + " .sv_q_imgsel_image");
                                if (imagePickerItems.length == 0) {
                                    FormsEvents.sendEvent(FormsEventsEnum.FORMS_ONEMPTYIMAGEITEM, true);
                                    return [2 /*return*/];
                                }
                            }
                        }
                        isImgInDescription = false;
                        imgPreview = document.getElementsByClassName('desc-preview-img');
                        if (imgPreview.length > 0) {
                            if (surveyJson.pages[0].elements.length !== this.descriptionImgJson.pages[0].elements.length) {
                                isImgInDescription = true;
                                //  this.updateQuestionJsonForDesc(surveyJson);
                            }
                            else {
                                surveyJson = this.descriptionImgJson;
                            }
                        }
                        this.disableButtons(true);
                        // ShowLoading();
                        this.editsurveyId = sessionStorage.getItem("interactionID").toString();
                        if (!this.isStateNew) return [3 /*break*/, 1];
                        //un commented by Fahad
                        // this.updateQuestionJsonForDesc(surveyJson)
                        this.SaveShareSurvey(surveyJson, true);
                        return [3 /*break*/, 3];
                    case 1:
                        this.isDraftForm = true;
                        // this.updateQuestionJsonForDesc(surveyJson);
                        return [4 /*yield*/, this.UpdateSurvey(JSON.stringify(surveyJson), true, false, this.isDraftForm)];
                    case 2:
                        // this.updateQuestionJsonForDesc(surveyJson);
                        _a.sent();
                        this.ShareSurveyOnly(JSON.stringify(surveyJson), true); //because update is same for all cases
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.SaveShareSurvey = function (jsonSurvey, isPublish) {
            try {
                var surveyData = _this.addNewPropertyForDescriptionInJson(JSON.stringify(jsonSurvey));
                if (window['formTitle']) {
                    _this.surveyTitle = window['formTitle'];
                }
                _this.calculateScore(JSON.parse(surveyData));
                console.debug("share");
                var savesharebody = JSON.stringify({
                    title: _this.surveyTitle,
                    data: surveyData.toString(),
                    questionscount: JSON.parse(_this.surveyCreator.text).pages[0].elements
                        .length,
                    active: isPublish,
                    type: _this.formType,
                    bubbleid: _this.sectionsReceived,
                    showresults: true,
                    totalmarks: _this.totalMarks,
                });
                var xhr = new XMLHttpRequest();
                xhr.open("POST", APIURL + "interaction/saveandstartsession");
                // xhr.open('GET', document.referrer  + 'authenticate/ValidateSessionAuthToken' );
                xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
                xhr.setRequestHeader('Authorization', 'Bearer ' + _this.access_token);
                xhr.setRequestHeader('client_id', AuthEnum.CLIENTID);
                xhr.setRequestHeader('client_secret', AuthEnum.CLIENTSECRET);
                xhr.onload = function () {
                    var result = xhr.response ? JSON.parse(xhr.response) : null;
                    if (result && result.responseCode && result.responseCode == 200) {
                        sessionStorage.setItem("interactionID", "");
                        var objtoSend = {
                            interactionid: result.interactionid,
                            state: FormsStateEnum.SHARED,
                            type: _this.formType,
                            title: _this.surveyTitle,
                            bubbleid: _this.sectionsReceived,
                        };
                        // HideLoading();
                        closeWindow(objtoSend); //close creator on success
                        console.debug("share res::::::", result);
                    }
                    else if (xhr.status == 401) {
                        _this.renewSessionAuthorization().then(function () {
                            xhr.open("POST", APIURL + "interaction/saveandstartsession");
                            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
                            xhr.setRequestHeader('Authorization', 'Bearer ' + _this.access_token);
                            xhr.setRequestHeader('client_id', AuthEnum.CLIENTID);
                            xhr.setRequestHeader('client_secret', AuthEnum.CLIENTSECRET);
                            xhr.onload = function () {
                                var result = xhr.response ? JSON.parse(xhr.response) : null;
                                if (result.responseCode == 200) {
                                    sessionStorage.setItem("interactionID", "");
                                    var objtoSend = {
                                        interactionid: result.interactionid,
                                        state: FormsStateEnum.SHARED,
                                        type: _this.formType,
                                        title: _this.surveyTitle,
                                        bubbleid: _this.sectionsReceived,
                                    };
                                    // HideLoading();
                                    closeWindow(objtoSend); //close creator on success
                                    console.debug("share res::::::", result);
                                }
                            };
                            xhr.send(savesharebody);
                        })
                            .catch(function () {
                        });
                    }
                };
                // const parsedCorrect = JSON.parse(savesharebody);
                // // console.log("parsed-Correct-item",parsedCorrect.data);
                // const dataToSend = JSON.parse(parsedCorrect.data);
                xhr.send(savesharebody);
            }
            catch (e) {
                console.debug("add error::: ", e);
            }
            // HideLoading();
        };
        this.ShareSurveyOnly = function (jsonSurvey, isPublish) {
            //Fahad
            try {
                var xhr = new XMLHttpRequest();
                var self = _this;
                xhr.open("POST", APIURL + "InteractionSession/Start?Id=" + self.editsurveyId);
                xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
                xhr.setRequestHeader('Authorization', 'Bearer ' + self.access_token);
                xhr.setRequestHeader('client_id', AuthEnum.CLIENTID);
                xhr.setRequestHeader('client_secret', AuthEnum.CLIENTSECRET);
                xhr.onload = function () {
                    var result = xhr.response ? JSON.parse(xhr.response) : null;
                    if (xhr.status === 200) {
                        if (result.responseCode == 200) {
                            sessionStorage.setItem("interactionID", "");
                            var objtoSend = {
                                interactionid: self.editsurveyId,
                                state: FormsStateEnum.SHARED,
                                title: self.surveyTitle,
                                type: self.formType,
                                bubbleid: self.sectionsReceived,
                            };
                            closeWindow(objtoSend); //close creator on success
                            console.debug("[Form share after save/update]", result);
                        }
                    }
                    else if (xhr.status == 401) {
                        self.renewSessionAuthorization().then(function () {
                            xhr.open("POST", APIURL + "InteractionSession/Start?Id=" + self.editsurveyId);
                            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
                            xhr.setRequestHeader('Authorization', 'Bearer ' + self.access_token);
                            xhr.setRequestHeader('client_id', AuthEnum.CLIENTID);
                            xhr.setRequestHeader('client_secret', AuthEnum.CLIENTSECRET);
                            xhr.onload = function () {
                                var result = xhr.response ? JSON.parse(xhr.response) : null;
                                if (result.responseCode == 200) {
                                    sessionStorage.setItem("interactionID", "");
                                    var objtoSend = {
                                        interactionid: self.editsurveyId,
                                        state: FormsStateEnum.SHARED,
                                        title: self.surveyTitle,
                                        type: self.formType,
                                        bubbleid: self.sectionsReceived,
                                    };
                                    closeWindow(objtoSend);
                                    console.debug("[Form share after save/update]", result);
                                }
                            };
                            xhr.send();
                        })
                            .catch(function () {
                        });
                    }
                };
                xhr.send();
            }
            catch (e) {
                console.debug("add error::: ", e);
            }
            // HideLoading();
        };
        this.saveandpublishMySurvey = function () {
            var surveyJson = JSON.parse(_this.surveyCreator.text);
            //q.choices.forEach(element => {
            _this.surveyQuestionEvent = _this.surveyCreator.survey.getAllQuestions();
            var questions = _this.surveyCreator.survey.getAllQuestions();
            if (questions.length == 0) {
                _this.displayErrorModal();
                return;
            }
            try {
                // this.disableButtons(true);
            }
            catch (error) { }
            // console.log(this.surveyCreator.text);
            // this.disableButtons(true);
            // questions.forEach((element)=>{
            //   document.querySelectorAll()
            // })
            var isImgInDescription = false;
            var imgPreview = document.getElementsByClassName('desc-preview-img');
            if (imgPreview.length > 0) {
                if (surveyJson.pages[0].elements.length !== _this.descriptionImgJson.pages[0].elements.length) {
                    isImgInDescription = true;
                    // this.updateQuestionJsonForDesc(surveyJson);
                }
                else {
                    surveyJson = _this.descriptionImgJson;
                }
            }
            if (JSON.parse(_this.surveyCreator.text).pages[0].elements != null &&
                JSON.parse(_this.surveyCreator.text).pages[0].elements.length > 0) {
                // ShowLoading();
                _this.editsurveyId = sessionStorage.getItem("interactionID").toString();
                if (_this.editsurveyId == "") {
                    _this.SaveSurvey(JSON.stringify(surveyJson), true)
                        .then(function (result) {
                        if (result["responseCode"] === 200) {
                            _this.onSaveFormPromiseResolve(result);
                        }
                    })
                        .catch(function (error) { });
                }
                else {
                    if (_this.isStateNew == true) {
                        _this.SaveSurvey(JSON.stringify(surveyJson), true)
                            .then(function (result) {
                            if (result["responseCode"] === 200) {
                                _this.onSaveFormPromiseResolve(result);
                            }
                        })
                            .catch(function (error) { });
                    }
                    else {
                        _this.UpdateSurvey(JSON.stringify(surveyJson), true, true);
                    }
                }
            }
            else {
                console.debug("Create atleast one question to continue");
                // notify("Create atleast one question to continue", "Error");
            }
        };
        this.UpdateSurvey = function (jsonSurvey, isPublish, isCopy, isAlreadyExist) {
            var imageQuestion = document.querySelectorAll('.image-picker_question');
            if (imageQuestion.length > 0) {
                for (var i = 0; i < imageQuestion.length; i++) {
                    var imageQuestionId = imageQuestion[i].id;
                    var imagePickerItems = document.querySelectorAll("#" + imageQuestionId + " .sv_q_imgsel_image");
                    if (imagePickerItems.length == 0) {
                        FormsEvents.sendEvent(FormsEventsEnum.FORMS_ONEMPTYIMAGEITEM, true);
                        return;
                    }
                }
            }
            try {
                var surveyData_1 = _this.addNewPropertyForDescriptionInJson(jsonSurvey);
                _this.surveyTitle = document.getElementById("lblFormTitle").innerHTML;
                _this.calculateScore(surveyData_1);
                var xhr = new XMLHttpRequest();
                xhr.open("PUT", APIURL + "interaction/update");
                xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
                xhr.setRequestHeader('Authorization', 'Bearer ' + _this.access_token);
                xhr.setRequestHeader('client_id', AuthEnum.CLIENTID);
                xhr.setRequestHeader('client_secret', AuthEnum.CLIENTSECRET);
                xhr.onload = function () {
                    var result = xhr.response ? JSON.parse(xhr.response) : null;
                    if (xhr.status === 200) {
                        if (isPublish == true) {
                            // notify("Updated Successfully", "success");
                            var objtoSend = {
                                interactionid: result.interactionid,
                                state: FormsStateEnum.UPDATED,
                                type: _this.formType,
                                bubbleid: _this.sectionsReceived,
                            };
                            var commentCopy = {
                                state: FormsStateEnum.UPDATED,
                                type: _this.formType,
                            };
                            _this.disableButtons(false);
                            if (!isAlreadyExist) {
                                if (!freeTextCopyEvent) {
                                    closeWindow(objtoSend);
                                }
                                if (isCopy) {
                                    closeWindow(commentCopy);
                                }
                            }
                        }
                    }
                    else if (xhr.status === 401) {
                        _this.renewSessionAuthorization().then(function () {
                            xhr.open("PUT", APIURL + "interaction/update");
                            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
                            xhr.setRequestHeader('Authorization', 'Bearer ' + _this.access_token);
                            xhr.setRequestHeader('client_id', AuthEnum.CLIENTID);
                            xhr.setRequestHeader('client_secret', AuthEnum.CLIENTSECRET);
                            xhr.onload = function () {
                                var result = xhr.response ? JSON.parse(xhr.response) : null;
                                if (xhr.status === 200) {
                                    if (isPublish == true) {
                                        // notify("Updated Successfully", "success");
                                        var objtoSend = {
                                            interactionid: result.interactionid,
                                            state: FormsStateEnum.UPDATED,
                                            type: _this.formType,
                                            bubbleid: _this.sectionsReceived,
                                        };
                                        var commentCopy = {
                                            state: FormsStateEnum.UPDATED,
                                            type: _this.formType,
                                        };
                                        _this.disableButtons(false);
                                        if (!isAlreadyExist) {
                                            if (!freeTextCopyEvent) {
                                                closeWindow(objtoSend);
                                            }
                                            if (isCopy) {
                                                closeWindow(commentCopy);
                                            }
                                        }
                                    }
                                }
                            };
                            xhr.send(JSON.stringify({
                                data: surveyData_1.toString(),
                                active: isPublish,
                                title: _this.surveyTitle == "" || _this.surveyTitle == undefined
                                    ? "untitled-" + Date.now()
                                    : _this.surveyTitle,
                                type: _this.formType,
                                interactionid: _this.editsurveyId,
                                questionscount: JSON.parse(_this.surveyCreator.text).pages[0]
                                    .elements.length,
                                totalmarks: _this.totalMarks,
                            }));
                        })
                            .catch(function () { });
                    }
                };
                xhr.send(JSON.stringify({
                    data: surveyData_1.toString(),
                    active: isPublish,
                    title: _this.surveyTitle == "" || _this.surveyTitle == undefined
                        ? "untitled-" + Date.now()
                        : _this.surveyTitle,
                    type: _this.formType,
                    interactionid: _this.editsurveyId,
                    questionscount: JSON.parse(_this.surveyCreator.text).pages[0].elements
                        .length,
                    //  sections:this.sectionsReceived,
                    totalmarks: _this.totalMarks,
                }));
            }
            catch (e) {
                console.debug("add error::: ", e);
            }
            // HideLoading();
        };
        // verifyImagePickerItems(){
        // }
        this.SaveSurvey = function (jsonSurvey, isPublish) {
            return new Promise(function (resolve, reject) {
                // const savebtn = document.getElementById("lnkSave");
                // savebtn.classList.add("disableSaveButton");
                // const saveSvg = document.getElementById("saveSvg");
                // saveSvg.classList.add("disableSaveSvg");
                var surveyData = _this.addNewPropertyForDescriptionInJson(jsonSurvey);
                var imageQuestion = document.querySelectorAll('.image-picker_question');
                if (imageQuestion.length > 0) {
                    for (var i = 0; i < imageQuestion.length; i++) {
                        var imageQuestionId = imageQuestion[i].id;
                        var imagePickerItems = document.querySelectorAll("#" + imageQuestionId + " .sv_q_imgsel_image");
                        if (imagePickerItems.length == 0) {
                            FormsEvents.sendEvent(FormsEventsEnum.FORMS_ONEMPTYIMAGEITEM, true);
                            return;
                        }
                    }
                }
                try {
                    if (window['formTitle']) {
                        _this.surveyTitle = window['formTitle'];
                    }
                    _this.calculateScore(JSON.parse(surveyData));
                    var xhr = new XMLHttpRequest();
                    xhr.open("POST", APIURL + "interaction/Add");
                    xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
                    xhr.setRequestHeader('Authorization', 'Bearer ' + _this.access_token);
                    xhr.setRequestHeader('client_id', AuthEnum.CLIENTID);
                    xhr.setRequestHeader('client_secret', AuthEnum.CLIENTSECRET);
                    var self = _this;
                    xhr.onload = function () {
                        var result = xhr.response ? JSON.parse(xhr.response) : null;
                        if (xhr.status === 200) {
                            resolve(result);
                        }
                        else if (xhr.status === 401) {
                            self.renewSessionAuthorization().then(function () {
                                xhr.open("POST", APIURL + "interaction/Add");
                                xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
                                xhr.setRequestHeader('Authorization', 'Bearer ' + self.access_token);
                                xhr.setRequestHeader('client_id', AuthEnum.CLIENTID);
                                xhr.setRequestHeader('client_secret', AuthEnum.CLIENTSECRET);
                                xhr.onload = function () {
                                    var result = xhr.response ? JSON.parse(xhr.response) : null;
                                    if (xhr.status === 200) {
                                        resolve(result);
                                    }
                                };
                                xhr.send(JSON.stringify({
                                    data: surveyData.toString(),
                                    active: isPublish,
                                    title: self.surveyTitle == "" || self.surveyTitle == undefined
                                        ? "untitled-" + Date.now()
                                        : self.surveyTitle,
                                    type: self.formType,
                                    questionscount: JSON.parse(self.surveyCreator.text).pages[0].elements
                                        .length,
                                    bubbleid: self.sectionsReceived,
                                    totalmarks: self.totalMarks,
                                }));
                            })
                                .catch(function () {
                                reject();
                            });
                        }
                    };
                    xhr.send(JSON.stringify({
                        data: surveyData.toString(),
                        active: isPublish,
                        title: _this.surveyTitle == "" || _this.surveyTitle == undefined
                            ? "untitled-" + Date.now()
                            : _this.surveyTitle,
                        type: _this.formType,
                        questionscount: JSON.parse(_this.surveyCreator.text).pages[0].elements
                            .length,
                        bubbleid: _this.sectionsReceived,
                        totalmarks: _this.totalMarks,
                    }));
                }
                catch (e) {
                    console.log("add error::: ", e);
                }
            });
            // HideLoading();
        };
        this.disableButtons = function (isDisable) {
            if (isDisable)
                document.getElementById("htmlInteraction").style.pointerEvents = "none";
            else
                document.getElementById("htmlInteraction").style.pointerEvents = "all";
        };
        this.OnLoadCreator = function (access_token, editsurveyId) {
            try {
                SurveyKo.JsonObject.metaData.addProperty("questionbase", "popupdescription:text");
                //score Start
                //Add a property in survey JSON
                SurveyKo.JsonObject.metaData.addProperty("question", {
                    name: "marks:number",
                    default: 0,
                    minValue: 0,
                    category: "data",
                });
                //score End
                //Locale
                try {
                    if (_this.myLanguage == "en")
                        CurrentLangStrings = enLang;
                    else if (_this.myLanguage == "fr")
                        CurrentLangStrings = frLang;
                    else if (_this.myLanguage == "de")
                        CurrentLangStrings = deLang;
                    document.getElementById("lnkPreview").title =
                        CurrentLangStrings.default.ed.testSurvey;
                    document.getElementById("lnkSave").title =
                        CurrentLangStrings.default.ed.saveSurvey;
                    document.getElementById("lnkClose").title =
                        CurrentLangStrings.default.ed.close;
                    document.getElementById("newQuestion").innerText =
                        CurrentLangStrings.default.ed.toolbox;
                    document.getElementById("singleselect").innerText =
                        CurrentLangStrings.default.qt.radiogroup;
                    document.getElementById("multiselect").innerText =
                        CurrentLangStrings.default.qt.checkbox;
                    CurrentLangStrings.default.qt.radiogroup;
                    document.getElementById("type-description").innerText =
                        CurrentLangStrings.default.qt.freetext;
                    document.getElementById("image-type").innerText =
                        CurrentLangStrings.default.qt.image;
                    document.getElementById("creator-modal-text").innerText =
                        CurrentLangStrings.default.ed.setMarks;
                    document.getElementById("creator-body-text").innerText =
                        CurrentLangStrings.default.ed.marks;
                    document.getElementById("nullText").innerText =
                        CurrentLangStrings.default.ed.addtostart;
                    document.getElementById("popup-modal-button").innerText =
                        CurrentLangStrings.default.pe.okay;
                    document.getElementById("creator-question-text").innerText = "" + CurrentLangStrings.default.ed.setMarks + ":";
                    // TODO: Change the strings for Descriptive and Image Type Questions to support multi-lingual
                    // document.getElementById("typeDescription").innerText = CurrentLangStrings.default.qt.checkbox;
                    // document.getElementById("typeImage").innerText = CurrentLangStrings.default.qt.checkbox;
                    document.getElementById("openforResponse").innerText =
                        CurrentLangStrings.default.qt.openforResponse;
                    SurveyCreator.editorLocalization.locales["en"] =
                        CurrentLangStrings.default;
                    SurveyCreator.editorLocalization.locales["fr"] =
                        CurrentLangStrings.default;
                    SurveyCreator.editorLocalization.locales["de"] =
                        CurrentLangStrings.default;
                    // Survey.surveyLocalization.defaultLocale = this.myLanguage;
                    // Survey.surveyLocalization.locales["de"]=CurrentLangStrings.default;
                    _this.surveyCreator.survey.editorLocalization.locales["de"] =
                        CurrentLangStrings.default;
                    _this.surveyCreator.survey.editorLocalization.currentLocale =
                        _this.myLanguage;
                }
                catch (_a) { }
                SurveyCreator.editorLocalization.currentLocale = _this.myLanguage;
                //locale end
                SurveyKo.JsonObject.metaData.addProperty("page", "popupdescription:text");
                try {
                    var options = {
                        showEmbededSurveyTab: true,
                        generateValidJSON: true,
                        showJSONEditorTab: false,
                        showNavigationButtons: false,
                    };
                    _this.surveyCreator = new SurveyCreator.SurveyCreator("surveyCreatorContainer", options);
                }
                catch (e) {
                    //       console.log('exception in creation', e);
                }
                // this.surveyCreator.setIsRequired=true
                _this.surveyCreator.haveCommercialLicense = true;
                _this.surveyCreator.showToolbox = "none";
                _this.surveyCreator.showPropertyGrid = "none";
                _this.surveyCreator.rightContainerActiveItem("toolbox");
                var propertyStopList = [
                    "visibleIf",
                    "enableIf",
                    "requiredIf",
                    "bindings",
                    "defaultValueExpression",
                    "columnsVisibleIf",
                    "rowsVisibleIf",
                    "hideIfChoicesEmpty",
                    "choicesVisibleIf",
                    "choicesEnableIf",
                    "minValueExpression",
                    "maxValueExpression",
                    "calculatedValues",
                    "triggers",
                    "logo",
                    "logoPosition",
                    "logoWidth",
                    "logoHeight",
                    "logoFit",
                    "clearInvisibleValues",
                    "textUpdateMode",
                    "sendResultOnPageNext",
                    "storeOthersAsComment",
                    "focusOnFirstError",
                    "checkErrorsMode",
                    "navigateToUrl",
                    "showCompletedPage",
                    "completedHtml",
                    "navigateToUrlOnCondition",
                    "completedHtmlOnCondition",
                    "loadingHtml",
                    "completedBeforeHtml",
                    "maxTimeToFinish",
                    "maxTimeToFinishPage",
                    "showTimerPanel",
                    "showTimerPanelMode",
                    "Popupdescription",
                    "cookieName",
                    "checkboxClass",
                    "radioClass",
                    "choicesByUrl",
                    "choices",
                    "completeText",
                    "name",
                    "title",
                    "description",
                    "visible",
                    "isRequired",
                    "readOnly",
                    "page",
                    "startWithNewLine",
                    "hideNumber",
                    "state",
                    "titleLocation",
                    "descriptionLocation",
                    "indent",
                    "width",
                    "minWidth",
                    "maxWidth",
                    "valueName",
                    "defaultValue",
                    "useDisplayValuesInTitle",
                    "requiredErrorText",
                    "validators",
                ];
                _this.surveyCreator.onAdornerRendered.add(function (sender, options) {
                    var self = _this;
                    // setTimeout(() => {
                    // self.addImgInDescription(options.question);
                    // }, 10);
                });
                _this.surveyCreator.onDragDropAllow.add(function (sender, options) {
                    var self = _this;
                    //if source and target are same
                    // setTimeout(() => {
                    console.log("window[onquestiondropped]:::::", window['onquestiondropped']);
                    // }, 500);
                    if (options.source.rows && options.target.rows) {
                        window["onQuestionDrop"] = function () {
                            window["targetQuesEvent"] = options.target;
                            var sourceQuestionEvent = options.source;
                            var sourceQuestion = document.getElementById("" + sourceQuestionEvent["id"]);
                            var targetQuestionEvent = options.target;
                            var targetQuestion = document.getElementById("" + targetQuestionEvent["id"]);
                            var TargetQuestionTextarea = document.getElementById("" + targetQuestionEvent["id"] + "i");
                            if (TargetQuestionTextarea) {
                                TargetQuestionTextarea.style.resize = "none";
                                TargetQuestionTextarea.classList.add("comment-textarea");
                                TargetQuestionTextarea["rows"] = 5;
                                options.target.rows = 5;
                            }
                            if (+sessionStorage.getItem("QuestionType") === 1) {
                                var removeDuplicates = document.getElementById("btnParent_" + targetQuestionEvent["id"]);
                                if (removeDuplicates) {
                                    removeDuplicates.remove();
                                }
                                var btnParent = document.createElement("div");
                                console.debug("btnParent div", btnParent);
                                btnParent.id = "btnParent_" + targetQuestionEvent["id"];
                                var btn = document.createElement("div");
                                var btnTxt = CurrentLangStrings.default.ed.setMarks;
                                btn.innerText = "" + btnTxt;
                                btn.id = "btn_" + targetQuestionEvent.id;
                                var defaultMarksSpan = document.createElement("span");
                                defaultMarksSpan.innerText = "" + " ( " + targetQuestionEvent.marks + " " + CurrentLangStrings.default.ed.marks + " )";
                                btn.title = "" + CurrentLangStrings.default.ed.setMarks + defaultMarksSpan.innerHTML;
                                btn.appendChild(defaultMarksSpan);
                                btn.classList.add("appended_btn", "creator-appended-btn", "svda-add-new-item");
                                btnParent.style.display = "flex";
                                btnParent.style.justifyContent = "flex-end";
                                btnParent.style.paddingTop = "2rem";
                                btnParent.classList.add("creator-btn-parent");
                                btnParent.style.width = "calc(100% - 14px)";
                                btnParent.style.pointerEvents = "all";
                                btnParent.appendChild(btn);
                                targetQuestion.appendChild(btnParent);
                                btn.addEventListener("click", function (e) {
                                    targetEvent = e.target["offsetParent"].name;
                                    targetBtnId = e.currentTarget["id"];
                                    questionEvent = targetQuestion;
                                    document.getElementById("descriptiveQuestionPoints")["value"] =
                                        targetQuestionEvent.marks;
                                    var statement = document.getElementById("descriptive_statement");
                                    while (statement.firstChild) {
                                        statement.firstChild.remove();
                                    }
                                    var descriptiveStatement = document.createElement("span");
                                    statement.appendChild(descriptiveStatement);
                                    descriptiveStatement.innerHTML = questionEvent.title;
                                    displayPopupDescriptivemodal();
                                    preventInputMarks("#descriptiveQuestionPoints", document.getElementById("descriptiveQuestionPoints")["max"]);
                                });
                            }
                            // Main.UpdateSurvey(sender.text, true);
                            // setTimeout(() => {
                            //   Main.commentIconsAllignment();
                            // }, 50);
                        };
                    }
                    else {
                        _this.targetDroppedQuestion = options.target;
                        if (document.querySelectorAll("#" + options.source.id + " .desc-preview-img")[0]) {
                            window['previousImgSrc'] = document.querySelectorAll("#" + options.source.id + " .desc-preview-img")[0]['currentSrc'];
                        }
                    }
                });
                // });
                //Question adding/creation event
                var qCountforshowing = 1;
                var selfme = _this;
                _this.surveyCreator.onQuestionAdded.add(function (sender, options) {
                    selfme.calculateScore(sender.JSON);
                    var questions = sender.survey.getAllQuestions();
                    selfme.surveyQuestionEvent = sender.survey.getAllQuestions();
                    setTimeout(function () {
                        if (freeTextCopyEvent && options.question.rows) {
                            var commentCopyQuestion_1 = options.question;
                            console.debug("copy ctreated element.id", options.question.id);
                            var commentQuestion = document.getElementById("" + commentCopyQuestion_1["id"]);
                            console.debug("commentQuestion", commentQuestion);
                            var questionTextarea = document.getElementById("" + commentCopyQuestion_1["id"] + "i");
                            if (questionTextarea) {
                                questionTextarea.style.resize = "none";
                                questionTextarea.classList.add("comment-textarea");
                                questionTextarea["rows"] = 5;
                                options.question.rows = 5;
                            }
                            if (+sessionStorage.getItem("QuestionType") === 1) {
                                var btnParent = document.createElement("div");
                                console.debug("btnParent div", btnParent);
                                btnParent.id = "btnParent_" + commentCopyQuestion_1["id"];
                                var btn = document.createElement("div");
                                var btnTxt = CurrentLangStrings.default.ed.setMarks;
                                btn.innerText = "" + btnTxt;
                                btn.id = "btn_" + commentCopyQuestion_1.id;
                                var defaultMarksSpan = document.createElement("span");
                                defaultMarksSpan.innerText = "" + " ( " + commentCopyQuestion_1.marks + ' ' + CurrentLangStrings.default.ed.marks + " )";
                                btn.title = "" + CurrentLangStrings.default.ed.setMarks + defaultMarksSpan.innerHTML;
                                btn.appendChild(defaultMarksSpan);
                                btn.classList.add("appended_btn", "creator-appended-btn", "svda-add-new-item");
                                btnParent.style.display = "flex";
                                btnParent.style.justifyContent = "flex-end";
                                btnParent.style.paddingTop = "2rem";
                                btnParent.classList.add("creator-btn-parent");
                                btnParent.style.width = "calc(100% - 14px)";
                                btnParent.style.pointerEvents = "all";
                                btnParent.appendChild(btn);
                                commentQuestion.appendChild(btnParent);
                                btn.addEventListener("click", function (e) {
                                    targetEvent = e.target["offsetParent"].name;
                                    targetBtnId = e.currentTarget["id"];
                                    questionEvent = commentCopyQuestion_1;
                                    document.getElementById("descriptiveQuestionPoints")["value"] =
                                        options.question.marks;
                                    var statement = document.getElementById("descriptive_statement");
                                    while (statement.firstChild) {
                                        statement.firstChild.remove();
                                    }
                                    var descriptiveStatement = document.createElement("span");
                                    statement.appendChild(descriptiveStatement);
                                    descriptiveStatement.innerHTML = questionEvent.title;
                                    displayPopupDescriptivemodal();
                                    preventInputMarks("#descriptiveQuestionPoints", document.getElementById("descriptiveQuestionPoints")["max"]);
                                });
                            }
                            Main.UpdateSurvey(sender.text, true, false);
                        }
                    }, 10);
                    console.debug("questions adding event", questions);
                    console.debug("JSON", sender);
                    console.debug("this.surveyCreator", this.surveyCreator);
                    if (sender) {
                        window["childJson"] = sender.JSON.pages[0].elements;
                    }
                    if (questions.length >= 1) {
                        document
                            .getElementById("zero-question-content")
                            .classList.add("displaynone");
                        document
                            .getElementById("zero-question-content")
                            .classList.remove("d-block");
                        var creatorAddDiv = document.getElementById("creatorAddDiv");
                        creatorAddDiv.classList.remove("displaynone");
                        var preview = document.getElementById("lnkPreview");
                        preview.classList.remove("disableSaveButton");
                        var diablePreviewSvg = document.getElementById("preview-svg");
                        diablePreviewSvg.classList.remove("disableSaveSvg");
                    }
                    if (!!options.question) {
                        // if (me.formType != FormType.QUIZ)
                        options.question.isRequired = true;
                        options.question.description = "";
                        options.question.descriptionLocation = "none";
                        var q = options.question;
                        // console.log('options');
                        // console.log(options);
                        // console.log(sender);
                        if (CurrentLangStrings.default) {
                            if (!q.title.startsWith(CurrentLangStrings.default.ed.newQuestionName + " ") &&
                                q.title.includes("question")) {
                                qCountforshowing++;
                                q.name =
                                    CurrentLangStrings.default.ed.newQuestionName + questions.length; // "Question " + questions.length;
                                q.title = q.name;
                                var indx = 1;
                                // q.choices.forEach(element => {
                                //   console.log(element.text, ":::", CurrentLangStrings.default.ed.option);
                                //   if (element.text.includes("item"))
                                //     element.text = element.text.replace('item', CurrentLangStrings.default.ed.option);
                                //   else
                                //     element.text = CurrentLangStrings.default.ed.option + indx;
                                //   if (element.text.includes("Other"))
                                //     element.text = CurrentLangStrings.default.pe.addOther;
                                //   if (element.text.includes("None"))
                                //     element.text = CurrentLangStrings.default.pe.addNone;
                                //   if (element.text.includes("Select All"))
                                //     element.text = CurrentLangStrings.default.pe.addSelectAll;
                                //   //    element.value=element.text.replace('item',CurrentLangStrings.default.ed.option);
                                //   indx = indx + 1;
                                // });
                                selfme.surveyCreator.survey.selectedElement =
                                    selfme.surveyCreator.survey.getQuestionByName(q.name);
                            }
                        }
                        $("#sv_body").scrollTop($("#sv_body").scrollHeight);
                    }
                    // if(!options.question.rows){
                    selfme.addImgInDescription(options.question);
                    // }
                });
                _this.surveyCreator.onItemValueAdded.add(function (sender, options) {
                    if (options.newItem.text.includes("item"))
                        options.newItem.text = options.newItem.text.replace("item", CurrentLangStrings.default.ed.option);
                    else
                        options.newItem.text =
                            CurrentLangStrings.default.ed.option + options.itemValues.length;
                });
                var me = _this;
                _this.surveyCreator.onConditionQuestionsGetList.add(function (editor, options) {
                    options.list.forEach(function (item) {
                        item.text = item.text + " - " + item.name;
                    });
                });
                _this.surveyCreator.onShowingProperty.add(function (sender, options) {
                    options.canShow = propertyStopList.indexOf(options.property.name) == -1;
                    options.showObjectTitles = false;
                    options.showTitlesInExpressions = false;
                    if (me.isPropertyClosed == false) {
                        me.isPropertyClosed = true;
                        var parsed = JSON.parse(sender.text);
                        if (parsed) {
                            window["childJson"] = parsed.pages[0].elements;
                        }
                        try {
                            me.calculateScore(parsed);
                            //    document.getElementById("Qanswerkey").innerHTML="Answer key ("+options.obj.points+" points)";
                        }
                        catch (e) { }
                        try {
                        }
                        catch (_a) { }
                    }
                    setTimeout(function () {
                        me.isPropertyClosed = false;
                    }, 500);
                });
                //preview wala
                _this.surveyCreator.onTestSurveyCreated.add(function (sender, options) {
                    options.survey.showCompletedPage = false;
                });
                var me = _this;
                _this.surveyCreator.toolbarItems.push({
                    id: "custom-preview",
                    visible: true,
                    title: "Preview",
                    action: function () {
                        var options = { showNavigationButtons: false };
                        var testPreviewModal = new Survey.Model(me.surveyCreator.text, options);
                        testPreviewModal.completeText = "";
                        testPreviewModal.showNavigationButtons = false;
                        testPreviewModal.render("surveyContainerInPopup");
                        displayPopup();
                        modal.open();
                    },
                });
                //Add custom button in the toolbar
                //by MOT to get and edit Survey if in edit mode.
                try {
                    if (editsurveyId == "undefined" || editsurveyId == "") {
                        //   if (me.formType == FormType.POLL)
                        var questions = _this.surveyCreator.survey.getAllQuestions();
                        if (questions.length == 0) {
                            var questionBtn = document.getElementById("creatorAddDiv");
                            questionBtn.classList.add("displaynone");
                            var zerQuestionContent = document.getElementById("zero-question-content");
                            zerQuestionContent.className = "d-block";
                            var preview = document.getElementById("lnkPreview");
                            preview.classList.add("disableSaveButton");
                            var diablePreviewSvg = document.getElementById("preview-svg");
                            diablePreviewSvg.classList.add("disableSaveSvg");
                        }
                        // jsonDef = {
                        //   title: "",
                        //   pages: [
                        //     {
                        //       name: "page1",
                        //       elements: [
                        //         {
                        //           type: "radiogroup",
                        //           name: CurrentLangStrings.default.ed.newQuestionName + "1",
                        //           title: CurrentLangStrings.default.ed.newQuestionName + "1",
                        //           descriptionLocation:"none",
                        //           isRequired: true,
                        //           choices: [
                        //             CurrentLangStrings.default.ed.option + "1",
                        //             CurrentLangStrings.default.ed.option + "2",
                        //             CurrentLangStrings.default.ed.option + "3",
                        //           ],
                        //         },
                        //       ],
                        //     },
                        //   ],
                        //   showProgressBar: "top",
                        // };
                        _this.surveyCreator.text = JSON.stringify(jsonDef);
                        // else if (me.formType == FormType.QUIZ)
                        //   this.surveyCreator.text = JSON.stringify(jsonDefQuiz);
                        _this.surveyCreator.saveSurveyFunc = _this.saveandpublishMySurvey; // this.saveMySurvey;
                        //   this.surveyCreator.saveandpublishSurveyFunc = this.saveandpublishMySurvey;
                    }
                    else {
                        _this.getData(access_token, editsurveyId);
                        // this.surveyCreator.text=abc;
                        _this.surveyCreator.saveSurveyFunc = _this.saveandpublishMySurvey; // this.saveMySurvey;
                        //  this.surveyCreator.saveandpublishSurveyFunc = this.saveandpublishMySurvey;
                    }
                    if (_this.isStateNew)
                        _this.surveyCreator.commands.save.toolbar.title =
                            CurrentLangStrings.default.ed.saveSurvey;
                    //this.surveyCreator.getLocString("ed.saveSurvey");
                    else {
                        _this.surveyCreator.commands.save.toolbar.title =
                            CurrentLangStrings.default.ed.UpdateSurvey; // this.surveyCreator.getLocString("ed.UpdateSurvey");
                    }
                }
                catch (e) {
                    console.debug("text for json exception:::", e);
                }
                displayQuestion();
            }
            catch (_b) { }
        };
        this.updateQuestionDescription = function (question, imageUrl) {
            var surveyJson = [];
            surveyJson = JSON.parse(_this.surveyCreator.text);
            console.debug("window['descriptionImgJson']", _this.descriptionImgJson);
            console.debug("[updated actual json]:::", surveyJson);
            surveyJson['pages'][0].elements.forEach(function (element) {
                if (element.name == question.name) {
                    element.description = imageUrl;
                    _this.descriptionImgJson['pages'][0].elements.find(function (previousElement) {
                        if (previousElement && element.name == previousElement.name) {
                            var deletedElement = _this.descriptionImgJson['pages'][0].elements.indexOf(element);
                            _this.descriptionImgJson['pages'][0].elements.splice(deletedElement, 1);
                            return true;
                        }
                    });
                    _this.descriptionImgJson.pages[0].elements.push(element);
                }
            });
        };
        this.updateQuestionJsonForDesc = function (surveyJson, isPreviewModal) {
            console.debug("[ImagedescriptionJson]:::", _this.descriptionImgJson);
            surveyJson.pages[0].elements.map(function (actualQuestion) {
                _this.descriptionImgJson.pages[0].elements.map(function (imgDescQuestion) {
                    if (actualQuestion.name == imgDescQuestion.name) {
                        actualQuestion['description'] = imgDescQuestion['description'];
                    }
                });
            });
            console.debug("[updated actual json]:::", surveyJson);
            _this.descriptionImgJson = surveyJson;
        };
        this.previewButton = function () {
            console.debug("preview");
            var options = { showNavigationButtons: false };
            _this.surveyQuestionEvent = _this.surveyCreator.survey.getAllQuestions();
            var surveyCratorJson = _this.addNewPropertyForDescriptionInJson(_this.surveyCreator.text);
            // var testPreviewModal = new Survey.Model(this.surveyCreator.text, options);
            var testPreviewModal = new Survey.Model(surveyCratorJson, options);
            setTimeout(function () {
                var modalElements = document.querySelectorAll('#mainPreviewModal .sv_q_title');
                _this.showQuestionTypeOnPreview(modalElements, testPreviewModal.activePage.elementsValue);
                _this.createImageForPreview(testPreviewModal.activePage.elementsValue, JSON.parse(surveyCratorJson));
            }, 100);
            setTimeout(function () {
                testPreviewModal.activePage.elementsValue.forEach(function (element) {
                    if (element.contentMode === "image") {
                        var modalImageElement = document.querySelectorAll("#mainPreviewModal #" + element.id);
                        for (var index = 0; index < modalImageElement.length; index++) {
                            var imageDiv = modalImageElement[index].children[1];
                            imageDiv.classList.add("disable-image-div");
                            var imageStatementDiv = modalImageElement[index].children[1];
                            imageStatementDiv.classList.add("disable-element");
                        }
                    }
                    else {
                        var modalOtherElements = document.querySelectorAll("#mainPreviewModal #" + element.id);
                        modalOtherElements[0].classList.add("disable-element");
                    }
                });
            }, 10);
            if (_this.formType == FormType.QUIZ) {
                var questions = _this.surveyCreator.survey.getAllQuestions();
                if (questions.length == 0) {
                    var displayScore = document.getElementById("displayscore");
                    displayScore.classList.add("displaynone");
                }
                else {
                    var score = document.getElementById("totalScore");
                    var displayScore = document.getElementById("displayscore");
                    displayScore.classList.remove("displaynone");
                    displayScore.innerText = score["outerText"];
                }
            }
            testPreviewModal.completeText = "";
            testPreviewModal.showNavigationButtons = false;
            setTimeout(function () {
                testPreviewModal.activePage.elementsValue.forEach(function (element) {
                    if (element.rows) {
                        var questionTextarea = document.getElementById("" + element.id + "i");
                        if (questionTextarea) {
                            questionTextarea.style.resize = "none";
                            questionTextarea.classList.add("comment-textarea");
                            questionTextarea["rows"] = 5;
                            element.rows = 5;
                        }
                        // questionTextarea.style.border-radius=""
                    }
                });
            }, 10);
            testPreviewModal.render("surveyContainerInPopup");
            displayPopup();
            modal.open();
        };
        sessionStorage.setItem("interactionID", "");
        window["getAuthDetails"] = this.getAuthDetails;
        window["AddMCQ"] = this.AddMCQ;
        window["AddSCQ"] = this.AddSCQ;
        window["AddTXT"] = this.AddTXT;
        window["calculateScore"] = this.calculateScore;
        window["AddImageQuestion"] = this.AddImageQuestion;
        window["savepublishandShareMySurvey"] = this.savepublishandShareMySurvey;
        window["previewButton"] = this.previewButton;
        window["saveandpublishMySurvey"] = this.saveandpublishMySurvey;
        window["formType"] = this.formType;
        // window['objForClose'] = this.objForClose;
    }
    SurveyCreatorComponent.prototype.onKeydownHandler = function (event) {
        modal.close();
    };
    SurveyCreatorComponent.prototype.ngOnInit = function () {
        this.KeyClaokApiUrl = configJson["authHostUrl"] + "protocol/openid-connect/";
        Main = this;
        var target = document.getElementById("basebody");
        target.style.background = "#d5d8dc";
        //this.questionType= +sessionStorage.getItem("QuestionType");
    };
    SurveyCreatorComponent.prototype.ngAfterViewInit = function () {
        this.eventHandler();
        //this.questionType=
    };
    SurveyCreatorComponent.prototype.eventHandler = function () {
        document.addEventListener(FormsEventsEnum.FORMS_ONEMPTYIMAGEITEM, this.onEmptyImageItem);
        // document.addEventListener(FormsEventsEnum.FORMS_ANWERKEYEVENT, this.anwerKeyEvent);
        document.addEventListener(FormsEventsEnum.FORMS_ONUPDATEFREETEXTMARKS, this.onUpdateFreeTextMarks);
        // document.addEventListener(FormsEventsEnum.FORMS_NONEPRESSED, this.nonePressed);
        document.addEventListener(FormsEventsEnum.FORMS_ONUNSUPPORTEDFILE, this.onUnsupportedFile);
        document.addEventListener(FormsEventsEnum.FORMS_ONFREETEXTCOPY, this.onFreeTextCopy);
        document.addEventListener(FormsEventsEnum.FORMS_ONITEMDELETE, this.onitemdelete);
        document.addEventListener(FormsEventsEnum.FORMS_ONNEWITEMADDED, this.onnewitemadded);
        document.addEventListener(FormsEventsEnum.FORMS_IMAGEITEMIMAGEPLUS, this.itemaddedthrougnhimageplus);
        document.addEventListener(FormsEventsEnum.FORMS_DESCRIPTIONIMAGEDRAGENDED, this.descriptionimagedragended);
        document.addEventListener(FormsEventsEnum.FORMS_ITEMDRAGGED, this.itemdragged);
    };
    SurveyCreatorComponent.prototype.removeEvents = function () {
        document.removeEventListener(FormsEventsEnum.FORMS_ONEMPTYIMAGEITEM, this.onEmptyImageItem);
        // document.removeEventListener(FormsEventsEnum.FORMS_ANWERKEYEVENT, this.anwerKeyEvent);
        document.removeEventListener(FormsEventsEnum.FORMS_ONUPDATEFREETEXTMARKS, this.onUpdateFreeTextMarks);
        // document.removeEventListener(FormsEventsEnum.FORMS_NONEPRESSED, this.nonePressed);
        document.removeEventListener(FormsEventsEnum.FORMS_ONUNSUPPORTEDFILE, this.onUnsupportedFile);
        document.removeEventListener(FormsEventsEnum.FORMS_ONFREETEXTCOPY, this.onFreeTextCopy);
        document.removeEventListener(FormsEventsEnum.FORMS_ONITEMDELETE, this.onitemdelete);
        document.removeEventListener(FormsEventsEnum.FORMS_ONNEWITEMADDED, this.onnewitemadded);
        document.removeEventListener(FormsEventsEnum.FORMS_DESCRIPTIONIMAGEDRAGENDED, this.descriptionimagedragended);
    };
    SurveyCreatorComponent.prototype.ngOnDestroy = function () {
        this.removeEvents();
        // this.questionType=null;
    };
    SurveyCreatorComponent.prototype.isJson = function (str) {
        try {
            JSON.parse(str);
        }
        catch (e) {
            return {};
        }
        return JSON.parse(str);
    };
    SurveyCreatorComponent.prototype.renewSessionAuthorization = function () {
        var _this = this;
        var retry = 0;
        var body = new HttpParams()
            .set('grant_type', AuthEnum.GRANTTYPE_REFRESHTOKEN)
            .set('client_id', AuthEnum.CLIENTID)
            .set('client_secret', AuthEnum.CLIENTSECRET)
            .set('refresh_token', this.refresh_token);
        return new Promise(function (resolve, reject) {
            try {
                var request = new XMLHttpRequest();
                request.onreadystatechange = function () {
                    if (request.readyState === 4) {
                        var response = _this.isJson(request.response);
                        if (request.status === 200 && response.access_token) {
                            _this.access_token = response.access_token;
                            _this.refresh_token = response.refresh_token;
                            resolve(response);
                        }
                        else {
                            reject(response);
                        }
                    }
                };
                request.open('POST', _this.KeyClaokApiUrl + 'token');
                request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
                request.onload = function () { };
                request.send(body.toString());
            }
            catch (error) { }
        });
    };
    SurveyCreatorComponent.prototype.AddMCQ = function () {
        Main.surveyCreator.survey.pages[0].addNewQuestion("checkbox");
        window["previousImgSrc"] = '';
    };
    SurveyCreatorComponent.prototype.AddSCQ = function () {
        Main.surveyCreator.survey.pages[0].addNewQuestion("radiogroup");
        window["previousImgSrc"] = '';
    };
    SurveyCreatorComponent.prototype.AddTXT = function () {
        freeTextCopyEvent = false;
        var descriptionObj = Main.surveyCreator.survey.pages[0].addNewQuestion("comment");
        window["previousImgSrc"] = '';
        setTimeout(function () {
            var newDescriptionElement = document.getElementById(descriptionObj["id"]);
            var questionTextarea = document.getElementById("" + descriptionObj["id"] + "i");
            if (questionTextarea) {
                questionTextarea.style.resize = "none";
                questionTextarea.classList.add("comment-textarea");
                questionTextarea["rows"] = 5;
                descriptionObj.rows = 5;
            }
            if (+sessionStorage.getItem("QuestionType") === 1) {
                var btnParent = document.createElement("div");
                btnParent.id = "btnParent_" + descriptionObj["id"];
                var btn = document.createElement("div");
                var btnTxt = CurrentLangStrings.default.ed.setMarks;
                btn.innerText = "" + btnTxt;
                btn.id = "btn_" + newDescriptionElement.id;
                var defaultMarksSpan = document.createElement("span");
                defaultMarksSpan.innerText = " ( 0 " + CurrentLangStrings.default.ed.marks + " )";
                btn.appendChild(defaultMarksSpan);
                btn.classList.add("appended_btn", "creator-appended-btn", "svda-add-new-item");
                btn.title = CurrentLangStrings.default.ed.setMarks + " ( 0 " + CurrentLangStrings.default.ed.marks + " )";
                btnParent.style.display = "flex";
                btnParent.style.justifyContent = "flex-end";
                btnParent.style.paddingTop = "2rem";
                btnParent.classList.add("creator-btn-parent");
                btnParent.style.width = "calc(100% - 14px)";
                btnParent.style.pointerEvents = "all";
                btnParent.appendChild(btn);
                newDescriptionElement.appendChild(btnParent);
                btn.addEventListener("click", function (e) {
                    targetEvent = e.target["offsetParent"].name;
                    targetBtnId = e.currentTarget["id"];
                    questionEvent = descriptionObj;
                    document.getElementById("descriptiveQuestionPoints")["value"] =
                        descriptionObj.marks;
                    var statement = document.getElementById("descriptive_statement");
                    while (statement.firstChild) {
                        statement.firstChild.remove();
                    }
                    var descriptiveStatement = document.createElement("span");
                    statement.appendChild(descriptiveStatement);
                    descriptiveStatement.innerHTML = questionEvent.title;
                    displayPopupDescriptivemodal();
                    preventInputMarks("#descriptiveQuestionPoints", document.getElementById("descriptiveQuestionPoints")["max"]);
                });
            }
        }, 10);
    };
    SurveyCreatorComponent.prototype.AddImageQuestion = function () {
        Main.surveyCreator.survey.pages[0].addNewQuestion("imagepicker");
        window["previousImgSrc"] = '';
    };
    SurveyCreatorComponent.prototype.displayErrorModal = function () {
        var dialogRef = this.dialog.open(PopupModalComponent, {
            data: {
                title: CurrentLangStrings.default.ed.notallowed,
                content: CurrentLangStrings.default.pe.addOneQuestion,
                type: "red",
            },
        });
        dialogRef.afterClosed().subscribe(function (confirmed) { });
    };
    SurveyCreatorComponent.prototype.calculateScore = function (json, currentQuestion, isAlreadyExist) {
        var _this = this;
        var total = 0;
        try {
            json.pages[0].elements.forEach(function (element) {
                if (element.marks != undefined &&
                    element.marks != NaN &&
                    element.marks > 0)
                    total = total + element.marks;
                else
                    _this.doAllQuestionhaveMarks = false;
            });
            this.totalMarks = total;
            sessionStorage.setItem("totalMarks", this.totalMarks);
            if (+sessionStorage.getItem("QuestionType") == FormType.QUIZ) {
                document.getElementById("totalScore").innerHTML =
                    "Total Marks: " + total;
                // if(!currentQuestion.rows){
                if (!document.getElementById("descParentdiv_" + currentQuestion.id)) {
                    var descriptionImgParent_1 = document.createElement('div');
                    descriptionImgParent_1.setAttribute('id', "descParentdiv_" + currentQuestion.id);
                    descriptionImgParent_1.classList.add('description-image');
                    var input_1 = document.createElement('input');
                    input_1.type = "file";
                    var img_1 = document.createElement('img');
                    img_1.classList.add('desc-preview-img');
                    img_1.setAttribute('id', "descImg_" + currentQuestion.id);
                    input_1.setAttribute('id', "chooseImg_" + currentQuestion.id);
                    input_1.classList.add('desc-image-input');
                    var addImgInDescriptionBtn_1 = document.createElement('div');
                    var svgParentDiv = document.createElement('div');
                    // addImgInDescriptionBtn.innerHTML=`${CurrentLangStrings.default.qt.clickimagetoadd}`;
                    var svgElem = document.createElementNS("http://www.w3.org/2000/svg", "svg");
                    svgElem.classList.add('default-svg-size');
                    var useElem = document.createElementNS("http://www.w3.org/2000/svg", "use");
                    useElem.setAttributeNS(null, "href", "assets/sprite.svg#plus-icon");
                    svgElem.appendChild(useElem);
                    svgParentDiv.appendChild(svgElem);
                    svgParentDiv.classList.add('add-image-svg-div');
                    addImgInDescriptionBtn_1.append(svgParentDiv);
                    addImgInDescriptionBtn_1.classList.add('add-description-image-btn', 'svda-add-new-item');
                    addImgInDescriptionBtn_1.setAttribute('id', "descImgBtn_" + currentQuestion.id);
                    if (isAlreadyExist) {
                        descriptionImgParent_1.appendChild(input_1);
                        descriptionImgParent_1.appendChild(addImgInDescriptionBtn_1);
                    }
                    if (window["previousImgSrc"] && window["previousImgSrc"] !== "" && isAlreadyExist) {
                        img_1["src"] = window["previousImgSrc"];
                        descriptionImgParent_1.appendChild(img_1);
                        addImgInDescriptionBtn_1.classList.add("d-none");
                    }
                    else {
                        addImgInDescriptionBtn_1.classList.remove("d-none");
                    }
                    setTimeout(function () {
                        var questionDescription = document.querySelectorAll("#" + currentQuestion.id + " .show-question-description");
                        if (isAlreadyExist) {
                            questionDescription[0].insertBefore(descriptionImgParent_1, questionDescription[0].firstChild);
                        }
                        addImgInDescriptionBtn_1.addEventListener("click", function () {
                            input_1.click();
                        });
                        input_1.addEventListener('change', function (event) {
                            var input = event.target;
                            if (input['files'] && input['files'][0]) {
                                var reader = new FileReader();
                                reader.onload = (function (e) {
                                    var descImg = document.getElementById("descImg_" + currentQuestion.id);
                                    if (descImg) {
                                        descImg.remove();
                                    }
                                    img_1["src"] = e.target["result"].toString();
                                    descriptionImgParent_1.appendChild(img_1);
                                    // this.updateQuestionDescription(currentQuestion,e.target['result'])
                                });
                                reader.readAsDataURL(input['files'][0]);
                            }
                        });
                    }, 100);
                }
                // }
            }
        }
        catch (_a) { }
    };
    SurveyCreatorComponent.prototype.onSaveFormPromiseResolve = function (result) {
        var self = this;
        self.editsurveyId = result.interactionid;
        sessionStorage.setItem("interactionID", result.interactionid);
        // notify("Saved Successfully", "success");
        // if (isPublish == true)
        var objtoSend = {
            interactionid: result.interactionid,
            state: FormsStateEnum.SAVED,
            type: self.formType,
            bubbleid: self.sectionsReceived,
        };
        self.surveyCreator.commands.save.toolbar.title =
            self.surveyCreator.getLocString("ed.UpdateSurvey");
        self.isStateNew = false;
        // if (!freeTextCopyEvent) {
        closeWindow(objtoSend);
        // }
        self.disableButtons(false);
    };
    SurveyCreatorComponent.prototype.showQuestionTypeOnPreview = function (questions, surveyQuestions) {
        try {
            for (var i = 0; i < questions.length; i++) {
                var questionNameParent = document.createElement('div');
                var questionName = document.createElement('span');
                questionName.classList.add('preview-question-type');
                questionNameParent.classList.add('preview-question-type-parent', 'd-flex-center');
                var duplicateQuestionHeading = document.querySelectorAll("#" + questions[i].id);
                if (duplicateQuestionHeading.length > 1) {
                    duplicateQuestionHeading[1].id = questions[i].id + "_modal";
                    questionNameParent.setAttribute('id', questions[i].id + "_quest-parent");
                    questionName.setAttribute('id', questions[i].id + "_preview-type");
                    questionNameParent.appendChild(questionName);
                    duplicateQuestionHeading[1].appendChild(questionNameParent);
                }
                else {
                    var questHeading = document.getElementById("" + questions[i].id);
                    questHeading.id = questions[i].id + "_modal";
                    questionNameParent.setAttribute('id', questions[i].id + "_quest-parent");
                    questionName.setAttribute('id', questions[i].id + "_preview-type");
                    questionNameParent.appendChild(questionName);
                    questHeading.appendChild(questionNameParent);
                }
                if (surveyQuestions[i].getType() === 'radiogroup') {
                    questionName.innerHTML = 'Single Choice';
                    document.getElementById(questions[i].id + "_preview-type").innerText =
                        CurrentLangStrings.default.qt.radiogroup;
                    var carretSvg = document.createElement('span');
                    carretSvg.classList.add('caret', 'align-preview-caret', 'color-default');
                    questionNameParent.appendChild(carretSvg);
                }
                else if (surveyQuestions[i].getType() === 'checkbox') {
                    questionName.innerHTML = 'Multiple Choice';
                    document.getElementById(questions[i].id + "_preview-type").innerText =
                        CurrentLangStrings.default.qt.checkbox;
                    var carretSvg = document.createElement('span');
                    carretSvg.classList.add('caret', 'align-preview-caret', 'color-default');
                    questionNameParent.appendChild(carretSvg);
                }
                else if (surveyQuestions[i].getType() === 'comment') {
                    questionName.innerHTML = 'Free Text';
                    document.getElementById(questions[i].id + "_preview-type").innerText =
                        CurrentLangStrings.default.qt.freetext;
                }
                else if (surveyQuestions[i].getType() === 'imagepicker') {
                    questionName.innerHTML = 'Image';
                    document.getElementById(questions[i].id + "_preview-type").innerText =
                        CurrentLangStrings.default.qt.image;
                }
                else {
                    console.log("invalid quetion type");
                }
            }
        }
        catch (_a) {
        }
    };
    SurveyCreatorComponent.prototype.addImgInDescription = function (currentQuestion, isDraftQuestion, draftImgDescJson) {
        var _this = this;
        if (!document.getElementById("descParentdiv_" + currentQuestion.id)) {
            var descriptionImgParent_2 = document.createElement('div');
            descriptionImgParent_2.setAttribute('id', "descParentdiv_" + currentQuestion.id);
            descriptionImgParent_2.classList.add('description-image');
            var input_2 = document.createElement('input');
            var addImgInDescriptionBtn_2 = document.createElement('div');
            var svgParentDiv = document.createElement('div');
            var svgElem = document.createElementNS("http://www.w3.org/2000/svg", "svg");
            svgElem.classList.add('default-svg-size');
            var useElem = document.createElementNS("http://www.w3.org/2000/svg", "use");
            useElem.setAttributeNS(null, "href", "assets/sprite.svg#plus-icon");
            svgElem.appendChild(useElem);
            svgParentDiv.appendChild(svgElem);
            svgParentDiv.classList.add('add-image-svg-div');
            addImgInDescriptionBtn_2.classList.add('add-description-image-btn', 'svda-add-new-item');
            addImgInDescriptionBtn_2.setAttribute('id', "descImgBtn_" + currentQuestion.id);
            addImgInDescriptionBtn_2.append(svgParentDiv);
            input_2.type = "file";
            var img_2 = document.createElement('img');
            img_2.classList.add('desc-preview-img');
            img_2.setAttribute('id', "descImg_" + currentQuestion.id);
            input_2.setAttribute('id', "chooseImg_" + currentQuestion.id);
            input_2.classList.add('desc-image-input');
            if (descriptionImgParent_2.id.includes('sq')) {
                descriptionImgParent_2.appendChild(input_2);
                descriptionImgParent_2.appendChild(addImgInDescriptionBtn_2);
            }
            setTimeout(function () {
                if (window["previousImgSrc"]) {
                    img_2["src"] = window["previousImgSrc"];
                    descriptionImgParent_2.appendChild(img_2);
                    addImgInDescriptionBtn_2.classList.add("d-none");
                    img_2.addEventListener("click", function () {
                        input_2.click();
                    });
                }
                else {
                    addImgInDescriptionBtn_2.classList.remove("d-none");
                }
            }, 100);
            if (isDraftQuestion) {
                setTimeout(function () {
                    draftImgDescJson.pages[0].elements.forEach(function (element) {
                        if (element.name == currentQuestion.name) {
                            if (element.imageDescription !== "" && element.imageDescription.includes('data:image')) {
                                descriptionImgParent_2.appendChild(img_2);
                                img_2['src'] = element.imageDescription.toString();
                                if (img_2 && img_2["src"]) {
                                    addImgInDescriptionBtn_2.classList.add("d-none");
                                }
                                else {
                                    addImgInDescriptionBtn_2.classList.remove("d-none");
                                }
                            }
                            else {
                                descriptionImgParent_2.appendChild(addImgInDescriptionBtn_2);
                            }
                        }
                    });
                }, 100);
            }
            setTimeout(function () {
                var questionDescription = document.querySelectorAll("#" + currentQuestion.id + " .show-question-description");
                if (!document.getElementById("descParentdiv_" + currentQuestion.id) && questionDescription[0]) {
                    questionDescription[0].insertBefore(descriptionImgParent_2, questionDescription[0].firstChild);
                }
                addImgInDescriptionBtn_2.addEventListener("click", function () {
                    input_2.click();
                });
                input_2.addEventListener('change', function (event) {
                    var input = event.target;
                    if (input['files'] && input['files'][0]) {
                        var IsSuppotedFile = _this.checkSupportedImagesType(event);
                        if (IsSuppotedFile) {
                            var reader = new FileReader();
                            reader.onload = (function (e) {
                                var descImg = document.getElementById("descImg_" + currentQuestion.id);
                                if (descImg) {
                                    descImg.remove();
                                }
                                img_2['src'] = e.target['result'].toString();
                                window['previousImgSrc'] = e.target['result'].toString();
                                descriptionImgParent_2.appendChild(img_2);
                                if (img_2 && img_2["src"]) {
                                    addImgInDescriptionBtn_2.classList.add("d-none");
                                }
                                else {
                                    addImgInDescriptionBtn_2.classList.remove("d-none");
                                }
                                img_2.addEventListener('click', function () {
                                    input['click']();
                                });
                            });
                            reader.readAsDataURL(input['files'][0]);
                        }
                    }
                });
            }, 300);
        }
    };
    SurveyCreatorComponent.prototype.addNewPropertyForDescriptionInJson = function (questionJson) {
        var parsedJson = JSON.parse(questionJson);
        parsedJson.pages[0].elements.forEach(function (question) {
            question.imageDescription = "";
        });
        this.surveyQuestionEvent.forEach(function (element, i) {
            var image = document.getElementById("descImg_" + element.id);
            if (image && image['src'] && parsedJson.pages[0].elements[i].name === element.name) {
                parsedJson.pages[0].elements[i].imageDescription = image['src'];
            }
        });
        return JSON.stringify(parsedJson);
    };
    SurveyCreatorComponent.prototype.checkSupportedImagesType = function (event) {
        var imagePickerExtension = ["jpg", "jpeg", "png", 'bmp', 'gif', "webp", "svg"];
        var Image = event.target['files'][0].name.split('.');
        var imageExtensionIndex = Image.length - 1;
        var ImageExtension = Image[imageExtensionIndex];
        if (imagePickerExtension.indexOf(ImageExtension.toLowerCase()) == -1) {
            var unSupportedFiletype = {
                state: FormsStateEnum.UNSUPPORTEDFILE
            };
            closeWindow(unSupportedFiletype);
            return false;
        }
        else {
            return true;
        }
    };
    SurveyCreatorComponent.prototype.createImageForPreview = function (surveyQuestions, creatorJson) {
        surveyQuestions.forEach(function (question, i) {
            if (question.name === creatorJson.pages[0].elements[i].name && creatorJson.pages[0].elements[i].imageDescription && creatorJson.pages[0].elements[i].imageDescription.includes('data:image')) {
                var questionDescription = document.querySelectorAll("#" + question.id + " .sv_q_description");
                questionDescription[0].classList.remove('sv_q_description');
                questionDescription[0].classList.add('img-preview-description');
                var imgParent = document.createElement('div');
                imgParent.classList.add('preview-modal-img');
                var img = document.createElement('img');
                img.src = creatorJson.pages[0].elements[i].imageDescription;
                imgParent.appendChild(img);
                var descImgQuestion = document.querySelectorAll("#" + question.id);
                descImgQuestion[0].children[0].setAttribute('id', question.id + "_preview-modal-header");
                var previewQuestionType = document.getElementById(question.id + "_ariaTitle_modal_quest-parent");
                var previewModalHeader = document.getElementById(question.id + "_ariaTitle_modal");
                var removedElement = previewModalHeader.removeChild(previewQuestionType);
                descImgQuestion[0].children[0]['classList'].add('preview-modal-header');
                descImgQuestion[0].children[0].appendChild(imgParent);
                var headingParent = document.createElement('div');
                headingParent.classList.add('modal-heading-parent');
                headingParent.append(previewModalHeader, removedElement);
                descImgQuestion[0].children[0].insertBefore(headingParent, descImgQuestion[0].children[0].firstChild);
                var textDescription = document.querySelectorAll("#" + question.id + "_preview-modal-header .img-preview-description");
                textDescription[0].insertBefore(imgParent, textDescription[0].firstChild);
                textDescription[0].classList.add('preview-model-description');
            }
        });
    };
    return SurveyCreatorComponent;
}());
export { SurveyCreatorComponent };
