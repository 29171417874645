import { Component, HostListener } from "@angular/core";
import { FormsEventsEnum } from "app/enums/app.enums";
import defaultJson from "../../assets/amigo.json";
import * as html2pdf from 'html2pdf.js';
declare var FormsEvents;
enum FormType {
  POLL = 0,
  QUIZ = 1,
}
@Component({
  selector: "assessment-page",
  templateUrl: "./assessment.page.html",
  host:{  'id': 'assessment-container' }
})
export class SurveyassessmentPage {
  @HostListener("document:keydown.escape", ["$event"]) onKeydownHandler(
    event: KeyboardEvent
  ) {
    this.closeAssessmentmodal();
  }
  title = "assessment";
  dummyJson;
  formObject;
  pageHeader: "Form assessment";
  constructor() {
    this.dummyJson = defaultJson;
  }

  sendData(result) {
    //TODO update with your own behavior
    // console.debug(result);

  }
  closeAssessmentmodal() {
    var x = document.getElementById("descriptiveAssessmentModelSurvey");
    if (x.style.display === "none") {
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
    console.debug("close clicked");
  }
  setAssessmentMarks() {
    var assessmentInputMarks = document.getElementById("descriptive-assessment-points");
    console.debug(
      "The Marks of descriptvie Question is:::",
      assessmentInputMarks["value"]
    );
    FormsEvents.sendEvent(FormsEventsEnum.FORMS_ONUPDATEASSESSMENTMARKS, true);
    this.closeAssessmentmodal();
  }
  onObjectReceived(event) {
    this.formObject = event;
  }
}
