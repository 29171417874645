/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../components/survey.analytics.tabulator.ngfactory";
import * as i2 from "../components/survey.analytics.tabulator";
import * as i3 from "@angular/common/http";
import * as i4 from "./analytics.tabulator.page";
var styles_AnalyticsTabulatorPage = [];
var RenderType_AnalyticsTabulatorPage = i0.ɵcrt({ encapsulation: 2, styles: styles_AnalyticsTabulatorPage, data: {} });
export { RenderType_AnalyticsTabulatorPage as RenderType_AnalyticsTabulatorPage };
export function View_AnalyticsTabulatorPage_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Results Table - display survey results in the table form"])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Uses Tabulator. Supports modern browsers."])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "survey-analytics-tabulator", [], null, null, null, i1.View_SurveyAnalyticsTabulatorComponent_0, i1.RenderType_SurveyAnalyticsTabulatorComponent)), i0.ɵdid(6, 114688, null, 0, i2.SurveyAnalyticsTabulatorComponent, [i3.HttpClient], null, null)], function (_ck, _v) { _ck(_v, 6, 0); }, null); }
export function View_AnalyticsTabulatorPage_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "analytics-tabulator-page", [], null, null, null, View_AnalyticsTabulatorPage_0, RenderType_AnalyticsTabulatorPage)), i0.ɵdid(1, 49152, null, 0, i4.AnalyticsTabulatorPage, [], null, null)], null, null); }
var AnalyticsTabulatorPageNgFactory = i0.ɵccf("analytics-tabulator-page", i4.AnalyticsTabulatorPage, View_AnalyticsTabulatorPage_Host_0, {}, {}, []);
export { AnalyticsTabulatorPageNgFactory as AnalyticsTabulatorPageNgFactory };
