/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../../surveyviewer.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./surveypreview.component";
import * as i3 from "@angular/router";
import * as i4 from "@angular/common/http";
var styles_SurveyPreviewComponent = [i0.styles];
var RenderType_SurveyPreviewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SurveyPreviewComponent, data: {} });
export { RenderType_SurveyPreviewComponent as RenderType_SurveyPreviewComponent };
export function View_SurveyPreviewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "scoreParentothers"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "scoreHeader"], ["id", "totalScore"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "survey-container contentcontainer codecontainer"], ["id", "survey-preview"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["id", "surveyElement"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["id", "surveyResult"]], null, null, null, null, null))], null, null); }
export function View_SurveyPreviewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "surveypreview", [], null, null, null, View_SurveyPreviewComponent_0, RenderType_SurveyPreviewComponent)), i1.ɵdid(1, 114688, null, 0, i2.SurveyPreviewComponent, [i3.Router, i4.HttpClient], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SurveyPreviewComponentNgFactory = i1.ɵccf("surveypreview", i2.SurveyPreviewComponent, View_SurveyPreviewComponent_Host_0, { json: "json" }, { submitSurvey: "submitSurvey", rbcFormObject: "rbcFormObject" }, []);
export { SurveyPreviewComponentNgFactory as SurveyPreviewComponentNgFactory };
