
  <div class="export-btn-parent">
  <button matripple class="rbcButtons btnFilledBlue" (click)="exportToPdf()">
  <span id="export-btn-text" *ngIf="!btnLoader;else showDots">Export</span>
  <ng-template #showDots>
        <div class="dots-container">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
        </div>
    </ng-template>
</button>
  </div>
  <div class="scoreParentothers viewer-score-parent" >
  <div class="viewer-page-export" id="pdf-export-btn-viewer">
  </div>
  <div id="totalScore" class="scoreHeader viewer-score"></div>
  </div>
  <div class="survey-container contentcontainer codecontainer">
    <div id="surveyElement"></div>
    <div id="surveyResult"></div>
  </div>