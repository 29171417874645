<div class="container">
  <div class="jumbotron">
    <h2>Export survey to a PDF document</h2>
    <p>
      The SurveyJS PDF Export library allows you to render SurveyJS Library
      surveys to PDF in a browser which can be later emailed or printed.
    </p>
    <p>Click the button below to get a PDF document.</p>
  </div>
  <div>
    <button (click)="savePDF()">Save PDF</button>
  </div>
</div>
