import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Tabulator } from "survey-analytics/survey.analytics.tabulator";
import * as Survey from "survey-angular";

declare var APIURL;

@Component({
  // tslint:disable-next-line:component-selector
  selector: "survey-analytics-tabulator",
  template: ` <div id="surveyAnalyticsTabulatorContainer"></div> `,
})
export class SurveyAnalyticsTabulatorComponent implements OnInit {
  @Input() json: any;
  @Output() surveySaved: EventEmitter<Object> = new EventEmitter();
  AuthToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJBbWlnb1NvZnR3YXJlIiwic3ViIjoiT1BDWFJBUEkiLCJlbWFpbCI6ImFzc2lzdGFuY2VAYW1pZ28tc29mdHdhcmUuY29tIiwicm9sZSI6Ik1hbnVmYWN0dXJlciIsImh0dHA6Ly9zY2hlbWFzLm1pY3Jvc29mdC5jb20vd3MvMjAwOC8wNi9pZGVudGl0eS9jbGFpbXMvaXNwZXJzaXN0ZW50IjoiVHJ1ZSIsImlhdCI6MTYyNjUyMDU5MSwiaHR0cDovL3NjaGVtYXMubWljcm9zb2Z0LmNvbS93cy8yMDA4LzA2L2lkZW50aXR5L2NsYWltcy92ZXJzaW9uIjoiUHJvZHVjdGlvbiIsImV4cCI6MTYyNjUzNDk5MSwiYXVkIjoiT1BDWFJBUEkiLCJMSUkiOiJGYWxzZSIsIlVOU1QiOiJ0ZWFjaGVyLndiMUB0ZXN0LmNvbSIsIlVUWVBFIjoiU2VydmVyIiwiU0lURUNPREUiOiI2MGYxMmI5NmIzMjMzOGQ1OWFiNWVkYTMiLCJSQkFVVEhUT0tFTiI6IiIsIkxJRE9TIjoiIiwiTVBUIjoiVHJ1ZSIsImV4cGlyZXNfYXQiOiIxNjI2NTM0OTkxIiwiUlRCIjoiVHJ1ZSIsImV4cGlyZXNfbWludXRlcyI6IjI0MCIsIm5iZiI6MTYyNjUyMDU5MX0.yt-q5-E14XEEODv9KRcVP3acmJcwL6hXN67dVcC5BUk';
  constructor(private http: HttpClient) { }

  ngOnInit() {
    const headers = { 'AuthToken': this.AuthToken };
    var JsonbyCreator = "";//sessionStorage.getItem("creatorJson");
    var id = sessionStorage.getItem('pollid');
    //var id = 'cba7ae3a32f20a82997b7f67efd334aa';
    this.http.get<any>(APIURL + 'Poll/GetById?Id=' + id, { headers }).subscribe(data => {
      //   this.http.get<any>(this.APIURL + '/Poll/Get', { headers }).subscribe(data => {

      JsonbyCreator = JSON.parse(data.polls[0].data);
      const survey = new Survey.Model(JsonbyCreator);  //json);
      this.http.get<any>(APIURL + 'PollResult/GetByPollId?Id=' + id, { headers }).subscribe(data11 => {
        var Rdata = data11.pollsresults;// [JSON.parse(data11.pollsresults[0].data)];
        var dataFinal = [];
        Rdata.forEach(function (value) {

          dataFinal.push(JSON.parse(value.data));
        });

        var normalizedData = dataFinal.map(function (item) {
          delete item.email;
          survey.getAllQuestions().forEach(function (q) {
            if (item[q.name] === undefined) {
              item[q.name] = "";
            }
          });
          item['Email'] = item.email;

          return item;
        });


        var table = new Tabulator(survey, normalizedData, null);
        // table.render(document.getElementById("surveyAnalyticsContainer"));
        table.haveCommercialLicense=true;
      });

    });

  }
}
