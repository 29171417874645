<div class="dropup  PreviewtopDiv width-100 header-bg-color">
  <div class="FormName FormNamePosition">
      <!-- AddtopClose -->
      <div class="backbluecircle" id="headerIcon">
          <!-- <svg class="svgFormIconwhite">
      <use xlink:href="assets/sprite.svg#polling_icon"></use>
    </svg> -->
      </div>
      <label id="lblFormTitle" class="FormNameLabel"></label>
  </div>
  <!-- <button class="btn btn-default PreviewtopBtn" type="button" id="btnShare" onclick="closeWindow('')">
  <span>Close</span>

</button> -->
<!-- <div class="pdf-export" id="pdf-export-btn" (click)="exportToPdf()">
  Export
</div> -->
  <a href="javascript:confirmClose()" title="Close" class="backgraysquare PreviewtopBtn"> <svg class="svgFormName ">
<use xlink:href="assets/sprite.svg#closeicon20"></use>
</svg></a>
</div>
<div class="container containerPreview" id="previewID" style="padding-top: 0px;">
  <!-- <label id="pageHeaderViewer" style="font-size: 12px; font-weight: bold;" ></label> -->
  <div class="export-btn-parent">
    <button matripple class="rbcButtons btnFilledBlue" (click)="exportToPdf()">
    <span id="export-btn-text"*ngIf="!btnLoader;else showDots">{{((CurrentLangStrings?.default?.ed?.export) ?(CurrentLangStrings?.default?.ed?.export) :('Export'))}}</span>
    <ng-template #showDots>
          <div class="dots-container">
              <div class="dot"></div>
              <div class="dot"></div>
              <div class="dot"></div>
          </div>
      </ng-template>
  </button>
    </div>
  <surveypreview [json]="json" (submitSurvey)="sendData($event)" (rbcFormObject)="onObjectReceived($event)"></surveypreview>

</div>
