/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./survey.analytics.tabulator";
import * as i2 from "@angular/common/http";
var styles_SurveyAnalyticsTabulatorComponent = [];
var RenderType_SurveyAnalyticsTabulatorComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SurveyAnalyticsTabulatorComponent, data: {} });
export { RenderType_SurveyAnalyticsTabulatorComponent as RenderType_SurveyAnalyticsTabulatorComponent };
export function View_SurveyAnalyticsTabulatorComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["id", "surveyAnalyticsTabulatorContainer"]], null, null, null, null, null))], null, null); }
export function View_SurveyAnalyticsTabulatorComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "survey-analytics-tabulator", [], null, null, null, View_SurveyAnalyticsTabulatorComponent_0, RenderType_SurveyAnalyticsTabulatorComponent)), i0.ɵdid(1, 114688, null, 0, i1.SurveyAnalyticsTabulatorComponent, [i2.HttpClient], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SurveyAnalyticsTabulatorComponentNgFactory = i0.ɵccf("survey-analytics-tabulator", i1.SurveyAnalyticsTabulatorComponent, View_SurveyAnalyticsTabulatorComponent_Host_0, { json: "json" }, { surveySaved: "surveySaved" }, []);
export { SurveyAnalyticsTabulatorComponentNgFactory as SurveyAnalyticsTabulatorComponentNgFactory };
