import * as tslib_1 from "tslib";
import * as html2pdf from "html2pdf.js";
var AnalyticsPage = /** @class */ (function () {
    function AnalyticsPage() {
    }
    AnalyticsPage.prototype.exportToPdf = function () {
        var _this = this;
        // var element = document.querySelectorAll('#analytics-container .sa-visualizer__content');
        var element = document.querySelector(".sa-visualizer__content");
        var opt = {
            margin: 0,
            filename: "-" +
                new Date().toLocaleDateString() +
                "@" +
                new Date().toLocaleTimeString() +
                ".pdf",
            // image: { type: 'jpeg', quality: 1 },
            // html2canvas: { scale: 2, useCORS: true },
            // jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' }
            image: { type: "jpeg", quality: 0.98 },
            html2canvas: { scale: 2, logging: true, dpi: 300, letterRendering: true },
            pagebreak: { mode: ["avoid-all", "css", "auto"] },
            jsPDF: { unit: "in", format: "letter", orientation: "landscape" },
        };
        setTimeout(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, html2pdf().from(element).set(opt).save()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); });
    };
    AnalyticsPage.prototype.onObjectReceived = function (event) {
        this.apiResponse = event;
    };
    return AnalyticsPage;
}());
export { AnalyticsPage };
