import { Component } from "@angular/core";
import * as html2pdf from "html2pdf.js";


@Component({
  selector: "analytics-page",
  templateUrl: "./analytics.page.html",
})
export class AnalyticsPage {
  apiResponse;
  counter;

  exportToPdf() {
    // var element = document.querySelectorAll('#analytics-container .sa-visualizer__content');
    const element = document.querySelector(".sa-visualizer__content");
    const opt = {
      margin: 0,
      filename:
        "-" +
        new Date().toLocaleDateString() +
        "@" +
        new Date().toLocaleTimeString() +
        ".pdf",
      // image: { type: 'jpeg', quality: 1 },
      // html2canvas: { scale: 2, useCORS: true },
      // jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' }
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2, logging: true, dpi: 300, letterRendering: true },
      pagebreak: { mode: ["avoid-all", "css", "auto"] },
      jsPDF: { unit: "in", format: "letter", orientation: "landscape" },
    };
    setTimeout(async () => {
      await html2pdf().from(element).set(opt).save();
    });
  }

  onObjectReceived(event) {
    this.apiResponse = event;
  }
}
