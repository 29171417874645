<div class="btn-container-top-header width-100 header-bg-color">
  <div class="dropup  PreviewtopDiv btn-top-header header-bg-color" >&nbsp;
  <div class="FormName FormNamePosition">
    <div class="backbluecircle" id="headerIcon">

    </div>
    <label id="lblFormTitle" class="FormNameLabel"></label>
  </div>
  <div class="divRightButtons" id="creatorbtnDiv">
    <a href="javascript:confirmClose()" title="Close" id="lnkClose" class="backgraysquare"> <svg class="svgFormName ">
    <use xlink:href="assets/sprite.svg#closeicon20"></use>
  </svg></a>
</div>
 </div>
 <div class="content-container">
  <div  class="container-first-layer" >
    <div class="containeranalytics container-sec-layer" >
      <survey-analytics (apiRespose)="onObjectReceived($event)" ></survey-analytics>
    </div>
    </div>
 </div>

</div>
