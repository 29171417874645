import { Component } from "@angular/core";

import json from "../../assets/amigo.json";
import * as html2pdf from 'html2pdf.js';

@Component({
  selector: "viewer-page",
  templateUrl: "./viewer.page.html",
})
export class SurveyViewerPage {
  title = "SurveyJS+Angular Demo Application";

  json;
  pageHeader: "Form attempt";
  constructor() {
    this.json = json;
  }

  sendData(result) {
    //TODO update with your own behavior
    console.log(result);

  }
}
