import { LocationStrategy, PathLocationStrategy } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app.routing.module";
import { SurveyAnalyticsComponent } from "./components/survey.analytics.component";
import { SurveyAnalyticsDatatablesComponent } from "./components/survey.analytics.datatables";
import { SurveyAnalyticsTabulatorComponent } from "./components/survey.analytics.tabulator";
import { SurveyComponent } from "./components/survey.component";
import { SurveyCreatorComponent } from "./components/survey.creator.component";
import { SurveyassessmentComponent } from "./components/surveyassessment.component";
import { SurveyPreviewComponent } from "./components/surveypreview.component";
import { AnalyticsDatatablesPage } from "./pages/analytics.datatables.page";
import { AnalyticsPage } from "./pages/analytics.page";
import { AnalyticsTabulatorPage } from "./pages/analytics.tabulator.page";
import { SurveyassessmentPage } from "./pages/assessment.page";
import { CreatorPage } from "./pages/creator.page";
import { HomePage } from "./pages/home.page";
import { PdfExportPage } from "./pages/pdfexport.page";
import { SurveyPreviewPage } from "./pages/preview.page";
import { SurveyViewerPage } from "./pages/viewer.page";
import { PopupModalComponent } from './components/popup-modal/popup-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import {MatTabsModule} from '@angular/material/tabs'
@NgModule({
  declarations: [
    AppComponent,
    HomePage,
    SurveyComponent,
    SurveyViewerPage,
    SurveyPreviewPage,
    SurveyassessmentPage,
    SurveyCreatorComponent,
    CreatorPage,
    SurveyAnalyticsComponent,
    SurveyAnalyticsDatatablesComponent,
    SurveyAnalyticsTabulatorComponent,
    AnalyticsPage,
    PdfExportPage,
    AnalyticsTabulatorPage,
    AnalyticsDatatablesPage,
    SurveyPreviewComponent,
    SurveyassessmentComponent,
    PopupModalComponent
  ],
  imports: [BrowserModule, FormsModule, HttpClientModule, AppRoutingModule, MatButtonModule, BrowserAnimationsModule, MatMenuModule,MatDialogModule,MatTabsModule],
  entryComponents: [PopupModalComponent],
  providers: [{ provide: LocationStrategy, useClass: PathLocationStrategy },],
  bootstrap: [AppComponent],

})
export class AppModule { }
