/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../components/survey.analytics.datatables.ngfactory";
import * as i2 from "../components/survey.analytics.datatables";
import * as i3 from "./analytics.datatables.page";
var styles_AnalyticsDatatablesPage = [];
var RenderType_AnalyticsDatatablesPage = i0.ɵcrt({ encapsulation: 2, styles: styles_AnalyticsDatatablesPage, data: {} });
export { RenderType_AnalyticsDatatablesPage as RenderType_AnalyticsDatatablesPage };
export function View_AnalyticsDatatablesPage_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Results Table - display survey results in the table form"])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Uses Datatables. Browsers compatibility: IE10+ "])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "survey-analytics-datatables", [], null, null, null, i1.View_SurveyAnalyticsDatatablesComponent_0, i1.RenderType_SurveyAnalyticsDatatablesComponent)), i0.ɵdid(6, 114688, null, 0, i2.SurveyAnalyticsDatatablesComponent, [], null, null)], function (_ck, _v) { _ck(_v, 6, 0); }, null); }
export function View_AnalyticsDatatablesPage_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "analytics-datatables-page", [], null, null, null, View_AnalyticsDatatablesPage_0, RenderType_AnalyticsDatatablesPage)), i0.ɵdid(1, 49152, null, 0, i3.AnalyticsDatatablesPage, [], null, null)], null, null); }
var AnalyticsDatatablesPageNgFactory = i0.ɵccf("analytics-datatables-page", i3.AnalyticsDatatablesPage, View_AnalyticsDatatablesPage_Host_0, {}, {}, []);
export { AnalyticsDatatablesPageNgFactory as AnalyticsDatatablesPageNgFactory };
