
  <div class="export-btn-parent">
  <button matripple class="rbcButtons btnFilledBlue" (click)="exportToPdf()">
  <span id="export-btn-text"*ngIf="!btnLoader;else showDots">Export</span>
  <ng-template #showDots>
        <div class="dots-container">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
        </div>
    </ng-template>
</button>
  </div>
  <div class="survey-container contentcontainer codecontainer">
  <div class="scoreParentothers assessment-marks">
  <div id="total-marks"></div>
  <div id="obtained-marks"></div>
  </div>
    <div id="surveyElement"></div>
    <div id="surveyResult"></div>
    <br />
    <div id="surveymarks"></div>
  </div>