/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./pdfexport.page";
var styles_PdfExportPage = [];
var RenderType_PdfExportPage = i0.ɵcrt({ encapsulation: 2, styles: styles_PdfExportPage, data: {} });
export { RenderType_PdfExportPage as RenderType_PdfExportPage };
export function View_PdfExportPage_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "div", [["class", "jumbotron"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Export survey to a PDF document"])), (_l()(), i0.ɵeld(4, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" The SurveyJS PDF Export library allows you to render SurveyJS Library surveys to PDF in a browser which can be later emailed or printed. "])), (_l()(), i0.ɵeld(6, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Click the button below to get a PDF document."])), (_l()(), i0.ɵeld(8, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.savePDF() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Save PDF"]))], null, null); }
export function View_PdfExportPage_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "pdfexport-page", [], null, null, null, View_PdfExportPage_0, RenderType_PdfExportPage)), i0.ɵdid(1, 49152, null, 0, i1.PdfExportPage, [], null, null)], null, null); }
var PdfExportPageNgFactory = i0.ɵccf("pdfexport-page", i1.PdfExportPage, View_PdfExportPage_Host_0, {}, {}, []);
export { PdfExportPageNgFactory as PdfExportPageNgFactory };
