/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../components/surveypreview.component.ngfactory";
import * as i3 from "../components/surveypreview.component";
import * as i4 from "@angular/router";
import * as i5 from "@angular/common/http";
import * as i6 from "./preview.page";
var styles_SurveyPreviewPage = [];
var RenderType_SurveyPreviewPage = i0.ɵcrt({ encapsulation: 2, styles: styles_SurveyPreviewPage, data: {} });
export { RenderType_SurveyPreviewPage as RenderType_SurveyPreviewPage };
function View_SurveyPreviewPage_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["id", "export-btn-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.CurrentLangStrings == null) ? null : ((_co.CurrentLangStrings.default == null) ? null : ((_co.CurrentLangStrings.default.ed == null) ? null : _co.CurrentLangStrings.default.ed.export))) ? ((_co.CurrentLangStrings == null) ? null : ((_co.CurrentLangStrings.default == null) ? null : ((_co.CurrentLangStrings.default.ed == null) ? null : _co.CurrentLangStrings.default.ed.export))) : "Export"); _ck(_v, 1, 0, currVal_0); }); }
function View_SurveyPreviewPage_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "dots-container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "div", [["class", "dot"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "div", [["class", "dot"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "div", [["class", "dot"]], null, null, null, null, null))], null, null); }
export function View_SurveyPreviewPage_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "dropup  PreviewtopDiv width-100 header-bg-color"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "FormName FormNamePosition"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "div", [["class", "backbluecircle"], ["id", "headerIcon"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "label", [["class", "FormNameLabel"], ["id", "lblFormTitle"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 2, "a", [["class", "backgraysquare PreviewtopBtn"], ["href", "javascript:confirmClose()"], ["title", "Close"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, ":svg:svg", [["class", "svgFormName "]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 0, ":svg:use", [[":xlink:href", "assets/sprite.svg#closeicon20"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 7, "div", [["class", "container containerPreview"], ["id", "previewID"], ["style", "padding-top: 0px;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 4, "div", [["class", "export-btn-parent"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 3, "button", [["class", "rbcButtons btnFilledBlue"], ["matripple", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.exportToPdf() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SurveyPreviewPage_1)), i0.ɵdid(11, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵand(0, [["showDots", 2]], null, 0, null, View_SurveyPreviewPage_2)), (_l()(), i0.ɵeld(13, 0, null, null, 1, "surveypreview", [], null, [[null, "submitSurvey"], [null, "rbcFormObject"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submitSurvey" === en)) {
        var pd_0 = (_co.sendData($event) !== false);
        ad = (pd_0 && ad);
    } if (("rbcFormObject" === en)) {
        var pd_1 = (_co.onObjectReceived($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_SurveyPreviewComponent_0, i2.RenderType_SurveyPreviewComponent)), i0.ɵdid(14, 114688, null, 0, i3.SurveyPreviewComponent, [i4.Router, i5.HttpClient], { json: [0, "json"] }, { submitSurvey: "submitSurvey", rbcFormObject: "rbcFormObject" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.btnLoader; var currVal_1 = i0.ɵnov(_v, 12); _ck(_v, 11, 0, currVal_0, currVal_1); var currVal_2 = _co.json; _ck(_v, 14, 0, currVal_2); }, null); }
export function View_SurveyPreviewPage_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "preview-page", [], null, null, null, View_SurveyPreviewPage_0, RenderType_SurveyPreviewPage)), i0.ɵdid(1, 49152, null, 0, i6.SurveyPreviewPage, [], null, null)], null, null); }
var SurveyPreviewPageNgFactory = i0.ɵccf("preview-page", i6.SurveyPreviewPage, View_SurveyPreviewPage_Host_0, {}, {}, []);
export { SurveyPreviewPageNgFactory as SurveyPreviewPageNgFactory };
