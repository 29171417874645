/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../../surveyviewer.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./survey.component";
import * as i4 from "@angular/router";
import * as i5 from "@angular/common/http";
var styles_SurveyComponent = [i0.styles];
var RenderType_SurveyComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SurveyComponent, data: {} });
export { RenderType_SurveyComponent as RenderType_SurveyComponent };
function View_SurveyComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["id", "export-btn-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Export"]))], null, null); }
function View_SurveyComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "dots-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "dot"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "dot"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "dot"]], null, null, null, null, null))], null, null); }
export function View_SurveyComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "export-btn-parent"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "button", [["class", "rbcButtons btnFilledBlue"], ["matripple", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.exportToPdf() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SurveyComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["showDots", 2]], null, 0, null, View_SurveyComponent_2)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "scoreParentothers viewer-score-parent"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [["class", "viewer-page-export"], ["id", "pdf-export-btn-viewer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "div", [["class", "scoreHeader viewer-score"], ["id", "totalScore"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "survey-container contentcontainer codecontainer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "div", [["id", "surveyElement"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "div", [["id", "surveyResult"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.btnLoader; var currVal_1 = i1.ɵnov(_v, 4); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
export function View_SurveyComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "survey", [["id", "student-attempt-container"]], null, null, null, View_SurveyComponent_0, RenderType_SurveyComponent)), i1.ɵdid(1, 114688, null, 0, i3.SurveyComponent, [i4.Router, i5.HttpClient], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SurveyComponentNgFactory = i1.ɵccf("survey", i3.SurveyComponent, View_SurveyComponent_Host_0, { json: "json" }, { submitSurvey: "submitSurvey" }, []);
export { SurveyComponentNgFactory as SurveyComponentNgFactory };
