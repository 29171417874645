import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { HomePage } from "./pages/home.page";
import { SurveyViewerPage } from "./pages/viewer.page";
import { SurveyPreviewPage } from "./pages/preview.page";
import{ SurveyassessmentPage } from "./pages/assessment.page";

import { CreatorPage } from "./pages/creator.page";
import { AnalyticsPage } from "./pages/analytics.page";
import { AnalyticsTabulatorPage } from './pages/analytics.tabulator.page';
import { AnalyticsDatatablesPage } from './pages/analytics.datatables.page';
import { PdfExportPage } from "./pages/pdfexport.page";


const routes: Routes = [
  { path: "", component: SurveyViewerPage },
  { path: "viewer", component: SurveyViewerPage },
  { path: "preview", component: SurveyPreviewPage },
  { path: "assessment", component: SurveyassessmentPage },
  { path: "creator", component: CreatorPage },
  { path: "analytics", component: AnalyticsPage },
  { path: "analyticsdatatables", component: AnalyticsDatatablesPage},
  { path: "analyticstabulator", component: AnalyticsTabulatorPage},
  { path: "pdfexport", component: PdfExportPage },
];

@NgModule({
  //imports: [RouterModule.forRoot(routes, { useHash: true })],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
