
<div class="export-btn-parent csv-export-parent" id="export-csv-parent">
  <button matripple class="rbcButtons btnFilledBlue" (click)=" clickDefaultExportBtn();">
  <span id="export-csv-text" *ngIf="!btnLoader;else showDots">{{((CurrentLangStrings?.default?.ed?.exportcsv) ?(CurrentLangStrings?.default?.ed?.exportcsv) :('Export CSV'))}}</span>
  <ng-template #showDots>
        <div class="dots-container">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
        </div>
    </ng-template>
</button>
  </div>
    <!-- <div id="surveyAnalyticsContainer"></div>
    <div id="csvContainer"></div> -->


    <!-- For student view -->
    <!-- <ng-container *ngIf="rbcFormsObj.userrole==userRole?.STUDENT || rbcFormsObj.userrole==userRole?.GUEST; else teacher">
      <div class="scoreParentothers analytics-score-header">
        <div id="totalScore" class="scoreHeaderAnalytics"></div>
      </div>
    <div id="surveyAnalyticsContainer"></div>
  </ng-container> -->
    <!-- For teacher view -->
  <!-- <ng-template #teacher> -->
    <mat-tab-group [selectedIndex]="currentTabIndex" (selectedTabChange)="tabClick($event)">
      <mat-tab [label]="((CurrentLangStrings?.default?.ed?.analytics) ?(CurrentLangStrings?.default?.ed?.analytics) :('Analytics'))">
        <div class="scoreParentothers analytics-score-header">
          <div id="totalScore" class="scoreHeaderAnalytics"></div>
        </div>
        <div id="surveyAnalyticsContainer"></div><div id="analytics-csvContainer"></div></mat-tab>
      <mat-tab [label]="((CurrentLangStrings?.default?.ed?.table) ?(CurrentLangStrings?.default?.ed?.table) :('Table'))">
        <!-- <div class="scoreParentothers analytics-score-header">
          <div id="totalScore" class="scoreHeaderAnalytics"></div>
        </div> -->
         <div id="csvContainer"></div>
         <div id="surveyAnalyticsContainer" class="d-none"></div>
        </mat-tab>
    </mat-tab-group>
  <!-- </ng-template> -->

  <!-- <div id="analytics-student-view">
    <div id="surveyAnalyticsContainer"></div>
    </div>
    <div id="analytics-teacher-view">
    <mat-tab-group (selectedTabChange)="tabClick($event)">
      <mat-tab label="Analytics"> <div id="surveyAnalyticsContainer"></div> </mat-tab>
      <mat-tab label="Table"> <div id="csvContainer"></div></mat-tab>
    </mat-tab-group>
  </div> -->


