/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../components/survey.analytics.component.ngfactory";
import * as i2 from "../components/survey.analytics.component";
import * as i3 from "@angular/common/http";
import * as i4 from "./analytics.page";
var styles_AnalyticsPage = [];
var RenderType_AnalyticsPage = i0.ɵcrt({ encapsulation: 2, styles: styles_AnalyticsPage, data: {} });
export { RenderType_AnalyticsPage as RenderType_AnalyticsPage };
export function View_AnalyticsPage_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 14, "div", [["class", "btn-container-top-header width-100 header-bg-color"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 8, "div", [["class", "dropup  PreviewtopDiv btn-top-header header-bg-color"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00A0 "])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "FormName FormNamePosition"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, "div", [["class", "backbluecircle"], ["id", "headerIcon"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 0, "label", [["class", "FormNameLabel"], ["id", "lblFormTitle"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 3, "div", [["class", "divRightButtons"], ["id", "creatorbtnDiv"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 2, "a", [["class", "backgraysquare"], ["href", "javascript:confirmClose()"], ["id", "lnkClose"], ["title", "Close"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, ":svg:svg", [["class", "svgFormName "]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 0, ":svg:use", [[":xlink:href", "assets/sprite.svg#closeicon20"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 4, "div", [["class", "content-container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 3, "div", [["class", "container-first-layer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 2, "div", [["class", "containeranalytics container-sec-layer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 1, "survey-analytics", [["id", "analytics-container"]], null, [[null, "apiRespose"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("apiRespose" === en)) {
        var pd_0 = (_co.onObjectReceived($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_SurveyAnalyticsComponent_0, i1.RenderType_SurveyAnalyticsComponent)), i0.ɵdid(14, 114688, null, 0, i2.SurveyAnalyticsComponent, [i3.HttpClient], null, null)], function (_ck, _v) { _ck(_v, 14, 0); }, null); }
export function View_AnalyticsPage_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "analytics-page", [], null, null, null, View_AnalyticsPage_0, RenderType_AnalyticsPage)), i0.ɵdid(1, 49152, null, 0, i4.AnalyticsPage, [], null, null)], null, null); }
var AnalyticsPageNgFactory = i0.ɵccf("analytics-page", i4.AnalyticsPage, View_AnalyticsPage_Host_0, {}, {}, []);
export { AnalyticsPageNgFactory as AnalyticsPageNgFactory };
