import { HttpClient, HttpParams } from "@angular/common/http";
import {
  AfterViewInit,
  ChangeDetectorRef, Component,
  EventEmitter, HostListener, NgZone, OnDestroy, OnInit,
  Output
} from "@angular/core";
import { MatDialog } from "@angular/material";
import { Router } from "@angular/router";
import * as SurveyKo from "survey-knockout";
import * as Survey from "../../../src/survey.angular.js";
// import * as SurveyCreator from "../../../src/amigocreator/survey-creator";
import { FormsEventsEnum, FormsStateEnum,DescriptionType, AuthEnum } from "app/enums/app.enums.js";
import * as widgets from "../../../src/surveyjs-widgets.js";
import * as deLang from "../../assets/language/language_de.json";
import * as enLang from "../../assets/language/language_en.json";
import * as frLang from "../../assets/language/language_fr.json";
import * as SurveyCreator from "../surveycreator/survey-creator";
import { init as initCustomWidget } from "./customwidget";
import { PopupModalComponent } from "./popup-modal/popup-modal.component";
import * as html2pdf from 'html2pdf.js';

widgets.icheck(SurveyKo);
widgets.select2(SurveyKo);
widgets.inputmask(SurveyKo);
widgets.jquerybarrating(SurveyKo);
widgets.jqueryuidatepicker(SurveyKo);
widgets.nouislider(SurveyKo);
widgets.select2tagbox(SurveyKo);
//widgets.signaturepad(SurveyKo);
widgets.sortablejs(SurveyKo);
widgets.ckeditor(SurveyKo);
widgets.autocomplete(SurveyKo);
widgets.bootstrapslider(SurveyKo);
//widgets.emotionsratings(SurveyKo);
initCustomWidget(SurveyKo);

declare var APIURL;
declare var configJson;
declare var objforCloseButton;
declare var modal;
declare var $: any;
declare var FormsEvents: any;

declare function ShowLoading();
declare function HideLoading();
declare function displayPopup();
declare function displayQuestion();
declare function closeWindow(obj);
declare function displayPopupDescriptivemodal();
declare function preventInputMarks(id, maxVal);

SurveyCreator.StylesManager.applyTheme("omar");

var Main;
var targetEvent;
var targetBtnId;
var questionEvent;
var CurrentLangStrings;
var buttonMarks;
var jsonDef;
var updateDescriptiveEvent;
var freeTextCopyEvent;
var draftCurrentQuestion;
// = {
//   "title": "",
//   "pages": [
//     {
//       "name": "page1",
//       "elements": [
//         {
//           "type": "checkbox",
//           "name": "Question 1",
//           "title": "Question 1",
//           "isRequired": true,
//           "choices": [
//             {
//               "value": "item1",
//               "text": "option 1"
//             },
//             {
//               "value": "item2",
//               "text": "option 2"
//             },
//             {
//               "value": "item3",
//               "text": "option 3"
//             }
//           ]
//         }
//       ]
//     }
//   ],
//   "showProgressBar": "top"
// };
var jsonDefQuiz = {
  title: "",
  pages: [
    {
      name: "page1",
      elements: [
        {
          type: "checkbox",
          name: "Question 1",
          title: "Question 1",

          choices: [
            {
              value: "item1 ",
              text: "option 1",
            },
            {
              value: "item 2",
              text: "option 2",
            },
            {
              value: "item3",
              text: "option 3",
            },
          ],
        },
      ],
    },
  ],
  showProgressBar: "top",
};
enum FormType {
  POLL = 0,
  QUIZ = 1,
  ASSIGNMENT = 2,
}

@Component({
  selector: "survey-creator",
  template: `
    <div class="scoreParent marks-parent-header">
    <div class="creator-pdf-export">
    </div>
      <div id="totalScore" class="scoreHeader creator-score"></div>
    </div>
    <div id="creator-parent">
      <div id="zero-question-content" class="displaynone">
        <div id="textSvgParent">
          <div class="z-index-999" id="content-icon">
            <svg id="null-icon" class="svgFormIconwhite questionContentIcon">
              <use xlink:href="" id="empty-icon-href"></use>
            </svg>
          </div>
          <div id="nullText" class="z-index-999">
            Please add a question to start
          </div>
          <div
            class="dropup AddQuestionOptions add-question-btn"
            id="QuestionPopUp"
          >
            <button
              id="BtnQuestionPopUp"
              mat-button
              [matMenuTriggerFor]="qustionMenu"
              class="dropdown-toggle question-content-toggle"
            >
              <div class="dropdown-content">
                <svg width="16" height="16" class="addQuestionsvgwhite">
                  <use xlink:href="assets/sprite.svg#add_16_icon" />
                </svg>
                <span id="newQuestion" class="btnPrimary new-question"
                  >Add Question</span
                >
                <span class="caret"></span>
              </div>
            </button>
            <mat-menu #qustionMenu="matMenu" class="addQuestionMenu">
              <button mat-menu-item (click)="AddSCQ()">
                <svg width="16" height="16" class="addQuestionsvg">
                  <use xlink:href="assets/sprite.svg#SingleChoice_16" />
                </svg>
                <span id="singleselect" class="dropdown-text"
                  >Single Choice</span
                >
              </button>
              <button mat-menu-item (click)="AddMCQ()">
                <svg width="16" height="16" class="addQuestionsvg">
                  <use xlink:href="assets/sprite.svg#Multiplechoice_16" />
                </svg>
                <span id="multiselect" class="dropdown-text"
                  >Multiple Choice</span
                >
              </button>
              <button mat-menu-item (click)="AddTXT()">
                <svg width="16" height="16" class="addQuestionsvg">
                  <use xlink:href="assets/sprite.svg#paragraph" />
                </svg>
                <span id="type-description" class="dropdown-text"
                  >Free Text</span
                >
              </button>
              <button mat-menu-item (click)="AddImageQuestion()">
                <svg width="18" height="18" class="addQuestionsvg">
                  <use xlink:href="assets/sprite.svg#image-picker" />
                </svg>
                <span id="image-type" class="dropdown-text">Image</span>
              </button>
            </mat-menu>
          </div>
        </div>
      </div>
      <div id="surveyCreatorContainer"></div>
    </div>
    <style>
      .basebody {
        background-color: var(--amigo-secondary-bg-color) !important;
      }
    </style>
  `,
  styles: [`::ng-deep .addQuestionMenu .mat-menu-content{
    background: var(--menu-dropdown-bg)!important;
  }
  ::ng-deep .addQuestionMenu{
    background: var(--menu-dropdown-bg)!important;
  }
  `],
})

export class SurveyCreatorComponent
  implements OnInit, OnDestroy, AfterViewInit {
  @HostListener("document:keydown.escape", ["$event"]) onKeydownHandler(
    event: KeyboardEvent
  ) {
    modal.close();
  }
  surveyCreator: SurveyCreator.SurveyCreator;
  surveyName: "";
  access_token: "";
  refresh_token: "";
  KeyClaokApiUrl
  objSate;
  surveyTitle;
  editsurveyId: any;
  sectionsReceived: "";
  isStateNew = true;
  myLanguage = "en";
  formType = null;
  isActive: false;
  totalMarks: any;
  objForClose: any;
  isQuestionDropped=false
  isPropertyClosed: boolean = false;
  doAllQuestionhaveMarks: boolean = true;
  materailModalEvent;
  formTitle;
  surveyQuestionEvent;
  targetDroppedQuestion
  descriptionImgJson= {
    pages:[{elements:[]}]
};
formObject;
  // questionMarks;
  noneEvent;
  showContent = false;
  isDraftForm=false;
  questionType: number;
  // CurrentLangStrings:any;

  @Output() surveySaved: EventEmitter<Object> = new EventEmitter();
  @Output() rbcFormObject: EventEmitter<Object> = new EventEmitter();
  @Output() formsTitle: EventEmitter<Object> = new EventEmitter();


  constructor(
    private router: Router,
    private http: HttpClient,
    private zone: NgZone,
    private dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    sessionStorage.setItem("interactionID", "");
    window["getAuthDetails"] = this.getAuthDetails;
    window["AddMCQ"] = this.AddMCQ;
    window["AddSCQ"] = this.AddSCQ;
    window["AddTXT"] = this.AddTXT;
    window["calculateScore"] = this.calculateScore;
    window["AddImageQuestion"] = this.AddImageQuestion;
    window["savepublishandShareMySurvey"] = this.savepublishandShareMySurvey;
    window["previewButton"] = this.previewButton;
    window["saveandpublishMySurvey"] = this.saveandpublishMySurvey;
    window["formType"] = this.formType;
    // window['objForClose'] = this.objForClose;
  }

  ngOnInit() {
    this.KeyClaokApiUrl = `${configJson["authHostUrl"]}protocol/openid-connect/`;
    Main = this;
    var target = document.getElementById("basebody");
    target.style.background = "#d5d8dc";
    //this.questionType= +sessionStorage.getItem("QuestionType");
  }
  ngAfterViewInit() {
    this.eventHandler();
    //this.questionType=
  }

  eventHandler() {
    document.addEventListener(FormsEventsEnum.FORMS_ONEMPTYIMAGEITEM, this.onEmptyImageItem);
    // document.addEventListener(FormsEventsEnum.FORMS_ANWERKEYEVENT, this.anwerKeyEvent);
    document.addEventListener(FormsEventsEnum.FORMS_ONUPDATEFREETEXTMARKS, this.onUpdateFreeTextMarks);
    // document.addEventListener(FormsEventsEnum.FORMS_NONEPRESSED, this.nonePressed);
    document.addEventListener(FormsEventsEnum.FORMS_ONUNSUPPORTEDFILE, this.onUnsupportedFile);
    document.addEventListener(FormsEventsEnum.FORMS_ONFREETEXTCOPY, this.onFreeTextCopy);
    document.addEventListener(FormsEventsEnum.FORMS_ONITEMDELETE, this.onitemdelete);
    document.addEventListener(FormsEventsEnum.FORMS_ONNEWITEMADDED, this.onnewitemadded);
    document.addEventListener(FormsEventsEnum.FORMS_IMAGEITEMIMAGEPLUS, this.itemaddedthrougnhimageplus);
    document.addEventListener(FormsEventsEnum.FORMS_DESCRIPTIONIMAGEDRAGENDED, this.descriptionimagedragended);
    document.addEventListener(FormsEventsEnum.FORMS_ITEMDRAGGED, this.itemdragged);
  }

  removeEvents() {
    document.removeEventListener(FormsEventsEnum.FORMS_ONEMPTYIMAGEITEM, this.onEmptyImageItem);
    // document.removeEventListener(FormsEventsEnum.FORMS_ANWERKEYEVENT, this.anwerKeyEvent);
    document.removeEventListener(FormsEventsEnum.FORMS_ONUPDATEFREETEXTMARKS, this.onUpdateFreeTextMarks);
    // document.removeEventListener(FormsEventsEnum.FORMS_NONEPRESSED, this.nonePressed);
    document.removeEventListener(FormsEventsEnum.FORMS_ONUNSUPPORTEDFILE, this.onUnsupportedFile);
    document.removeEventListener(FormsEventsEnum.FORMS_ONFREETEXTCOPY, this.onFreeTextCopy);
    document.removeEventListener(FormsEventsEnum.FORMS_ONITEMDELETE, this.onitemdelete);
    document.removeEventListener(FormsEventsEnum.FORMS_ONNEWITEMADDED, this.onnewitemadded);
    document.removeEventListener(FormsEventsEnum.FORMS_DESCRIPTIONIMAGEDRAGENDED, this.descriptionimagedragended);
  }
  ngOnDestroy() {
    this.removeEvents();
    // this.questionType=null;
  }

  onEmptyImageItem = (e) => {
    try {
      if (e) {
        var emptyImageOption = {
          state: FormsStateEnum.EMPTYIMAGEITEM
        }
        closeWindow(emptyImageOption);
      }
    } catch (error) {
      console.log(error);
    }
  }
  onitemdelete = (e) => {
    try {
      if (e.detail) {
        this.addImgInDescription(e.detail.question);

      }
    } catch (error) {
      console.log(error);
    }
  }
  onnewitemadded = (e) => {
    try {
      if (e.detail) {
        this.addImgInDescription(e.detail);
      }
    } catch (error) {
      console.log(error);
    }
  }
  itemdragged = (e) => {
    try {
      if (e.detail) {
        this.addImgInDescription(e.detail);
      }
    } catch (error) {
      console.log(error);
    }
  }
  itemaddedthrougnhimageplus = (e) => {
    try {
      if (e.detail) {
        this.addImgInDescription(e.detail);

      }
    } catch (error) {
      console.log(error);
    }
  }

 descriptionimagedragended = (e) => {
    try {
      if (e.detail) {
        this.addImgInDescription(this.targetDroppedQuestion);
        console.log("e.detail:::",e.detail)
        this.isQuestionDropped=e.detail;
      }
    } catch (error) {
      console.log(error);
    }
  }

  // anwerKeyEvent = (e) => {
  //   try {
  //     this.materailModalEvent = e;
  //     console.log("materailModalEvent", this.materailModalEvent);
  //     if (!this.materailModalEvent) {
  //       this.displayErrorModal();
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  onUpdateFreeTextMarks = (e) => {
    try {
      updateDescriptiveEvent = e;
      if (e) {
        const surveyJson = JSON.parse(this.surveyCreator.text);
        var obtainedMarks = 0;
        var updatedMarks = 0;
        const marks = document.getElementById("descriptiveQuestionPoints");
        const commentMarks = marks["value"];
        const clickedBtn = document.getElementById(targetBtnId);
        clickedBtn.textContent = `${CurrentLangStrings.default.ed.setMarks} ( ${commentMarks}${" "}${CurrentLangStrings.default.ed.marks} )`;
        clickedBtn.title = `${CurrentLangStrings.default.ed.setMarks} ( ${commentMarks}${" "}${CurrentLangStrings.default.ed.marks} )`

        if (surveyJson) {
          window["childJson"] = surveyJson.pages[0].elements;
        }
        surveyJson.pages[0].elements.forEach((element) => {
          if (element.type == "comment" && targetEvent == element.name) {
            updatedMarks = parseInt(commentMarks);
            element.marks = updatedMarks;
            questionEvent.marks = updatedMarks;
            element.obtainedMarks = obtainedMarks;
          }
          // this.SaveSurvey(JSON.stringify(surveyJson), true);
          this.calculateScore(surveyJson);
        });
        document.getElementById("descriptiveQuestionPoints")["value"] = 0;
      }
    } catch (error) {
      console.log(error);
    }
  }

  // nonePressed = (e) => {
  //   try {
  //     this.noneEvent = e;
  //     if (e) {
  //       this.addNoneItem();
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  onUnsupportedFile = (e) => {
    try {
      if (e) {
        var unSupportedFiletype = {
          state: FormsStateEnum.UNSUPPORTEDFILE
        }
        closeWindow(unSupportedFiletype);
      }
    } catch (error) {
      console.log(error);
    }
  }

  onFreeTextCopy = (e) => {
    try {
      freeTextCopyEvent = e;
    } catch (error) {
      console.log(error);
    }
  }

  getAuthDetails = (obj) => {
    try {
      this.formObject=obj;
      this.rbcFormObject.emit(obj);
      this.disableButtons(false);
      try {
        this.myLanguage = obj.language;
        sessionStorage.setItem("currLang", obj.language);
      } catch { }
      try {
        if (obj.bubbleid != "" && obj.bubbleid != undefined) {
          document.getElementById("btnShare").style.display = "block";
          if (!obj.id) {
            HideLoading();
          }
        }
        else {
          document.getElementById("btnShare").style.display = "none";
          if (!obj.bubbleid && !obj.id) {
            HideLoading();
          }
          else if (obj.bubbleid && this.isStateNew) {

            HideLoading();
          }
        }
      } catch { }
      try {
        this.isStateNew = obj.state == FormsStateEnum.NEW ? true : false;
        if (obj.state == FormsStateEnum.NEW || obj.state == FormsStateEnum.EDIT) {
          document.getElementById("edit-name-icon").setAttribute("xlink:href", "assets/sprite.svg#rename-edit-icon");
        }
        else {
          document.getElementById('edit-form-icons').classList.remove('cursor-pointer');
        }
      } catch { }
      sessionStorage.setItem("interactionID", obj.id);
      this.access_token = obj.access_token;
      this.refresh_token = obj.refresh_token;
      this.editsurveyId = obj.id;
      this.formType = obj.type;
      this.objSate = obj.state;
      try {
        console.debug("[Forms] :: Selected type Poll - ", this.formType == FormType.POLL);
        if (this.formType === FormType.POLL) {
          document
            .getElementById("empty-icon-href")
            .setAttribute("xlink:href", "assets/sprite.svg#filterPoll_120");
          document
            .getElementById("header-icon-href")
            .setAttribute("xlink:href", "assets/sprite.svg#polling_icon");
          document.getElementById("totalScore").classList.add("displaynone");
        } else if (this.formType === FormType.QUIZ) {
          document
            .getElementById("empty-icon-href")
            .setAttribute("xlink:href", "assets/sprite.svg#filterQuiz_120");
          document
            .getElementById("header-icon-href")
            .setAttribute("xlink:href", "assets/sprite.svg#quiz_icon");
        }
      } catch { }
      try {
        sessionStorage.setItem("QuestionType", this.formType);
        // if (this.formType == FormType.QUIZ) {
        document.getElementById("Qanswerkey").style.display = "block";
        // }
        // else {
        //   console.log(document.getElementById("Qanswerkey"));
        //   document.getElementById("Qanswerkey").style.display = "none";
        //   console.log("NO answerkey");
        // }
      } catch { }

      try {
        this.surveyTitle = obj.title;
        this.sectionsReceived = obj.bubbleid;
        document.getElementById("lblFormTitle").innerHTML = this.surveyTitle;
        window['formTitle']=this.surveyTitle;
        this.formsTitle.emit(window['formTitle']);
      } catch { }
      try {
        this.objForClose = {
          interactionid: obj.id,
          state: FormsStateEnum.CREATORCLOSED,
          type: obj.type,
          bubbleid: obj.bubbleid,
        };
        objforCloseButton = this.objForClose;
      } catch { }
      this.OnLoadCreator(obj.access_token, obj.id);
    } catch (e) { }
  };
  getData = (access_token, editsurveyId) => {
    try {
     // const headers = { AuthToken: access_token };
      const headers =  {
        Authorization: 'Bearer ' + this.access_token,
        client_id: AuthEnum.CLIENTID,
        client_secret: AuthEnum.CLIENTSECRET
       };
      var self = this;
      this.http.get<any>(APIURL + "interaction/GetById?Id=" + editsurveyId, {headers,})        //in the case of draft,template enable and disbale buttons
        .subscribe((data) => {
          this.formTitle = data.interactions[0].title;
          const surveyCreatorJson = JSON.parse(data.interactions[0].data);
          // self.descriptionImgJson=JSON.parse(data.interactions[0].data);
          // self.descriptionImgJson.pages[0].elements.forEach(element => {
          //   if(!element.description || !element.description.includes('data:image')){
          //     let deletedElement = self.descriptionImgJson['pages'][0].elements.indexOf(element);
          //     self.descriptionImgJson['pages'][0].elements.splice(deletedElement, 1);
          //   }
          // });
          if (data.interactions[0].bubbleid != "" && this.objSate === 'edit') {
            document.getElementById("btnShare").classList.add("d-block");
          }
          // this.enableAndDisableBtns(surveyCreatorJson.pages[0].elements);
          if (surveyCreatorJson.pages[0].elements.length === 0) {
            const zerQuestionContent = document.getElementById(
              "zero-question-content"
            );
            zerQuestionContent.className = "d-block";
          }
          if (data.responseCode == 200) {
            if (!this.isStateNew) {
              this.surveyTitle = data.interactions[0].title;
              document.getElementById("lblFormTitle").innerHTML =
                this.surveyTitle;
                window['formTitle']=this.surveyTitle;
            }
            HideLoading();
            try {
              //
              // const imgDescription=[];
              // console.log("data.interactions[0].data",JSON.parse(data.interactions[0].data));
              // var questionJson=JSON.parse(data.interactions[0].data);
              // questionJson.pages[0].elements.forEach(element => {
              //   if(element.description && element.description.includes('data:image')){
              //     imgDescription.push(element.description);
              //   }
              // });
              // if(imgDescription.length>0){
              //   const imgDescriptionJson=data.interactions[0].data;
              //   questionJson.pages[0].elements.forEach(element => {
              //     if(element.description && element.description.includes('data:image')){
              //     element.description='';
              //     }
              //   });
              //   self.surveyCreator.text = JSON.stringify(questionJson);
              // }

              // else{
              self.surveyCreator.text = data.interactions[0].data;
              // }
              // setTimeout(() => {
              //   self.surveyCreator.survey.activePage.elementsValue.forEach((element,i) => {
              //     if(!element.rows){
              //       this.addImgInDescription(element,true,self.descriptionImgJson)
              //     }
              //   });
              // }, 100);
              if(surveyCreatorJson.pages[0].elements.length && self.surveyCreator.survey.activePage.elementsValue.length){
              surveyCreatorJson.pages[0].elements.forEach((element,i) => {
                if(element){
                  this.addImgInDescription(self.surveyCreator.survey.activePage.elementsValue[i],true,surveyCreatorJson);
                }
              });
            }
              self.isActive = data.interactions[0].active;
              if (this.formType === FormType.QUIZ) {
                const surveyQuestions =
                  self.surveyCreator.survey.activePage.elementsValue;
                setTimeout(() => {
                  surveyQuestions.forEach((element) => {
                    // setTimeout(() => {
                    //   const questionDropDown=document.querySelectorAll(`#${element.id} .svda_question_action`)
                    //   if(questionDropDown[3]){
                    //   questionDropDown[3].classList.add("displaynone");
                    //   const questionIconsSeprator=document.querySelectorAll(`#${element.id} .svda_action_separator`);
                    //   questionIconsSeprator[0].classList.add("separator-display-content");
                    //   }
                    // }, 50);

                    if (element.rows) {
                      const questionComment = document.getElementById(
                        element.id
                      );
                      const questionTextarea = document.getElementById(
                        `${element.id}${"i"}`
                      );
                      if (questionTextarea) {
                        questionTextarea["rows"] = 5;
                        element.rows = 5;
                        questionTextarea.style.resize = "none";
                        questionTextarea.classList.add("comment-textarea");
                      }
                      // const editIconAllignment=document.querySelector(`#${element.id} .sv-title-actions__title`);
                      // editIconAllignment.classList.add("comment-edit-icon");
                      const questionMarks = element.marks;
                      var btnParent = document.createElement("div");
                      btnParent.id = `btnParent_${element.id}`;
                      btnParent.classList.add("pointer-event");
                      const btn = document.createElement("div");
                      btn.className = "appended-btn-txt";
                      btn.id = `btn_${element.id}`;
                      // const btnTxt="Set Marks";
                      btn.innerText = `${CurrentLangStrings.default.ed.setMarks
                        }${" ( "}${questionMarks} ${CurrentLangStrings.default.ed.marks} )`;
                      btn.classList.add("appended_btn", "creator-appended-btn", "svda-add-new-item");
                      btn.title = `${CurrentLangStrings.default.ed.setMarks
                        }${" ( "}${questionMarks}${' '}${CurrentLangStrings.default.ed.marks} )`;
                      btnParent.style.display = "flex";
                      btnParent.style.justifyContent = "flex-end";
                      btnParent.style.paddingTop = "2rem";
                      btnParent.style.width = "calc(100% - 14px)";
                      // btnParent.style.pointerEvents="all";
                      btnParent.appendChild(btn);
                      questionComment.appendChild(btnParent);
                      btn.addEventListener("click", (e) => {
                        targetBtnId = e.currentTarget["id"];
                        // targetEvent = e["path"][2].name;
                        targetEvent = e['currentTarget']['offsetParent']['name'];
                        surveyQuestions.forEach((element) => {
                          if (element.id ==e['currentTarget']['offsetParent'].id) {
                            questionEvent = element;
                          }
                        });
                        console.debug("[Forms] :: Question Marks - ", questionEvent.marks);
                        document.getElementById("descriptiveQuestionPoints")[
                          "value"
                        ] = questionEvent.marks;
                        const statement = document.getElementById(
                          "descriptive_statement"
                        );
                        while (statement.firstChild) {
                          statement.firstChild.remove();
                        }
                        const descriptiveStatement =
                          document.createElement("span");
                        statement.appendChild(descriptiveStatement);
                        descriptiveStatement.innerHTML = questionEvent.title;
                        displayPopupDescriptivemodal();
                        preventInputMarks(
                          "#descriptiveQuestionPoints",
                          document.getElementById("descriptiveQuestionPoints")[
                          "max"
                          ]
                        );
                      });
                    }
                  });
                }, 10);
              } else {
                const surveyQuestions =
                  self.surveyCreator.survey.activePage.elementsValue;
                setTimeout(() => {
                  surveyQuestions.forEach((element) => {
                    // setTimeout(() => {
                    //   const questionDropDown=document.querySelectorAll(`#${element.id} .svda_question_action`)
                    //   if(questionDropDown[3]){
                    //   questionDropDown[3].classList.add("displaynone");
                    //   const questionIconsSeprator=document.querySelectorAll(`#${element.id} .svda_action_separator`);
                    //   questionIconsSeprator[0].classList.add("separator-display-content");
                    //   }
                    // }, 50);
                    if (element.rows) {
                      const questionTextarea = document.getElementById(
                        `${element.id}${"i"}`
                      );
                      if (questionTextarea) {
                        questionTextarea.style.resize = "none";
                        questionTextarea.classList.add("comment-textarea");
                        questionTextarea["rows"] = 5;
                        element.rows = 5;
                      }
                    }
                  });
                }, 10);
              }

              this.calculateScore(JSON.parse(self.surveyCreator.text));
            } catch (e) {
              console.log("exception::: ", e);
            }
            self.surveyCreator.saveandpublishSurveyFunc =
              self.saveandpublishMySurvey;
          }
        });
    } catch { }
    // HideLoading();
  };
  isJson(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return {};
    }
    return JSON.parse(str);
  }
  renewSessionAuthorization() {
    var retry = 0;
    const body = new HttpParams()
      .set('grant_type', AuthEnum.GRANTTYPE_REFRESHTOKEN)
      .set('client_id', AuthEnum.CLIENTID)
      .set('client_secret', AuthEnum.CLIENTSECRET)
      .set('refresh_token', this.refresh_token);
    return new Promise((resolve, reject) => {
      try {
        var request = new XMLHttpRequest();
        request.onreadystatechange = () => {
          if (request.readyState === 4) {
            var response = this.isJson(request.response);
            if (request.status === 200 && response.access_token) {
              this.access_token = response.access_token;
            this.refresh_token = response.refresh_token;
              resolve(response);
            } else {
              reject(response);
            }
          }
        };

        request.open('POST', this.KeyClaokApiUrl + 'token');
        request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        request.onload = () => { };
        request.send(body.toString());
      } catch (error) { }
    });
  }
  AddMCQ() {
    Main.surveyCreator.survey.pages[0].addNewQuestion("checkbox");
    window["previousImgSrc"] ='';
  }
  AddSCQ() {
    Main.surveyCreator.survey.pages[0].addNewQuestion("radiogroup");
    window["previousImgSrc"] ='';
  }
  AddTXT() {
    freeTextCopyEvent = false;
    const descriptionObj =
      Main.surveyCreator.survey.pages[0].addNewQuestion("comment");
      window["previousImgSrc"] ='';
    setTimeout(() => {
      const newDescriptionElement = document.getElementById(
        descriptionObj["id"]
      );
      const questionTextarea = document.getElementById(
        `${descriptionObj["id"]}${"i"}`
      );
      if (questionTextarea) {
        questionTextarea.style.resize = "none";
        questionTextarea.classList.add("comment-textarea");
        questionTextarea["rows"] = 5;
        descriptionObj.rows = 5;
      }
      if (+sessionStorage.getItem("QuestionType") === 1) {
        var btnParent = document.createElement("div");
        btnParent.id = `btnParent_${descriptionObj["id"]}`;
        const btn = document.createElement("div");
        const btnTxt = CurrentLangStrings.default.ed.setMarks;
        btn.innerText = `${btnTxt}`;
        btn.id = `btn_${newDescriptionElement.id}`;
        const defaultMarksSpan = document.createElement("span");
        defaultMarksSpan.innerText = ` ( 0 ${CurrentLangStrings.default.ed.marks} )`
        btn.appendChild(defaultMarksSpan);
        btn.classList.add("appended_btn", "creator-appended-btn", "svda-add-new-item");
        btn.title = `${CurrentLangStrings.default.ed.setMarks} ( 0 ${CurrentLangStrings.default.ed.marks} )`;
        btnParent.style.display = "flex";
        btnParent.style.justifyContent = "flex-end";
        btnParent.style.paddingTop = "2rem";
        btnParent.classList.add("creator-btn-parent");
        btnParent.style.width = "calc(100% - 14px)";
        btnParent.style.pointerEvents = "all";
        btnParent.appendChild(btn);
        newDescriptionElement.appendChild(btnParent);
        btn.addEventListener("click", (e) => {
          targetEvent = e.target["offsetParent"].name;
          targetBtnId = e.currentTarget["id"];
          questionEvent = descriptionObj;
          document.getElementById("descriptiveQuestionPoints")["value"] =
            descriptionObj.marks;
          const statement = document.getElementById("descriptive_statement");
          while (statement.firstChild) {
            statement.firstChild.remove();
          }
          const descriptiveStatement = document.createElement("span");
          statement.appendChild(descriptiveStatement);
          descriptiveStatement.innerHTML = questionEvent.title;
          displayPopupDescriptivemodal();
          preventInputMarks(
            "#descriptiveQuestionPoints",
            document.getElementById("descriptiveQuestionPoints")["max"]
          );
        });
      }
    }, 10);
  }
  AddImageQuestion() {
    Main.surveyCreator.survey.pages[0].addNewQuestion("imagepicker");
    window["previousImgSrc"] ='';
  }

  savepublishandShareMySurvey =async () => {
    var surveyJson=JSON.parse(this.surveyCreator.text);
    this.surveyQuestionEvent=this.surveyCreator.survey.getAllQuestions();
    if (
      JSON.parse(this.surveyCreator.text).pages[0].elements != null &&
      JSON.parse(this.surveyCreator.text).pages[0].elements.length > 0
    ) {
      const imageQuestion = document.querySelectorAll('.image-picker_question');
      if (imageQuestion.length > 0) {
        for (let i = 0; i < imageQuestion.length; i++) {
          const imageQuestionId = imageQuestion[i].id
          const imagePickerItems = document.querySelectorAll(`#${imageQuestionId} .sv_q_imgsel_image`)
          if (imagePickerItems.length == 0) {
            FormsEvents.sendEvent(FormsEventsEnum.FORMS_ONEMPTYIMAGEITEM, true);
            return;
          }
        }
      }
      var isImgInDescription=false;
      const imgPreview= document.getElementsByClassName('desc-preview-img');
      if(imgPreview.length>0){
       if(surveyJson.pages[0].elements.length !==this.descriptionImgJson.pages[0].elements.length){
       isImgInDescription=true;
      //  this.updateQuestionJsonForDesc(surveyJson);
       }
       else{
         surveyJson=this.descriptionImgJson;
       }
      }
        this.disableButtons(true);
        // ShowLoading();
        this.editsurveyId = sessionStorage.getItem("interactionID").toString();
        if (this.isStateNew){
          //un commented by Fahad
          // this.updateQuestionJsonForDesc(surveyJson)
          this.SaveShareSurvey(surveyJson, true);
        }
        else{
          this.isDraftForm=true;
          // this.updateQuestionJsonForDesc(surveyJson);
        await this.UpdateSurvey(JSON.stringify(surveyJson),true,false,this.isDraftForm);
        this.ShareSurveyOnly(JSON.stringify(surveyJson), true); //because update is same for all cases
      }
    }
  };

  SaveShareSurvey = (jsonSurvey, isPublish) => {
    try {
      const surveyData= this.addNewPropertyForDescriptionInJson(JSON.stringify(jsonSurvey));
      if (window['formTitle']) {
        this.surveyTitle = window['formTitle'];
      }
      this.calculateScore(JSON.parse(surveyData));
      console.debug("share");
      var savesharebody = JSON.stringify({
        title: this.surveyTitle,
        data: surveyData.toString(),
        questionscount: JSON.parse(this.surveyCreator.text).pages[0].elements
          .length,
        active: isPublish,
        type: this.formType, // FormType.POLL,  //0
        bubbleid: this.sectionsReceived,
        showresults: true,
        totalmarks: this.totalMarks,
      });
      var xhr = new XMLHttpRequest();
      xhr.open("POST", APIURL + "interaction/saveandstartsession");
      // xhr.open('GET', document.referrer  + 'authenticate/ValidateSessionAuthToken' );
      xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
      xhr.setRequestHeader('Authorization', 'Bearer ' + this.access_token);
      xhr.setRequestHeader('client_id', AuthEnum.CLIENTID);
      xhr.setRequestHeader('client_secret', AuthEnum.CLIENTSECRET);
      xhr.onload = () => {
        var result = xhr.response ? JSON.parse(xhr.response) : null;
        if (result && result.responseCode && result.responseCode == 200) {
          sessionStorage.setItem("interactionID", "");
          var objtoSend = {
            interactionid: result.interactionid,
            state: FormsStateEnum.SHARED,
            type: this.formType,
            title: this.surveyTitle,
            bubbleid: this.sectionsReceived,
          };
          // HideLoading();
          closeWindow(objtoSend); //close creator on success
          console.debug("share res::::::", result);
        }
        else if(xhr.status == 401){
          this.renewSessionAuthorization().then(() => {
            xhr.open("POST", APIURL + "interaction/saveandstartsession");
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.setRequestHeader('Authorization', 'Bearer ' + this.access_token);
            xhr.setRequestHeader('client_id', AuthEnum.CLIENTID);
            xhr.setRequestHeader('client_secret', AuthEnum.CLIENTSECRET);
            xhr.onload = () => {
              var result = xhr.response ? JSON.parse(xhr.response) : null;
              if (result.responseCode == 200) {
                sessionStorage.setItem("interactionID", "");
                var objtoSend = {
                  interactionid: result.interactionid,
                  state: FormsStateEnum.SHARED,
                  type: this.formType,
                  title: this.surveyTitle,
                  bubbleid: this.sectionsReceived,
                };
                // HideLoading();
                closeWindow(objtoSend); //close creator on success
                console.debug("share res::::::", result);
              }};
            xhr.send(savesharebody);
            })
            .catch(() => {
            });
        }
      };
      // const parsedCorrect = JSON.parse(savesharebody);
      // // console.log("parsed-Correct-item",parsedCorrect.data);
      // const dataToSend = JSON.parse(parsedCorrect.data);
      xhr.send(savesharebody);
    } catch (e) {
      console.debug("add error::: ", e);
    }
    // HideLoading();
  };
  ShareSurveyOnly = (jsonSurvey, isPublish) => {
    //Fahad
    try {
      var xhr = new XMLHttpRequest();
      var self = this;
      xhr.open(
        "POST",
        APIURL + "InteractionSession/Start?Id=" + self.editsurveyId
      );
      xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
      xhr.setRequestHeader('Authorization', 'Bearer ' + self.access_token);
      xhr.setRequestHeader('client_id', AuthEnum.CLIENTID);
      xhr.setRequestHeader('client_secret', AuthEnum.CLIENTSECRET);

      xhr.onload = function () {
        var result = xhr.response ? JSON.parse(xhr.response) : null;
        if (xhr.status === 200) {
          if (result.responseCode == 200) {
            sessionStorage.setItem("interactionID", "");
            var objtoSend = {
              interactionid: self.editsurveyId,
              state: FormsStateEnum.SHARED,
              title: self.surveyTitle,
              type: self.formType,
              bubbleid: self.sectionsReceived,
            };
            closeWindow(objtoSend); //close creator on success
            console.debug("[Form share after save/update]", result);
          }
        }
        else if(xhr.status == 401){
          self.renewSessionAuthorization().then(() => {
            xhr.open(
              "POST",
              APIURL + "InteractionSession/Start?Id=" + self.editsurveyId
            );
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.setRequestHeader('Authorization', 'Bearer ' + self.access_token);
            xhr.setRequestHeader('client_id', AuthEnum.CLIENTID);
            xhr.setRequestHeader('client_secret', AuthEnum.CLIENTSECRET);
            xhr.onload = () => {
              var result = xhr.response ? JSON.parse(xhr.response) : null;
              if (result.responseCode == 200) {
                sessionStorage.setItem("interactionID", "");
                var objtoSend = {
                  interactionid: self.editsurveyId,
                  state: FormsStateEnum.SHARED,
                  title: self.surveyTitle,
                  type: self.formType,
                  bubbleid: self.sectionsReceived,
                };
                closeWindow(objtoSend);
                console.debug("[Form share after save/update]", result);
              }};
            xhr.send();
            })
            .catch(() => {
            });
        }
      };
      xhr.send();
    } catch (e) {
      console.debug("add error::: ", e);
    }
    // HideLoading();
  };
  displayErrorModal() {
    const dialogRef = this.dialog.open(PopupModalComponent, {
      data: {
        title: CurrentLangStrings.default.ed.notallowed,
        content: CurrentLangStrings.default.pe.addOneQuestion,
        type: "red",
      },
    });
    dialogRef.afterClosed().subscribe((confirmed) => { });
  }
  saveandpublishMySurvey = () => {
    var surveyJson = JSON.parse(this.surveyCreator.text);
    //q.choices.forEach(element => {
      this.surveyQuestionEvent=this.surveyCreator.survey.getAllQuestions();
    var questions = this.surveyCreator.survey.getAllQuestions();
    if (questions.length == 0) {
      this.displayErrorModal();
      return
    }
    try {
      // this.disableButtons(true);
    } catch (error) { }
    // console.log(this.surveyCreator.text);
    // this.disableButtons(true);
    // questions.forEach((element)=>{
    //   document.querySelectorAll()
    // })
    var isImgInDescription=false;
   const imgPreview= document.getElementsByClassName('desc-preview-img');
   if(imgPreview.length>0){
    if(surveyJson.pages[0].elements.length !==this.descriptionImgJson.pages[0].elements.length){
    isImgInDescription=true;
    // this.updateQuestionJsonForDesc(surveyJson);
    }
    else{
      surveyJson=this.descriptionImgJson;
    }
   }

    if (
      JSON.parse(this.surveyCreator.text).pages[0].elements != null &&
      JSON.parse(this.surveyCreator.text).pages[0].elements.length > 0
    ) {
      // ShowLoading();
      this.editsurveyId = sessionStorage.getItem("interactionID").toString();
      if (this.editsurveyId == "") {
        this.SaveSurvey(JSON.stringify(surveyJson), true)
          .then((result) => {
            if (result["responseCode"] === 200) {
              this.onSaveFormPromiseResolve(result);
            }
          })
          .catch((error) => {});
      }
       else {
        if (this.isStateNew == true) {
        this.SaveSurvey(JSON.stringify(surveyJson), true)
          .then((result) => {
            if (result["responseCode"] === 200) {
              this.onSaveFormPromiseResolve(result);
            }
          })
          .catch((error) => {});
        } else {
          this.UpdateSurvey(JSON.stringify(surveyJson), true, true);
        }
      }
    } else {
      console.debug("Create atleast one question to continue");
      // notify("Create atleast one question to continue", "Error");
    }
  };
  calculateScore(json,currentQuestion?,isAlreadyExist?) {
    var total = 0;
    try {
      json.pages[0].elements.forEach((element) => {
        if (
          element.marks != undefined &&
          element.marks != NaN &&
          element.marks > 0
        )
          total = total + element.marks;
        else this.doAllQuestionhaveMarks = false;
      });
      this.totalMarks = total;
      sessionStorage.setItem("totalMarks", this.totalMarks);
      if (+sessionStorage.getItem("QuestionType") == FormType.QUIZ){
        document.getElementById("totalScore").innerHTML =
          "Total Marks: " + total;
          // if(!currentQuestion.rows){
            if(!document.getElementById(`descParentdiv_${currentQuestion.id}`)){
              const descriptionImgParent=document.createElement('div');
              descriptionImgParent.setAttribute('id',`descParentdiv_${currentQuestion.id}`);
              descriptionImgParent.classList.add('description-image')
              const input=document.createElement('input');
              input.type="file";
              const img=document.createElement('img');
              img.classList.add('desc-preview-img');
              img.setAttribute('id',`descImg_${currentQuestion.id}`);
              input.setAttribute('id',`chooseImg_${currentQuestion.id}`);
              input.classList.add('desc-image-input');
              const addImgInDescriptionBtn=document.createElement('div');
              const svgParentDiv=document.createElement('div');
              // addImgInDescriptionBtn.innerHTML=`${CurrentLangStrings.default.qt.clickimagetoadd}`;
              var svgElem = document.createElementNS("http://www.w3.org/2000/svg", "svg");
              svgElem.classList.add('default-svg-size')
              var useElem = document.createElementNS("http://www.w3.org/2000/svg", "use");
              useElem.setAttributeNS(null, "href", "assets/sprite.svg#plus-icon");
              svgElem.appendChild(useElem);
              svgParentDiv.appendChild(svgElem)
              svgParentDiv.classList.add('add-image-svg-div')
              addImgInDescriptionBtn.append(svgParentDiv)
              addImgInDescriptionBtn.classList.add('add-description-image-btn','svda-add-new-item');
              addImgInDescriptionBtn.setAttribute('id',`descImgBtn_${currentQuestion.id}`);
              if(isAlreadyExist){
                descriptionImgParent.appendChild(input);
                descriptionImgParent.appendChild(addImgInDescriptionBtn);
              }
              if (window["previousImgSrc"] && window["previousImgSrc"] !== "" && isAlreadyExist) {
                img["src"] = window["previousImgSrc"];
                descriptionImgParent.appendChild(img);
                addImgInDescriptionBtn.classList.add("d-none");
                }
                else{
                  addImgInDescriptionBtn.classList.remove("d-none");
                }
              setTimeout(() => {
                const questionDescription=document.querySelectorAll(`#${currentQuestion.id} .show-question-description`);
                if(isAlreadyExist){
                questionDescription[0].insertBefore(descriptionImgParent,questionDescription[0].firstChild);
                }
               addImgInDescriptionBtn.addEventListener("click", function() {
                input.click();
                 });
                input.addEventListener('change', (event)=> {
                  var input = event.target;
                  if (input['files'] && input['files'][0]) {
                    var reader = new FileReader();
                    reader.onload = ((e)=> {
                      const descImg=document.getElementById(`descImg_${currentQuestion.id}`)
                      if(descImg){
                        descImg.remove();
                      }
                      img["src"] = e.target["result"].toString();
                     descriptionImgParent.appendChild(img);
                      // this.updateQuestionDescription(currentQuestion,e.target['result'])
                    });
                    reader.readAsDataURL(input['files'][0]);
                  }
                });
              }, 100);
            }
          // }
      }
    } catch { }
  }
  UpdateSurvey = (jsonSurvey, isPublish, isCopy,isAlreadyExist ?:boolean) => {
    const imageQuestion = document.querySelectorAll('.image-picker_question');
    if (imageQuestion.length > 0) {
      for (let i = 0; i < imageQuestion.length; i++) {
        const imageQuestionId = imageQuestion[i].id
        const imagePickerItems = document.querySelectorAll(`#${imageQuestionId} .sv_q_imgsel_image`)
        if (imagePickerItems.length == 0) {
          FormsEvents.sendEvent(FormsEventsEnum.FORMS_ONEMPTYIMAGEITEM, true);
          return;
        }
      }
    }
      try {
        const surveyData= this.addNewPropertyForDescriptionInJson(jsonSurvey)
         this.surveyTitle=document.getElementById("lblFormTitle").innerHTML;
        this.calculateScore(surveyData);
        var xhr = new XMLHttpRequest();
        xhr.open("PUT", APIURL + "interaction/update");
        xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        xhr.setRequestHeader('Authorization', 'Bearer ' + this.access_token);
        xhr.setRequestHeader('client_id', AuthEnum.CLIENTID);
        xhr.setRequestHeader('client_secret', AuthEnum.CLIENTSECRET);
        xhr.onload = () => {
          var result = xhr.response ? JSON.parse(xhr.response) : null;
          if (xhr.status === 200) {
            if (isPublish == true) {
              // notify("Updated Successfully", "success");
              var objtoSend = {
                interactionid: result.interactionid,
                state: FormsStateEnum.UPDATED,
                type: this.formType,
                bubbleid: this.sectionsReceived,
              };
              var commentCopy = {
                state: FormsStateEnum.UPDATED,
                type: this.formType,
              };
              this.disableButtons(false);
              if(!isAlreadyExist){
              if (!freeTextCopyEvent) {
                closeWindow(objtoSend);
              }
              if (isCopy) {
                closeWindow(commentCopy);
              }
            }
            }
          }
          else if(xhr.status === 401){
            this.renewSessionAuthorization().then(() => {
              xhr.open("PUT", APIURL + "interaction/update");
              xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
              xhr.setRequestHeader('Authorization', 'Bearer ' + this.access_token);
              xhr.setRequestHeader('client_id', AuthEnum.CLIENTID);
              xhr.setRequestHeader('client_secret', AuthEnum.CLIENTSECRET);
              xhr.onload = () => {
                var result = xhr.response ? JSON.parse(xhr.response) : null;
                if (xhr.status === 200) {
                  if (isPublish == true) {
                    // notify("Updated Successfully", "success");
                    var objtoSend = {
                      interactionid: result.interactionid,
                      state: FormsStateEnum.UPDATED,
                      type: this.formType,
                      bubbleid: this.sectionsReceived,
                    };
                    var commentCopy = {
                      state: FormsStateEnum.UPDATED,
                      type: this.formType,
                    };
                    this.disableButtons(false);
                    if(!isAlreadyExist){
                    if (!freeTextCopyEvent) {
                      closeWindow(objtoSend);
                    }
                    if (isCopy) {
                      closeWindow(commentCopy);
                    }
                  }
                  }
                }
              };
              xhr.send(
                JSON.stringify({
                  data: surveyData.toString(),
                  active: isPublish,
                  title:
                    this.surveyTitle == "" || this.surveyTitle == undefined
                      ? "untitled-" + Date.now()
                      : this.surveyTitle,
                  type: this.formType, // FormType.POLL,
                  interactionid: this.editsurveyId,
                  questionscount: JSON.parse(this.surveyCreator.text).pages[0]
                    .elements.length,
                  totalmarks: this.totalMarks,
                })
              );
              })
              .catch(() => {});
          }
        };
        xhr.send(
          JSON.stringify({
            data: surveyData.toString(),
            active: isPublish,
            title:
              this.surveyTitle == "" || this.surveyTitle == undefined
                ? "untitled-" + Date.now()
                : this.surveyTitle,
            type: this.formType, // FormType.POLL,
            interactionid: this.editsurveyId,
            questionscount: JSON.parse(this.surveyCreator.text).pages[0].elements
              .length,
            //  sections:this.sectionsReceived,
            totalmarks: this.totalMarks,
          })
        );
      } catch (e) {
        console.debug("add error::: ", e);
      }
    // HideLoading();
  };

  // verifyImagePickerItems(){

  // }

  SaveSurvey =(jsonSurvey, isPublish) => {
    return new Promise((resolve, reject) => {
    // const savebtn = document.getElementById("lnkSave");
    // savebtn.classList.add("disableSaveButton");
    // const saveSvg = document.getElementById("saveSvg");
    // saveSvg.classList.add("disableSaveSvg");
   const surveyData= this.addNewPropertyForDescriptionInJson(jsonSurvey)
    const imageQuestion = document.querySelectorAll('.image-picker_question');
    if (imageQuestion.length > 0) {
      for (let i = 0; i < imageQuestion.length; i++) {
        const imageQuestionId = imageQuestion[i].id
        const imagePickerItems = document.querySelectorAll(`#${imageQuestionId} .sv_q_imgsel_image`)
        if (imagePickerItems.length == 0) {
          FormsEvents.sendEvent(FormsEventsEnum.FORMS_ONEMPTYIMAGEITEM, true);
          return;
        }
      }
    }
      try {
        if (window['formTitle']) {
          this.surveyTitle = window['formTitle'];
        }
        this.calculateScore(JSON.parse(surveyData));
        var xhr = new XMLHttpRequest();
        xhr.open("POST", APIURL + "interaction/Add");
        xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        xhr.setRequestHeader('Authorization', 'Bearer ' + this.access_token);
        xhr.setRequestHeader('client_id', AuthEnum.CLIENTID);
        xhr.setRequestHeader('client_secret', AuthEnum.CLIENTSECRET);
        var self = this;
        xhr.onload =function () {
          var result = xhr.response ? JSON.parse(xhr.response) : null;
          if (xhr.status === 200) {
            resolve(result);
          }
          else if(xhr.status === 401){
            self.renewSessionAuthorization().then(() => {
              xhr.open("POST", APIURL + "interaction/Add");
              xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
              xhr.setRequestHeader('Authorization', 'Bearer ' + self.access_token);
              xhr.setRequestHeader('client_id', AuthEnum.CLIENTID);
              xhr.setRequestHeader('client_secret', AuthEnum.CLIENTSECRET);
              xhr.onload = () => {
                var result = xhr.response ? JSON.parse(xhr.response) : null;
              if (xhr.status === 200) {
              resolve(result);
              }};
              xhr.send(
                JSON.stringify({
                data: surveyData.toString(),
                active: isPublish,
                title:
                  self.surveyTitle == "" || self.surveyTitle == undefined
                    ? "untitled-" + Date.now()
                    : self.surveyTitle,
                type: self.formType,
                questionscount: JSON.parse(self.surveyCreator.text).pages[0].elements
                  .length,
                bubbleid: self.sectionsReceived,
                totalmarks: self.totalMarks,
              }));
              })
              .catch(() => {
                reject();
              });
          }
        };
        xhr.send(
          JSON.stringify({
            data: surveyData.toString(),
            active: isPublish,
            title:
              this.surveyTitle == "" || this.surveyTitle == undefined
                ? "untitled-" + Date.now()
                : this.surveyTitle,
            type: this.formType,
            questionscount: JSON.parse(this.surveyCreator.text).pages[0].elements
              .length,
            bubbleid: this.sectionsReceived,
            totalmarks: this.totalMarks,
          })
        );
      } catch (e) {
        console.log("add error::: ", e);
      }
    });
    // HideLoading();
  };
  onSaveFormPromiseResolve(result){
    var self=this;
    self.editsurveyId = result.interactionid;
    sessionStorage.setItem("interactionID", result.interactionid);
    // notify("Saved Successfully", "success");
    // if (isPublish == true)
    var objtoSend = {
      interactionid: result.interactionid,
      state: FormsStateEnum.SAVED,
      type: self.formType,
      bubbleid: self.sectionsReceived,
    };
    self.surveyCreator.commands.save.toolbar.title =
      self.surveyCreator.getLocString("ed.UpdateSurvey");
    self.isStateNew = false;
    // if (!freeTextCopyEvent) {
    closeWindow(objtoSend);
    // }
    self.disableButtons(false);

  }

  disableButtons = (isDisable) => {
    if (isDisable)
      document.getElementById("htmlInteraction").style.pointerEvents = "none";
    else document.getElementById("htmlInteraction").style.pointerEvents = "all";
  };
  OnLoadCreator = (access_token: string, editsurveyId: string) => {
    try {
      SurveyKo.JsonObject.metaData.addProperty(
        "questionbase",
        "popupdescription:text"
      );
      //score Start
      //Add a property in survey JSON
      SurveyKo.JsonObject.metaData.addProperty("question", {
        name: "marks:number",
        default: 0,
        minValue: 0,
        category: "data",
      });

      //score End

      //Locale

      try {
        if (this.myLanguage == "en") CurrentLangStrings = enLang;
        else if (this.myLanguage == "fr") CurrentLangStrings = frLang;
        else if (this.myLanguage == "de") CurrentLangStrings = deLang;
        document.getElementById("lnkPreview").title =
          CurrentLangStrings.default.ed.testSurvey;
        document.getElementById("lnkSave").title =
          CurrentLangStrings.default.ed.saveSurvey;
        document.getElementById("lnkClose").title =
          CurrentLangStrings.default.ed.close;
        document.getElementById("newQuestion").innerText =
          CurrentLangStrings.default.ed.toolbox;
        document.getElementById("singleselect").innerText =
          CurrentLangStrings.default.qt.radiogroup;
        document.getElementById("multiselect").innerText =
          CurrentLangStrings.default.qt.checkbox;
        CurrentLangStrings.default.qt.radiogroup;
        document.getElementById("type-description").innerText =
          CurrentLangStrings.default.qt.freetext;
        document.getElementById("image-type").innerText =
          CurrentLangStrings.default.qt.image;
        document.getElementById("creator-modal-text").innerText =
          CurrentLangStrings.default.ed.setMarks;
        document.getElementById("creator-body-text").innerText =
          CurrentLangStrings.default.ed.marks;
        document.getElementById("nullText").innerText =
          CurrentLangStrings.default.ed.addtostart;
        document.getElementById("popup-modal-button").innerText =
          CurrentLangStrings.default.pe.okay;
        document.getElementById("creator-question-text").innerText = `${CurrentLangStrings.default.ed.setMarks
          }${":"}`;
        // TODO: Change the strings for Descriptive and Image Type Questions to support multi-lingual
        // document.getElementById("typeDescription").innerText = CurrentLangStrings.default.qt.checkbox;
        // document.getElementById("typeImage").innerText = CurrentLangStrings.default.qt.checkbox;
        document.getElementById("openforResponse").innerText =
          CurrentLangStrings.default.qt.openforResponse;
        SurveyCreator.editorLocalization.locales["en"] =
          CurrentLangStrings.default;
        SurveyCreator.editorLocalization.locales["fr"] =
          CurrentLangStrings.default;
        SurveyCreator.editorLocalization.locales["de"] =
          CurrentLangStrings.default;
        // Survey.surveyLocalization.defaultLocale = this.myLanguage;
        // Survey.surveyLocalization.locales["de"]=CurrentLangStrings.default;

        this.surveyCreator.survey.editorLocalization.locales["de"] =
          CurrentLangStrings.default;

        this.surveyCreator.survey.editorLocalization.currentLocale =
          this.myLanguage;
      } catch { }
      SurveyCreator.editorLocalization.currentLocale = this.myLanguage;

      //locale end

      SurveyKo.JsonObject.metaData.addProperty("page", "popupdescription:text");
      try {
        let options = {
          showEmbededSurveyTab: true,
          generateValidJSON: true,
          showJSONEditorTab: false,
          showNavigationButtons: false,
        };
        this.surveyCreator = new SurveyCreator.SurveyCreator(
          "surveyCreatorContainer",
          options
        );
      } catch (e) {
        //       console.log('exception in creation', e);
      }
      // this.surveyCreator.setIsRequired=true
      this.surveyCreator.haveCommercialLicense = true;
      this.surveyCreator.showToolbox = "none";
      this.surveyCreator.showPropertyGrid = "none";
      this.surveyCreator.rightContainerActiveItem("toolbox");
      var propertyStopList = [
        "visibleIf",
        "enableIf",
        "requiredIf",
        "bindings",
        "defaultValueExpression",
        "columnsVisibleIf",
        "rowsVisibleIf",
        "hideIfChoicesEmpty",
        "choicesVisibleIf",
        "choicesEnableIf",
        "minValueExpression",
        "maxValueExpression",
        "calculatedValues",
        "triggers",
        "logo",
        "logoPosition",
        "logoWidth",
        "logoHeight",
        "logoFit",
        "clearInvisibleValues",
        "textUpdateMode",
        "sendResultOnPageNext",
        "storeOthersAsComment",
        "focusOnFirstError",
        "checkErrorsMode",
        "navigateToUrl",
        "showCompletedPage",
        "completedHtml",
        "navigateToUrlOnCondition",
        "completedHtmlOnCondition",
        "loadingHtml",
        "completedBeforeHtml",
        "maxTimeToFinish",
        "maxTimeToFinishPage",
        "showTimerPanel",
        "showTimerPanelMode",
        "Popupdescription",
        "cookieName",
        "checkboxClass",
        "radioClass",
        "choicesByUrl",
        "choices",
        "completeText",

        "name",
        "title",
        "description",
        "visible",
        "isRequired",
        "readOnly",
        "page",
        "startWithNewLine",
        "hideNumber",
        "state",
        "titleLocation",
        "descriptionLocation",
        "indent",
        "width",
        "minWidth",
        "maxWidth",
        "valueName",
        "defaultValue",
        "useDisplayValuesInTitle",
        "requiredErrorText",
        "validators",
      ];
      this.surveyCreator.onAdornerRendered.add( (sender, options)=>{
        var self=this;
        // setTimeout(() => {
        // self.addImgInDescription(options.question);
        // }, 10);
      })
      this.surveyCreator.onDragDropAllow.add( (sender, options)=> {
        var self=this
        //if source and target are same
        // setTimeout(() => {

        console.log("window[onquestiondropped]:::::",window['onquestiondropped'])
        // }, 500);
        if (options.source.rows && options.target.rows) {
          window["onQuestionDrop"] = () => {
            window["targetQuesEvent"] = options.target;
            const sourceQuestionEvent = options.source;
            const sourceQuestion = document.getElementById(
              `${sourceQuestionEvent["id"]}`
            );
            const targetQuestionEvent = options.target;
            const targetQuestion = document.getElementById(
              `${targetQuestionEvent["id"]}`
            );
            const TargetQuestionTextarea = document.getElementById(
              `${targetQuestionEvent["id"]}${"i"}`
            );
            if (TargetQuestionTextarea) {
              TargetQuestionTextarea.style.resize = "none";
              TargetQuestionTextarea.classList.add("comment-textarea");
              TargetQuestionTextarea["rows"] = 5;
              options.target.rows = 5;
            }
            if (+sessionStorage.getItem("QuestionType") === 1) {
              const removeDuplicates = document.getElementById(
                `btnParent_${targetQuestionEvent["id"]}`
              );
              if (removeDuplicates) {
                removeDuplicates.remove();
              }
              var btnParent = document.createElement("div");
              console.debug("btnParent div", btnParent);
              btnParent.id = `btnParent_${targetQuestionEvent["id"]}`;

              const btn = document.createElement("div");
              const btnTxt = CurrentLangStrings.default.ed.setMarks;
              btn.innerText = `${btnTxt}`;
              btn.id = `btn_${targetQuestionEvent.id}`;
              const defaultMarksSpan = document.createElement("span");
              defaultMarksSpan.innerText = `${" ( "}${targetQuestionEvent.marks
                } ${CurrentLangStrings.default.ed.marks} )`;
              btn.title = `${CurrentLangStrings.default.ed.setMarks}${defaultMarksSpan.innerHTML}`
              btn.appendChild(defaultMarksSpan);
              btn.classList.add("appended_btn", "creator-appended-btn", "svda-add-new-item");
              btnParent.style.display = "flex";
              btnParent.style.justifyContent = "flex-end";
              btnParent.style.paddingTop = "2rem";
              btnParent.classList.add("creator-btn-parent");
              btnParent.style.width = "calc(100% - 14px)";
              btnParent.style.pointerEvents = "all";
              btnParent.appendChild(btn);
              targetQuestion.appendChild(btnParent);
              btn.addEventListener("click", (e) => {
                targetEvent = e.target["offsetParent"].name;
                targetBtnId = e.currentTarget["id"];
                questionEvent = targetQuestion;
                document.getElementById("descriptiveQuestionPoints")["value"] =
                  targetQuestionEvent.marks;
                const statement = document.getElementById(
                  "descriptive_statement"
                );
                while (statement.firstChild) {
                  statement.firstChild.remove();
                }
                const descriptiveStatement = document.createElement("span");
                statement.appendChild(descriptiveStatement);
                descriptiveStatement.innerHTML = questionEvent.title;
                displayPopupDescriptivemodal();
                preventInputMarks(
                  "#descriptiveQuestionPoints",
                  document.getElementById("descriptiveQuestionPoints")["max"]
                );
              });
            }
            // Main.UpdateSurvey(sender.text, true);
            // setTimeout(() => {
            //   Main.commentIconsAllignment();
            // }, 50);
          };
        }

        else{
          this.targetDroppedQuestion=options.target;
          if(document.querySelectorAll(`#${options.source.id} .desc-preview-img`)[0])
          {
          window['previousImgSrc']=document.querySelectorAll(`#${options.source.id} .desc-preview-img`)[0]['currentSrc'];
        }
        }
      }
    )
      // });

      //Question adding/creation event
      var qCountforshowing = 1;
      var selfme = this;
      this.surveyCreator.onQuestionAdded.add(function (sender, options) {
        selfme.calculateScore(sender.JSON)
        var questions = sender.survey.getAllQuestions();
        selfme.surveyQuestionEvent=sender.survey.getAllQuestions();
        setTimeout(() => {
          if (freeTextCopyEvent && options.question.rows) {
            const commentCopyQuestion = options.question;
            console.debug("copy ctreated element.id", options.question.id);
            const commentQuestion = document.getElementById(
              `${commentCopyQuestion["id"]}`
            );
            console.debug("commentQuestion", commentQuestion);
            const questionTextarea = document.getElementById(
              `${commentCopyQuestion["id"]}${"i"}`
            );
            if (questionTextarea) {
              questionTextarea.style.resize = "none";
              questionTextarea.classList.add("comment-textarea");
              questionTextarea["rows"] = 5;
              options.question.rows = 5;
            }
            if (+sessionStorage.getItem("QuestionType") === 1) {
              var btnParent = document.createElement("div");
              console.debug("btnParent div", btnParent);
              btnParent.id = `btnParent_${commentCopyQuestion["id"]}`;
              const btn = document.createElement("div");
              const btnTxt = CurrentLangStrings.default.ed.setMarks;
              btn.innerText = `${btnTxt}`;
              btn.id = `btn_${commentCopyQuestion.id}`;
              const defaultMarksSpan = document.createElement("span");
              defaultMarksSpan.innerText = `${" ( "}${commentCopyQuestion.marks
                }${' '}${CurrentLangStrings.default.ed.marks} )`;
              btn.title = `${CurrentLangStrings.default.ed.setMarks}${defaultMarksSpan.innerHTML}`
              btn.appendChild(defaultMarksSpan);
              btn.classList.add("appended_btn", "creator-appended-btn", "svda-add-new-item");
              btnParent.style.display = "flex";
              btnParent.style.justifyContent = "flex-end";
              btnParent.style.paddingTop = "2rem";
              btnParent.classList.add("creator-btn-parent");
              btnParent.style.width = "calc(100% - 14px)";
              btnParent.style.pointerEvents = "all";
              btnParent.appendChild(btn);
              commentQuestion.appendChild(btnParent);
              btn.addEventListener("click", (e) => {
                targetEvent = e.target["offsetParent"].name;
                targetBtnId = e.currentTarget["id"];
                questionEvent = commentCopyQuestion;
                document.getElementById("descriptiveQuestionPoints")["value"] =
                  options.question.marks;
                const statement = document.getElementById(
                  "descriptive_statement"
                );
                while (statement.firstChild) {
                  statement.firstChild.remove();
                }
                const descriptiveStatement = document.createElement("span");
                statement.appendChild(descriptiveStatement);
                descriptiveStatement.innerHTML = questionEvent.title;
                displayPopupDescriptivemodal();
                preventInputMarks(
                  "#descriptiveQuestionPoints",
                  document.getElementById("descriptiveQuestionPoints")["max"]
                );
              });
            }
            Main.UpdateSurvey(sender.text, true, false);
          }
        }, 10);
        console.debug("questions adding event", questions);
        console.debug("JSON", sender);
        console.debug("this.surveyCreator", this.surveyCreator);
        if (sender) {
          window["childJson"] = sender.JSON.pages[0].elements;
        }
        if (questions.length >= 1) {
          document
            .getElementById("zero-question-content")
            .classList.add("displaynone");
          document
            .getElementById("zero-question-content")
            .classList.remove("d-block");
          const creatorAddDiv = document.getElementById("creatorAddDiv");
          creatorAddDiv.classList.remove("displaynone");
          const preview = document.getElementById("lnkPreview");
          preview.classList.remove("disableSaveButton");
          const diablePreviewSvg = document.getElementById("preview-svg");
          diablePreviewSvg.classList.remove("disableSaveSvg");
        }
        if (!!options.question) {
          // if (me.formType != FormType.QUIZ)
          options.question.isRequired = true;
          options.question.description = "";
          options.question.descriptionLocation = "none";
          var q = options.question;
          // console.log('options');
          // console.log(options);
          // console.log(sender);
          if (CurrentLangStrings.default) {
            if (
              !q.title.startsWith(
                CurrentLangStrings.default.ed.newQuestionName + " "
              ) &&
              q.title.includes("question")
            ) {
              qCountforshowing++;
              q.name =
                CurrentLangStrings.default.ed.newQuestionName + questions.length; // "Question " + questions.length;
              q.title = q.name;

              var indx = 1;
              // q.choices.forEach(element => {

              //   console.log(element.text, ":::", CurrentLangStrings.default.ed.option);
              //   if (element.text.includes("item"))
              //     element.text = element.text.replace('item', CurrentLangStrings.default.ed.option);
              //   else
              //     element.text = CurrentLangStrings.default.ed.option + indx;

              //   if (element.text.includes("Other"))
              //     element.text = CurrentLangStrings.default.pe.addOther;
              //   if (element.text.includes("None"))
              //     element.text = CurrentLangStrings.default.pe.addNone;
              //   if (element.text.includes("Select All"))
              //     element.text = CurrentLangStrings.default.pe.addSelectAll;

              //   //    element.value=element.text.replace('item',CurrentLangStrings.default.ed.option);
              //   indx = indx + 1;
              // });

              selfme.surveyCreator.survey.selectedElement =
                selfme.surveyCreator.survey.getQuestionByName(q.name);
            }
          }
          $("#sv_body").scrollTop($("#sv_body").scrollHeight);
        }
        // if(!options.question.rows){
        selfme.addImgInDescription(options.question);
        // }
      });

      this.surveyCreator.onItemValueAdded.add((sender, options) => {
        if (options.newItem.text.includes("item"))
          options.newItem.text = options.newItem.text.replace(
            "item",
            CurrentLangStrings.default.ed.option
          );
        else
          options.newItem.text =
            CurrentLangStrings.default.ed.option + options.itemValues.length;
      });

      var me = this;
      this.surveyCreator.onConditionQuestionsGetList.add(function (
        editor,
        options
      ) {
        options.list.forEach(function (item) {
          item.text = item.text + " - " + item.name;
        });
      });
      this.surveyCreator.onShowingProperty.add((sender, options) => {
        options.canShow = propertyStopList.indexOf(options.property.name) == -1;
        options.showObjectTitles = false;
        options.showTitlesInExpressions = false;
        if (me.isPropertyClosed == false) {
          me.isPropertyClosed = true;
          const parsed = JSON.parse(sender.text);
          if (parsed) {
            window["childJson"] = parsed.pages[0].elements;
          }
          try {
            me.calculateScore(parsed);
            //    document.getElementById("Qanswerkey").innerHTML="Answer key ("+options.obj.points+" points)";
          } catch (e) { }
          try {
          } catch { }
        }
        setTimeout(() => {
          me.isPropertyClosed = false;
        }, 500);
      });

      //preview wala
      this.surveyCreator.onTestSurveyCreated.add(function (sender, options) {
        options.survey.showCompletedPage = false;
      });
      var me = this;
      this.surveyCreator.toolbarItems.push({
        id: "custom-preview",
        visible: true,
        title: "Preview",
        action: function () {
          let options = { showNavigationButtons: false };
          var testPreviewModal = new Survey.Model(
            me.surveyCreator.text,
            options
          );
          testPreviewModal.completeText = "";
          testPreviewModal.showNavigationButtons = false;
          testPreviewModal.render("surveyContainerInPopup");
          displayPopup();
          modal.open();
        },
      });
      //Add custom button in the toolbar

      //by MOT to get and edit Survey if in edit mode.
      try {
        if (editsurveyId == "undefined" || editsurveyId == "") {
          //   if (me.formType == FormType.POLL)
          var questions = this.surveyCreator.survey.getAllQuestions();
          if (questions.length == 0) {
            const questionBtn = document.getElementById("creatorAddDiv");
            questionBtn.classList.add("displaynone");
            const zerQuestionContent = document.getElementById(
              "zero-question-content"
            );
            zerQuestionContent.className = "d-block";
            const preview = document.getElementById("lnkPreview");
            preview.classList.add("disableSaveButton");
            const diablePreviewSvg = document.getElementById("preview-svg");
            diablePreviewSvg.classList.add("disableSaveSvg");
          }

          // jsonDef = {
          //   title: "",
          //   pages: [
          //     {
          //       name: "page1",
          //       elements: [
          //         {
          //           type: "radiogroup",
          //           name: CurrentLangStrings.default.ed.newQuestionName + "1",
          //           title: CurrentLangStrings.default.ed.newQuestionName + "1",
          //           descriptionLocation:"none",
          //           isRequired: true,
          //           choices: [
          //             CurrentLangStrings.default.ed.option + "1",
          //             CurrentLangStrings.default.ed.option + "2",
          //             CurrentLangStrings.default.ed.option + "3",
          //           ],
          //         },
          //       ],
          //     },
          //   ],
          //   showProgressBar: "top",
          // };

          this.surveyCreator.text = JSON.stringify(jsonDef);
          // else if (me.formType == FormType.QUIZ)
          //   this.surveyCreator.text = JSON.stringify(jsonDefQuiz);

          this.surveyCreator.saveSurveyFunc = this.saveandpublishMySurvey; // this.saveMySurvey;
          //   this.surveyCreator.saveandpublishSurveyFunc = this.saveandpublishMySurvey;
        } else {
          this.getData(access_token, editsurveyId);
          // this.surveyCreator.text=abc;
          this.surveyCreator.saveSurveyFunc = this.saveandpublishMySurvey; // this.saveMySurvey;
          //  this.surveyCreator.saveandpublishSurveyFunc = this.saveandpublishMySurvey;
        }
        if (this.isStateNew)
          this.surveyCreator.commands.save.toolbar.title =
            CurrentLangStrings.default.ed.saveSurvey;
        //this.surveyCreator.getLocString("ed.saveSurvey");
        else {
          this.surveyCreator.commands.save.toolbar.title =
            CurrentLangStrings.default.ed.UpdateSurvey; // this.surveyCreator.getLocString("ed.UpdateSurvey");
        }
      } catch (e) {
        console.debug("text for json exception:::", e);
      }

      displayQuestion();
    } catch { }
  };
  showQuestionTypeOnPreview(questions, surveyQuestions) {
    try {
      for (let i = 0; i < questions.length; i++) {
        const questionNameParent = document.createElement('div');
        const questionName = document.createElement('span');
        questionName.classList.add('preview-question-type');
        questionNameParent.classList.add('preview-question-type-parent', 'd-flex-center');
        const duplicateQuestionHeading = document.querySelectorAll(`#${questions[i].id}`)
        if (duplicateQuestionHeading.length > 1) {
          duplicateQuestionHeading[1].id = `${questions[i].id}_modal`;
          questionNameParent.setAttribute('id', `${questions[i].id}_quest-parent`);
          questionName.setAttribute('id', `${questions[i].id}_preview-type`);
          questionNameParent.appendChild(questionName);
          duplicateQuestionHeading[1].appendChild(questionNameParent);
        }
        else {
          const questHeading = document.getElementById(`${questions[i].id}`);
          questHeading.id = `${questions[i].id}_modal`;
          questionNameParent.setAttribute('id', `${questions[i].id}_quest-parent`);
          questionName.setAttribute('id', `${questions[i].id}_preview-type`);
          questionNameParent.appendChild(questionName);
          questHeading.appendChild(questionNameParent);
        }
        if (surveyQuestions[i].getType() === 'radiogroup') {
          questionName.innerHTML = 'Single Choice';
          document.getElementById(`${questions[i].id}_preview-type`).innerText =
            CurrentLangStrings.default.qt.radiogroup;
          const carretSvg = document.createElement('span');
          carretSvg.classList.add('caret', 'align-preview-caret', 'color-default');
          questionNameParent.appendChild(carretSvg);
        }
        else if (surveyQuestions[i].getType() === 'checkbox') {
          questionName.innerHTML = 'Multiple Choice';
          document.getElementById(`${questions[i].id}_preview-type`).innerText =
            CurrentLangStrings.default.qt.checkbox;
          const carretSvg = document.createElement('span');
          carretSvg.classList.add('caret', 'align-preview-caret', 'color-default');
          questionNameParent.appendChild(carretSvg);
        }
        else if (surveyQuestions[i].getType() === 'comment') {
          questionName.innerHTML = 'Free Text';
          document.getElementById(`${questions[i].id}_preview-type`).innerText =
            CurrentLangStrings.default.qt.freetext;
        }
        else if (surveyQuestions[i].getType() === 'imagepicker') {
          questionName.innerHTML = 'Image';
          document.getElementById(`${questions[i].id}_preview-type`).innerText =
            CurrentLangStrings.default.qt.image;
        }
        else {
          console.log("invalid quetion type");
        }
      }
    }
    catch {
    }
  }
  addImgInDescription(currentQuestion,isDraftQuestion?,draftImgDescJson?){
    if(!document.getElementById(`descParentdiv_${currentQuestion.id}`)){
    const descriptionImgParent=document.createElement('div');
    descriptionImgParent.setAttribute('id',`descParentdiv_${currentQuestion.id}`);
    descriptionImgParent.classList.add('description-image');
    const input=document.createElement('input');
    const addImgInDescriptionBtn=document.createElement('div');
    const svgParentDiv=document.createElement('div');
    var svgElem = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    svgElem.classList.add('default-svg-size')
    var useElem = document.createElementNS("http://www.w3.org/2000/svg", "use");
    useElem.setAttributeNS(null, "href", "assets/sprite.svg#plus-icon");
    svgElem.appendChild(useElem);
    svgParentDiv.appendChild(svgElem)
    svgParentDiv.classList.add('add-image-svg-div')
    addImgInDescriptionBtn.classList.add('add-description-image-btn','svda-add-new-item');
    addImgInDescriptionBtn.setAttribute('id',`descImgBtn_${currentQuestion.id}`);
    addImgInDescriptionBtn.append(svgParentDiv)
    input.type="file";
    const img=document.createElement('img');
    img.classList.add('desc-preview-img');
    img.setAttribute('id',`descImg_${currentQuestion.id}`);
    input.setAttribute('id',`chooseImg_${currentQuestion.id}`);
    input.classList.add('desc-image-input');
    if(descriptionImgParent.id.includes('sq')){
    descriptionImgParent.appendChild(input);
    descriptionImgParent.appendChild(addImgInDescriptionBtn);
    }
    setTimeout(() => {
      if (window["previousImgSrc"]) {
        img["src"] = window["previousImgSrc"];
        descriptionImgParent.appendChild(img);
        addImgInDescriptionBtn.classList.add("d-none");
        img.addEventListener("click", function() {
          input.click();
        });
      }
      else{
        addImgInDescriptionBtn.classList.remove("d-none");
      }
    }, 100);
    if(isDraftQuestion){
      setTimeout(() => {
        draftImgDescJson.pages[0].elements.forEach(element => {
          if(element.name==currentQuestion.name ){
          if(element.imageDescription!=="" && element.imageDescription.includes('data:image')){
        descriptionImgParent.appendChild(img);
        img['src'] = element.imageDescription.toString();
        if (img && img["src"]) {
          addImgInDescriptionBtn.classList.add("d-none");
        } else {
          addImgInDescriptionBtn.classList.remove("d-none");
        }
      }
        else{
          descriptionImgParent.appendChild(addImgInDescriptionBtn);
        }
          }
        });
      }, 100);
    }
    setTimeout(() => {
      const questionDescription=document.querySelectorAll(`#${currentQuestion.id} .show-question-description`);
      if(!document.getElementById(`descParentdiv_${currentQuestion.id}`) && questionDescription[0]){
      questionDescription[0].insertBefore(descriptionImgParent,questionDescription[0].firstChild)
      }
      addImgInDescriptionBtn.addEventListener("click", function() {
        input.click();
      });
      input.addEventListener('change', (event)=> {
        var input = event.target;
        if (input['files'] && input['files'][0]) {
       let IsSuppotedFile = this.checkSupportedImagesType(event);
       if(IsSuppotedFile){
          var reader = new FileReader();
          reader.onload = ((e)=> {
            const descImg=document.getElementById(`descImg_${currentQuestion.id}`)
            if(descImg){
              descImg.remove();
            }
              img['src'] = e.target['result'].toString();
              window['previousImgSrc']=e.target['result'].toString();
            descriptionImgParent.appendChild(img);
              if (img && img["src"]) {
                addImgInDescriptionBtn.classList.add("d-none");
              } else {
                addImgInDescriptionBtn.classList.remove("d-none");
              }
            img.addEventListener('click',()=>{
              input['click']();
            })
          });
          reader.readAsDataURL(input['files'][0]);
        }
        }
      });
    }, 300);
  }
  }
  addNewPropertyForDescriptionInJson(questionJson){
    const parsedJson=JSON.parse(questionJson);
    parsedJson.pages[0].elements.forEach(question => {
      question.imageDescription="";
    });
    this.surveyQuestionEvent.forEach((element,i) => {
      const image=document.getElementById(`descImg_${element.id}`)
      if(image && image['src'] && parsedJson.pages[0].elements[i].name===element.name){
        parsedJson.pages[0].elements[i].imageDescription=image['src'];
      }
    });
    return JSON.stringify(parsedJson);
  }
  updateQuestionDescription=(question,imageUrl)=>{
   var surveyJson=[]
   surveyJson= JSON.parse(this.surveyCreator.text);
   console.debug("window['descriptionImgJson']",this.descriptionImgJson);
   console.debug("[updated actual json]:::",surveyJson);
   surveyJson['pages'][0].elements.forEach(element => {
    if(element.name==question.name){
      element.description=imageUrl;
      this.descriptionImgJson['pages'][0].elements.find((previousElement)=>{
        if(previousElement && element.name==previousElement.name){
          let deletedElement = this.descriptionImgJson['pages'][0].elements.indexOf(element);
          this.descriptionImgJson['pages'][0].elements.splice(deletedElement, 1);
          return true;
        }
      })
    this.descriptionImgJson.pages[0].elements.push(element);
    }
   });
  }
  updateQuestionJsonForDesc=(surveyJson,isPreviewModal?)=>{
      console.debug("[ImagedescriptionJson]:::",this.descriptionImgJson);
      surveyJson.pages[0].elements.map((actualQuestion)=>{
        this.descriptionImgJson.pages[0].elements.map((imgDescQuestion)=>{
          if(actualQuestion.name==imgDescQuestion.name){
            actualQuestion['description']=imgDescQuestion['description'];
          }
        })
      })
      console.debug("[updated actual json]:::",surveyJson)
      this.descriptionImgJson=surveyJson;
  }
  checkSupportedImagesType(event){
    const imagePickerExtension = ["jpg", "jpeg", "png", 'bmp', 'gif', "webp", "svg"];
    const Image = event.target['files'][0].name.split('.');
    const imageExtensionIndex = Image.length - 1
    const ImageExtension = Image[imageExtensionIndex];
    if (imagePickerExtension.indexOf(ImageExtension.toLowerCase()) == -1) {
        var unSupportedFiletype = {
          state: FormsStateEnum.UNSUPPORTEDFILE
        }
        closeWindow(unSupportedFiletype);
        return false;
    }
    else{
      return true;
    }
  }
  createImageForPreview(surveyQuestions,creatorJson){
    surveyQuestions.forEach((question,i) => {
      if(question.name===creatorJson.pages[0].elements[i].name && creatorJson.pages[0].elements[i].imageDescription && creatorJson.pages[0].elements[i].imageDescription.includes('data:image')){
        const questionDescription=document.querySelectorAll(`#${question.id} .sv_q_description`);
        questionDescription[0].classList.remove('sv_q_description');
        questionDescription[0].classList.add('img-preview-description');
        const imgParent=document.createElement('div');
        imgParent.classList.add('preview-modal-img')
        const img=document.createElement('img');
        img.src=creatorJson.pages[0].elements[i].imageDescription;
        imgParent.appendChild(img);
        const descImgQuestion=document.querySelectorAll(`#${question.id}`);
       descImgQuestion[0].children[0].setAttribute('id',`${question.id}_preview-modal-header`);
        const previewQuestionType=document.getElementById(`${question.id}_ariaTitle_modal_quest-parent`);
        const previewModalHeader=document.getElementById(`${question.id}_ariaTitle_modal`);
        const removedElement=previewModalHeader.removeChild(previewQuestionType);
        descImgQuestion[0].children[0]['classList'].add('preview-modal-header');
        descImgQuestion[0].children[0].appendChild(imgParent);
        const headingParent=document.createElement('div');
        headingParent.classList.add('modal-heading-parent');
        headingParent.append(previewModalHeader,removedElement);
        descImgQuestion[0].children[0].insertBefore(headingParent,descImgQuestion[0].children[0].firstChild);
        const textDescription=document.querySelectorAll(`#${question.id}_preview-modal-header .img-preview-description`);
        textDescription[0].insertBefore(imgParent,textDescription[0].firstChild);
        textDescription[0].classList.add('preview-model-description');
      }
    });
  }
  previewButton = () => {
    console.debug("preview");
    let options = { showNavigationButtons: false };
    this.surveyQuestionEvent=this.surveyCreator.survey.getAllQuestions();
    const surveyCratorJson=this.addNewPropertyForDescriptionInJson(this.surveyCreator.text);
    // var testPreviewModal = new Survey.Model(this.surveyCreator.text, options);
    var testPreviewModal = new Survey.Model(surveyCratorJson, options);
    setTimeout(() => {
      const modalElements = document.querySelectorAll('#mainPreviewModal .sv_q_title');
      this.showQuestionTypeOnPreview(modalElements, testPreviewModal.activePage.elementsValue);
      this.createImageForPreview(testPreviewModal.activePage.elementsValue,JSON.parse(surveyCratorJson));
    }, 100);
    setTimeout(() => {
      testPreviewModal.activePage.elementsValue.forEach((element) => {
        if (element.contentMode === "image") {
          const modalImageElement = document.querySelectorAll(`#mainPreviewModal #${element.id}`);
          for (let index = 0; index < modalImageElement.length; index++) {
            const imageDiv = modalImageElement[index].children[1]
            imageDiv.classList.add("disable-image-div")
            const imageStatementDiv = modalImageElement[index].children[1]
            imageStatementDiv.classList.add("disable-element");
          }
        }
        else {
          const modalOtherElements = document.querySelectorAll(`#mainPreviewModal #${element.id}`);
          modalOtherElements[0].classList.add("disable-element");
        }
      })
    }, 10);
    if (this.formType == FormType.QUIZ) {
      var questions = this.surveyCreator.survey.getAllQuestions();
      if (questions.length == 0) {
        const displayScore = document.getElementById("displayscore");
        displayScore.classList.add("displaynone");
      } else {
        const score = document.getElementById("totalScore");
        const displayScore = document.getElementById("displayscore");
        displayScore.classList.remove("displaynone");
        displayScore.innerText = score["outerText"];
      }
    }
    testPreviewModal.completeText = "";
    testPreviewModal.showNavigationButtons = false;
    setTimeout(() => {
      testPreviewModal.activePage.elementsValue.forEach((element) => {
        if (element.rows) {
          const questionTextarea = document.getElementById(
            `${element.id}${"i"}`
          );
          if (questionTextarea) {
            questionTextarea.style.resize = "none";
            questionTextarea.classList.add("comment-textarea");
            questionTextarea["rows"] = 5;
            element.rows = 5;
          }
          // questionTextarea.style.border-radius=""
        }
      });
    }, 10);
    testPreviewModal.render("surveyContainerInPopup");
    displayPopup();
    modal.open();
  };
}
