<div class="dropup  PreviewtopDiv width-100 header-bg-color">
  <div class="FormName FormNamePosition">
    <!-- AddtopClose -->
    <div class="backbluecircle" id="headerIcon" >
      <!-- <svg class="svgFormIconwhite">
        <use xlink:href="assets/sprite.svg#polling_icon"></use>
      </svg> -->
    </div>
    <label id="lblFormTitle" class="FormNameLabel"></label>
  </div>
  <!-- <a href="javascript:confirmClose()" title="Close" class="backgraysquare PreviewtopBtn"> <svg class="svgFormName ">
      <use xlink:href="assets/sprite.svg#closeicon20"></use>
    </svg></a> -->
</div>

<div class="container" id="AttemptContainer">
  <!-- <label id="pageHeaderViewer" style="font-size: 12px; font-weight: bold;"></label> -->
  <survey [json]="json" (submitSurvey)="sendData($event)"></survey>
</div>
