import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from '@angular/router';
import * as deLang from "../../assets/language/language_de.json";
import * as enLang from "../../assets/language/language_en.json";
import * as frLang from "../../assets/language/language_fr.json";
import * as Survey from "../../survey.angular.js";
import * as widgets from "../../surveyjs-widgets.js";
import { init as initCustomWidget } from "./customwidget";
import { AuthEnum, FormsEventsEnum, FormsStateEnum } from 'app/enums/app.enums';

widgets.icheck(Survey);
widgets.select2(Survey);
widgets.inputmask(Survey);
widgets.jquerybarrating(Survey);
widgets.jqueryuidatepicker(Survey);
widgets.nouislider(Survey);
widgets.select2tagbox(Survey);
//widgets.signaturepad(Survey);
widgets.sortablejs(Survey);
widgets.ckeditor(Survey);
widgets.autocomplete(Survey);
widgets.bootstrapslider(Survey);
widgets.prettycheckbox(Survey);
//widgets.emotionsratings(Survey);
initCustomWidget(Survey);
declare function notify(message, type);
declare function ShowLoading();
declare function HideLoading();
declare var APIURL;
declare var configJson;
declare var objforCloseButton;
Survey
  .JsonObject
  .metaData
  .addProperty("question", { name: "marks:number" });
Survey.JsonObject.metaData.addProperty("itemvalue", { name: "marks:number" });
Survey.JsonObject.metaData.addProperty("questionbase", "popupdescription:text");
Survey.JsonObject.metaData.addProperty("page", "popupdescription:text");
Survey.StylesManager.applyTheme("default");

enum FormType {
  POLL = 0,
  QUIZ = 1,
}
var CurrentLangStrings;
@Component({
  // tslint:disable-next-line:component-selector
  selector: "surveypreview",
  template: ` <div class="scoreParentothers">
  <div id="totalScore" class="scoreHeader"></div>
  </div>
  <div class="survey-container contentcontainer codecontainer" id="survey-preview">
    <div id="surveyElement"></div>
    <div id="surveyResult"></div>
  </div>`,
  styleUrls: ["../../../src/surveyviewer.css"]
})
export class SurveyPreviewComponent implements OnInit {

  @Input() json: object;
  @Output() submitSurvey = new EventEmitter<any>();
  @Output() rbcFormObject: EventEmitter<Object> = new EventEmitter();
  access_token: "";
  editsurveyId: "";
  formType: any = 0;
  myLanguage = "en";
  objForClose: any;
  result: any;
  surveyName: "";
  surveyTitle: "";
  totalMarks: any;
  refresh_token="";
  KeyClaokApiUrl;

  constructor(private router: Router, private http: HttpClient) {
    console.debug('This is Form page');
    window['getAuthDetails'] = this.getAuthDetails;
  }
  isJson(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return {};
    }
    return JSON.parse(str);
  }
  renewSessionAuthorization() {
    var retry = 0;
    const body = new HttpParams()
      .set('grant_type', AuthEnum.GRANTTYPE_REFRESHTOKEN)
      .set('client_id', AuthEnum.CLIENTID)
      .set('client_secret', AuthEnum.CLIENTSECRET)
      .set('refresh_token', this.refresh_token);
    return new Promise((resolve, reject) => {
      try {
        var request = new XMLHttpRequest();
        request.onreadystatechange = () => {
          if (request.readyState === 4) {
            var response = this.isJson(request.response);
            if (request.status === 200 && response.access_token) {
              this.access_token = response.access_token;
            this.refresh_token = response.refresh_token;
              resolve(response);
            } else {
              reject(response);
            }
          }
        };

        request.open('POST', this.KeyClaokApiUrl + 'token');
        request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        request.onload = () => { };
        request.send(body.toString());
      } catch (error) { }
    });
  }
  getAuthDetails = (obj) => {
    try {
      this.rbcFormObject.emit(obj);
      this.access_token = obj.access_token;
      this.refresh_token = obj.refresh_token;
      this.editsurveyId = obj.id;
      try {
        this.myLanguage = obj.language;
      } catch {

      }
      try {
        this.formType = obj.type;
        if (this.formType == FormType.POLL) {
          document.getElementById("headerIcon").innerHTML = '<svg class="svgFormIconwhite"><use xlink:href="assets/sprite.svg#polling_icon"></use></svg>';
          document.getElementById("totalScore").classList.add("displaynone");
        }
        else if (this.formType == FormType.QUIZ) {

          document.getElementById("headerIcon").innerHTML = '<svg class="svgFormIconwhite"><use xlink:href="assets/sprite.svg#quiz_icon"></use></svg>';
        }
      } catch { }
      this.objForClose = { 'interactionid': obj.id, 'state': FormsStateEnum.CLOSED, 'type': obj.type };
      objforCloseButton = this.objForClose;
      this.OnLoadCreator(this.access_token, this.editsurveyId, obj.interactionsessionid);
    } catch { }
  }

  ngOnInit() {
    this.KeyClaokApiUrl = `${configJson["authHostUrl"]}protocol/openid-connect/`;
    var target = document.getElementById("basebody");
    target.style.background = '#e6e6e6';
  }

  OnLoadCreator = (access_token: string, editsurveyId: string, interactionsessionid: string) => {
    try {
      // // Load survey by id from url
      console.debug('Form id', editsurveyId);
      var self = this;
      var xhr = new XMLHttpRequest();
      xhr.open('GET', APIURL + 'Interaction/GetById?Id=' + editsurveyId);
      xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
      xhr.setRequestHeader('Authorization', 'Bearer ' + this.access_token);
      xhr.setRequestHeader('client_id', AuthEnum.CLIENTID);
      xhr.setRequestHeader('client_secret', AuthEnum.CLIENTSECRET);
      xhr.onload = function () {
        var result = JSON.parse(xhr.response);
        if (result && result.responseCode &&  result.responseCode === 200) {
          try {
            if (self.myLanguage == "en")
              CurrentLangStrings = enLang;
            else if (self.myLanguage == "fr")
              CurrentLangStrings = frLang;
            else if (self.myLanguage == "de")
              CurrentLangStrings = deLang;

            surveyModel.editorLocalization.locales["en"] = CurrentLangStrings.default;
            surveyModel.editorLocalization.locales["fr"] = CurrentLangStrings.default;
            surveyModel.editorLocalization.locales["de"] = CurrentLangStrings.default;
            surveyModel.editorLocalization.currentLocale = self.myLanguage;
          } catch { }
          console.debug(result);
          self.surveyTitle = result.interactions[0].title;
          if (self.formType == FormType.QUIZ)
            document.getElementById("totalScore").innerHTML = CurrentLangStrings.default.ed.totalmarks + ": " + result.interactions[0].totalmarks;
          document.getElementById("lblFormTitle").innerHTML = self.surveyTitle;
          var questionJson=JSON.parse(result.interactions[0].data);
            setTimeout(() => {
              questionJson.pages[0].elements.forEach((element,i) => {
                if(questionJson.pages[0].elements[i].imageDescription && questionJson.pages[0].elements[i].imageDescription.includes('data:image')){
                  self.addImageInDescription(surveyModel.activePage.elementsValue,questionJson);
                }
              });
            }, 10);
            var surveyModel = new Survey.Model(result.interactions[0].data);
          // var surveyModel = new Survey.Model(result.interactions[0].data);
          setTimeout(() => {
            surveyModel.activePage.elementsValue.forEach(element => {
              if (element.rows) {
                const questionTextarea=document.getElementById(`${element.id}${"i"}`);
                questionTextarea['rows']=5;
                element.rows=5
                questionTextarea.style.resize="none";
                questionTextarea.classList.add("comment-textarea","viewer-comment-border");
              }
            });
          }, 10);
          document.getElementById("surveyElement").innerText = "";
          HideLoading();
          surveyModel.render("surveyElement");

          surveyModel.completeText = "";
          surveyModel.showNavigationButtons = false;

          if (self.formType == FormType.QUIZ) {
            //self.calculateScore(result.interactions[0].questionscount, result.interactions[0].data, survey.data);
            surveyModel
              .onAfterRenderQuestion
              .add(function (survey, options) {
                var maxmarks = document.createElement("span");
                maxmarks.setAttribute("style", "float:right; padding:5px 17px 0 4px;position:relative;bottom:6px;color:var(--amigo-primary-color);");
                maxmarks.innerHTML = options.question.marks == undefined ? '0 ' + CurrentLangStrings.default.ed.marks : options.question.marks + ' ' + CurrentLangStrings.default.ed.marks;//maxmarks;
                var header = options
                  .htmlElement
                  .querySelector("div");
                header.prepend(maxmarks);
              });
          }

        }
        else if(xhr.status == 401){
          self.renewSessionAuthorization().then(() => {
            xhr.open('GET', APIURL + 'Interaction/GetById?Id=' + editsurveyId);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            // xhr.setRequestHeader('AuthToken', AuthToken);
            xhr.setRequestHeader('Authorization', 'Bearer ' + self.access_token);
            xhr.setRequestHeader('client_id', AuthEnum.CLIENTID);
            xhr.setRequestHeader('client_secret', AuthEnum.CLIENTSECRET);
            xhr.onload = () => {
              var result = JSON.parse(xhr.response);
              if (result && result.responseCode &&  result.responseCode === 200) {
                try {
                  if (self.myLanguage == "en")
                    CurrentLangStrings = enLang;
                  else if (self.myLanguage == "fr")
                    CurrentLangStrings = frLang;
                  else if (self.myLanguage == "de")
                    CurrentLangStrings = deLang;

                  surveyModel.editorLocalization.locales["en"] = CurrentLangStrings.default;
                  surveyModel.editorLocalization.locales["fr"] = CurrentLangStrings.default;
                  surveyModel.editorLocalization.locales["de"] = CurrentLangStrings.default;
                  surveyModel.editorLocalization.currentLocale = self.myLanguage;
                } catch { }
                console.debug(result);
                self.surveyTitle = result.interactions[0].title;
                if (self.formType == FormType.QUIZ)
                  document.getElementById("totalScore").innerHTML = CurrentLangStrings.default.ed.totalmarks + ": " + result.interactions[0].totalmarks;
                document.getElementById("lblFormTitle").innerHTML = self.surveyTitle;
                var questionJson=JSON.parse(result.interactions[0].data);
                  setTimeout(() => {
                    questionJson.pages[0].elements.forEach((element,i) => {
                      if(questionJson.pages[0].elements[i].imageDescription && questionJson.pages[0].elements[i].imageDescription.includes('data:image')){
                        self.addImageInDescription(surveyModel.activePage.elementsValue,questionJson);
                      }
                    });
                  }, 10);
                  var surveyModel = new Survey.Model(result.interactions[0].data);
                // var surveyModel = new Survey.Model(result.interactions[0].data);
                setTimeout(() => {
                  surveyModel.activePage.elementsValue.forEach(element => {
                    if (element.rows) {
                      const questionTextarea=document.getElementById(`${element.id}${"i"}`);
                      questionTextarea['rows']=5;
                      element.rows=5
                      questionTextarea.style.resize="none";
                      questionTextarea.classList.add("comment-textarea","viewer-comment-border");
                    }
                  });
                }, 10);
                document.getElementById("surveyElement").innerText = "";
                HideLoading();
                surveyModel.render("surveyElement");

                surveyModel.completeText = "";
                surveyModel.showNavigationButtons = false;

                if (self.formType == FormType.QUIZ) {
                  //self.calculateScore(result.interactions[0].questionscount, result.interactions[0].data, survey.data);
                  surveyModel
                    .onAfterRenderQuestion
                    .add(function (survey, options) {
                      var maxmarks = document.createElement("span");
                      maxmarks.setAttribute("style", "float:right; padding:5px 17px 0 4px;position:relative;bottom:6px;color:var(--amigo-primary-color);");
                      maxmarks.innerHTML = options.question.marks == undefined ? '0 ' + CurrentLangStrings.default.ed.marks : options.question.marks + ' ' + CurrentLangStrings.default.ed.marks;//maxmarks;
                      var header = options
                        .htmlElement
                        .querySelector("div");
                      header.prepend(maxmarks);
                    });
                }

              }};
            xhr.send();
            })
            .catch(() => {
            });
        }
      };
      xhr.send();
    } catch { }
    // HideLoading();

  }
  addImageInDescription(surveyJson,apiResponseJson){
    surveyJson.forEach((question,i)=>{
    if(question.name==apiResponseJson.pages[0].elements[i].name && apiResponseJson.pages[0].elements[i].imageDescription && apiResponseJson.pages[0].elements[i].imageDescription.includes('data:image')){
    if(!document.getElementById(`descImgParent_${question.id}`)){
    const descriptionImgParent=document.createElement('div');
    descriptionImgParent.setAttribute('id',`descImgParent_${question.id}`);
    descriptionImgParent.classList.add('viewer-img-desc');
    const img=document.createElement('img');
    img.classList.add('desc-preview-img');
    img.src=apiResponseJson.pages[0].elements[i].imageDescription;
    img.setAttribute('id',`descImg_${question.id}`);
    descriptionImgParent.appendChild(img);
    const questionDescription=document.querySelectorAll(`#${question.id} .sv_q_description`);
    questionDescription[0].insertBefore(descriptionImgParent,questionDescription[0].firstChild);
      }
    }
    })
  }
  calculateScore(count, questionnairejson, json) {
    // var total = 0;
    // try {
    //   JSON.parse(questionnairejson).pages[0].elements.forEach(element => {
    //     console.log(element.name);
    //     if (element.marks != undefined && element.marks != NaN && element.marks > 0) {

    //       //    if (element.presencestatus == true) {
    //       //if (JSON.stringify(element.correctAnswer) == JSON.stringify(JSON.parse(element.data)[element.name])) {
    //       if (JSON.stringify(json[element.name]) == (JSON.stringify(element.correctAnswer))) {
    //         //            console.log(element.name, ' has correct answer');
    //         total = total + element.marks;
    //       }
    //       // else
    //       // console.log(element.name, ' has wrong answer');
    //       document.getElementById("totalScore").innerHTML = "Total marks: " + total;
    //     }
    //   });
    //   this.totalMarks = total;
    //   console.log(total)
    //} catch { }
  }
}
