<div class="dialog-container"
    [ngClass]="{'confirm-border': data?.type == 'confirm' || data?.type == 'orange' || data?.type == 'cancelOrange', 'alert-border': data?.type == 'red', 'blue-border': data?.type == 'blue'}">
    <div mat-dialog-title class="dialog-title">{{data?.title}}</div>
    <div mat-dialog-content>
        <p class="dialog-content">{{data?.content}}</p>
    </div>
    <div mat-dialog-actions>
        <button *ngIf="data?.type == 'confirm'" matRipple class="dialog-btn btn-orange"
            (click)="popupActions(true)">Yes</button>
        <button *ngIf="data?.type == 'confirm'" matRipple class="dialog-btn btn-orange"
            (click)="popupActions(false)">No</button>
        <button id="popup-modal-button" *ngIf="data?.type == 'red'" matRipple class="dialog-btn btn-red"
            (click)="popupActions(false)">Okay</button>
    </div>
</div>
