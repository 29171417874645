import { Component, HostListener, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material";
import { FormsEventsEnum, FormsStateEnum,FormType } from "app/enums/app.enums";
import json from "../../assets/amigo.json";
import * as html2pdf from 'html2pdf.js';
import * as deLang from "../../assets/language/language_de.json";
import * as enLang from "../../assets/language/language_en.json";
import * as frLang from "../../assets/language/language_fr.json";

declare var FormsEvents: any;
declare function saveandpublishMySurvey();
declare function closeWindow(obj);
@Component({
  selector: "creator-page",
  templateUrl: "./creator.page.html",
  styleUrls: ["../../surveycreator.css"],
  host: { 'id': 'creator-container' }
})
export class CreatorPage implements OnInit, OnDestroy {
  json = json;
  formObject;
  CurrentLangStrings
  formTitle;
  btnLoader=false;
  myLanguage = "en";
  formTypeEnum=FormType;
  @HostListener("document:keydown.escape", ["$event"]) onKeydownHandler(
    event: KeyboardEvent
  ) {
    this.closeDescriptiveModal();
  }
  constructor(private dialog: MatDialog) {
    // window["showPopUp"]=this.showPopUp
  }

  ngOnInit(): void {
    this.eventHandler();
  }

  eventHandler() {
  }
  removeEvents() {
  }
  ngOnDestroy() {
    this.removeEvents();
  }
  addSingleChoice() {
    window["AddSCQ"]();
    //   const dialogRef = this.dialog.open(PopupModalComponent, {
    //     data: {
    //       title: 'languageJson.dropdowns.linkLogout',
    //       content: 'languageJson.popups.logout',
    //       type: 'confirm'
    //     }
    //   });

    //   dialogRef.afterClosed().subscribe((confirmed) => {
    //     if (confirmed) {
    //       // console.log('confirm');

    //     } else {
    //       // console.log('cancel');
    //     }
    //   });
  }

  addMultiChoice() {
    window["AddMCQ"]();
  }
  addDescriptive() {
    window["AddTXT"]();
  }

  AddImage() {
    window["AddImageQuestion"]();
  }
  closeDescriptiveModal() {
    const descriptiveModel = document.getElementById("descriptiveModelSurvey");
    descriptiveModel.style.display = "none";
  }

  onSurveySaved(survey) {
    this.json = survey;
  }

  setDescriptiveMarks() {
    var imagePickerMarks = document.getElementById("descriptiveQuestionPoints");
    console.debug(
      "The Marks of descriptvie Question is:::",
      imagePickerMarks["value"]
    );
    FormsEvents.sendEvent(FormsEventsEnum.FORMS_ONUPDATEFREETEXTMARKS, true);
    this.closeDescriptiveModal()
  }
  saveSurvey() {
    saveandpublishMySurvey()
  }
  editFormName() {
    if(window['formTitle']){
    const renameObject = {
      state: FormsStateEnum.RENAMEFORM,
      type: this.formObject.type,
      title: window['formTitle'],
    }
    console.debug('on edit form title:::',this.formTitle);
    closeWindow(renameObject)
    console.debug("ClosrenameObject:::",renameObject)
  }
  }
  onObjectReceived(event) {
    this.formObject = event;
    this.myLanguage=this.formObject.language;
    if (this.myLanguage == "en") this.CurrentLangStrings = enLang;
    else if (this.myLanguage == "fr") this.CurrentLangStrings = frLang;
    else if (this.myLanguage == "de") this.CurrentLangStrings = deLang;
  }
  onFromTitleReceived(event) {
    this.formTitle = event;
  }
  exportToPdf(){
    try {
      this.btnLoader=true;
      var element = document.getElementById('scrollableDiv');
      const questionRow=document.getElementsByClassName('sv_row')[0]
      if(questionRow){
        questionRow['click']();
      }
      const parentDiv=document.createElement('div');
      parentDiv.classList.add('parent-cloned-formname');
      const logoDiv=document.createElement('div');
      logoDiv.classList.add('logo-img-container')
      logoDiv.innerHTML=`<svg version="1.1" id="rbc_light_logo" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 292 60" xml:space="preserve">
   <style type="text/css">
     .st0{fill:#FFFFFF;}
     .st1{fill:#46B44C;}
     .st2{fill:#E14731;}
     .st3{fill:#C21D76;}
     .st4{fill:#6A4499;}
     .st5{fill:#4180C1;}
     .st6{fill:#A6A6A6;}
     .st7{fill:#5C5C5C;}
   </style>
   <g>
     <path style="fill: #FFFFFF;" d="M46.3,52H13.9C10.7,52,8,49.3,8,46.1V13.9C8,10.6,10.7,8,13.9,8h32.4c3.3,0,5.9,2.7,5.9,5.9V46
       C52.2,49.2,49.5,52,46.3,52z"/>
     <path id="XMLID_2_" style="fill: #46B44C;" d="M10.4,15.4c0.1-2.6,2.2-5,4.8-5.4c0.5-0.1,1-0.1,1.5-0.1c3.5,0,7,0,10.4,0c5.6,0,11.2,0,16.9,0
       c1.7,0,3.3,0.5,4.5,1.7s1.8,2.6,1.8,4.2c0,2.6,0,5.1,0,7.7c0.1-0.1,0-0.1,0,0c-1.3-0.2-2.5-0.5-3.8-0.7c0-0.2,0-0.4,0-0.6
       c0-2.1,0-4.1,0-6.2c0-1.3-0.9-2.4-2.3-2.4c-6.1,0-12.2,0-18.4,0c-3,0-5.9,0-8.9,0c-0.6,0-1.2,0-1.7,0.4c-0.7,0.5-1,1.2-1,2
       c0,4.9,0,9.8,0,14.7c0,0.5-0.1,0.8-0.5,1.1c-1,0.9-2,1.9-3,2.8c-0.1,0.1-0.2,0.2-0.3,0.3"/>
     <path id="XMLID_768_"  style="fill: #E14731;" d="M14.2,36c0,0.4,0,0.7,0,1c0,2.2,0,4.4,0,6.6c0,1.2,0.9,2.3,2.2,2.3c2.1,0,4.2,0,6.3,0
       c2.8,0,5.7,0,8.5,0c2.9,0,5.8,0,8.7,0c1.4,0,2.8,0,4.2,0s2.3-1.1,2.3-2.3c0-5.6,0-11.1,0-16.7c0-0.7,0-1.4,0-2.2
       c0.5,0.1,1,0.1,1.5,0.2c0.7,0.1,1.3,0.3,2,0.4c0.3,0,0.3,0.2,0.3,0.5c0,3.9,0,7.8,0,11.7c0,2.1,0,4.2,0,6.3c0,2.9-1.8,5.2-4.6,5.9
       C45.1,49.9,44.5,50,44,50c-9.1,0-18.2,0-27.3,0c-1.8,0-3.4-0.5-4.7-1.8c-1.1-1.1-1.7-2.4-1.7-3.9c0-1.2,0-2.3,0-3.5
       c0-0.2,0-0.3,0.1-0.4c1.2-1.4,2.4-2.7,3.6-4.1C14.1,36.2,14.1,36.2,14.2,36z"/>
     <path  style="fill: #C21D76;" d="M45.5,49.8C45.5,49.8,45.6,49.8,45.5,49.8c1.1-0.3,2-0.8,2.8-1.4c1.2-1.1,1.9-2.7,1.9-4.5c0-2.1,0-4.2,0-6.3
       c0-3.9,0-7.8,0-11.7c0-0.3,0-0.5-0.3-0.5c-0.7-0.1-1.3-0.3-2-0.4c-0.5-0.1-1-0.1-1.5-0.2c0,0.6,0,1.2,0,1.7c0,0.2,0,0.3,0,0.5
       c0,5.6,0,11.1,0,16.6c0,0,0,0,0,0.1c0,1.2-0.9,2.3-2.3,2.3c-1.4,0-2.8,0-4.2,0c-2.8,0-5.6,0-8.4,0c0.4,0.9,1.3,3,2.5,4
       c3.3,0,6.6,0,10,0c0,0,0,0,0.1,0C44.5,50,45,49.9,45.5,49.8z"/>
     <path  style="fill: #6A4499;" d="M44,44H16.6c-0.2,0-0.3-0.2-0.3-0.5l0,0c0-0.3,0.1-0.5,0.3-0.5H44c0.2,0,0.3,0.2,0.3,0.5l0,0
       C44.3,43.8,44.1,44,44,44z"/>
     <g>
       <path  style="fill: #6A4499;" d="M25.6,42.7c0-0.4-0.2-0.7-0.6-0.9l-0.1-0.1c-0.2-0.8-0.7-1.3-1.5-1.6c-0.6-0.2-1.1-0.3-1.7-0.2
         c-0.7,0-1.3,0.1-1.9,0.5c-0.5,0.3-0.9,0.7-1,1.3c0,0.1-0.1,0.1-0.1,0.2c-0.4,0.2-0.6,0.4-0.6,0.9l0,0V44l0,0l0,0
         c0.2,0,0.4,0,0.6,0l0,0l0,0v-1c0-0.1,0-0.2,0-0.3c0-0.2,0.1-0.3,0.4-0.3c1.9,0,3.8,0,5.6,0c0.3,0,0.4,0.1,0.4,0.4
         c0,0.1,0,0.2,0,0.3v0.1c0,0.2,0,0.3,0,0.5l0,0v0.2l0,0c0.2,0,0.4,0,0.6,0l0,0L25.6,42.7L25.6,42.7z"/>
     </g>
     <path  style="fill: #6A4499;" d="M40.5,43.9c0-0.4,0-0.6,0-1c0-0.3-0.1-0.4-0.4-0.4c-1.8,0-3.7,0-5.5,0c-0.3,0-0.4,0.1-0.4,0.4c0,0.4,0,0.6,0,1
       c-0.2,0-0.4,0-0.6,0c0-0.1,0-0.1,0-0.2c0-0.4,0-0.5,0-0.9c0-0.5,0.2-0.8,0.6-1l0.1-0.1c0.2-0.9,0.8-1.3,1.7-1.5
       c0.6-0.1,1.1-0.2,1.7-0.2c0.7-0.1,1.2-0.4,1.7-0.8c0.9-0.8,1.4-1.7,1.8-2.8c0.1-0.4,0.5-0.6,0.9-0.5s0.7,0.3,0.7,0.7
       c0,0.3-0.1,0.6-0.2,0.9c-0.3,0.9-0.7,1.8-1.3,2.5c-0.3,0.5-0.8,1-1.3,1.5c-0.1,0-0.1,0.2-0.1,0.2c0.1,0,0.1,0.1,0.2,0.1
       c0.2,0,0.4,0,0.6,0c0.4,0.1,0.7,0.5,0.8,0.9c0,0.4,0,0.6,0,1c0,0.1,0,0.1-0.1,0.1C40.8,43.9,40.6,43.9,40.5,43.9z"/>
     <path  style="fill: #6A4499;" d="M37.3,34.8c1.1,0,2,0.9,2,2s-0.9,2-2,2s-2-0.9-2-2C35.3,35.8,36.2,34.8,37.3,34.8z"/>
     <path  style="fill: #6A4499;" d="M25.6,42.7c0-0.4-0.2-0.7-0.6-0.9l-0.1-0.1c-0.2-0.8-0.7-1.3-1.5-1.6c-0.6-0.2-1.1-0.3-1.7-0.2
       c-0.7,0-1.3,0.1-1.9,0.5c-0.5,0.3-0.9,0.7-1,1.3c0,0.1-0.1,0.1-0.1,0.2c-0.4,0.2-0.6,0.4-0.6,0.9l0,0V44l0,0l0,0c0.2,0,0.4,0,0.6,0
       l0,0l0,0v-1c0-0.1,0-0.2,0-0.3c0-0.2,0.1-0.3,0.4-0.3c1.9,0,3.8,0,5.6,0c0.3,0,0.4,0.1,0.4,0.4c0,0.1,0,0.2,0,0.3v0.1
       c0,0.2,0,0.3,0,0.5l0,0v0.2l0,0c0.2,0,0.4,0,0.6,0l0,0L25.6,42.7L25.6,42.7z"/>
     <path  style="fill: #6A4499;" d="M32.7,42.7c0-0.4-0.2-0.7-0.5-0.9L32,41.7c-0.1-0.8-0.6-1.3-1.2-1.6c-0.4-0.2-0.9-0.3-1.4-0.2
       c-0.5,0-1.1,0.1-1.5,0.5c-0.4,0.3-0.7,0.7-0.8,1.3c0,0.1-0.1,0.1-0.1,0.2c-0.3,0.2-0.5,0.5-0.5,0.9s0,0.6,0,1c0,0.1,0,0.1,0,0.2
       c0.2,0,0.4,0,0.6,0c0-0.1,0-0.1,0-0.2c0-0.4,0-0.5,0-0.9c0-0.2,0.1-0.3,0.3-0.3c1.5,0,2.7,0,4.2,0c0.2,0,0.3,0.1,0.3,0.4
       c0,0.4,0,0.5,0,0.9c0,0.1,0,0.1,0,0.2l0,0h0.6v-0.2l0,0C32.7,43.2,32.7,43.2,32.7,42.7z"/>
     <path  style="fill: #6A4499;" d="M29.5,34.8c1.1,0,2,0.9,2,2s-0.9,2-2,2c-1,0-2-0.9-2-2C27.5,35.8,28.5,34.8,29.5,34.8z"/>
     <path  style="fill: #6A4499;" d="M20,38.8c0-0.6,0-1.3,0-1.9c0-0.4,0.1-0.9,0.4-1.2c0.5-0.6,1.2-0.9,2-0.7c0.3,0.1,0.5,0.2,0.7,0.5l0,0
       c0.1,0.1,0.3,0.1,0.4,0.2c0.3,0.3,0.4,0.7,0.4,1.1c0,0.7,0,1.3,0,2c0,0,0,0,0,0.1c-0.2,0-0.3,0-0.5,0c-0.2-0.1-0.4-0.2-0.5-0.4
       c-0.3,0.3-0.7,0.4-1.1,0.4s-0.8-0.1-1.1-0.4C20.8,38.8,20.4,38.8,20,38.8L20,38.8z"/>
     <path  style="fill: #6A4499;" d="M32.3,36.8L32.3,36.8v-0.2v-0.1c-0.2-1.4-1.3-2.4-2.7-2.4l0,0c-0.7,0-1.4,0.3-2,0.8c-0.4,0.4-0.7,1-0.8,1.6
       v0.1v0.8c0,0.1,0.1,0.2,0.2,0.2h0.3c0.1,0,0.2-0.1,0.2-0.2v-0.8c0-0.1-0.1-0.2-0.2-0.2l0,0c0.1-0.5,0.3-0.9,0.6-1.2
       c0.5-0.5,1-0.7,1.7-0.7l0,0c1.1,0,2.1,0.8,2.3,1.9h-0.1c-0.1,0-0.2,0.1-0.2,0.2v0.8c0,0.1,0.1,0.2,0.2,0.2h0.3
       c0.1,0,0.2-0.1,0.2-0.2V36.8L32.3,36.8L32.3,36.8z"/>
     <circle  style="fill: #6A4499;" cx="22.9" cy="19.9" r="1.5"/>
     <path  style="fill: #6A4499;" d="M26.5,19.1c-0.4,0-0.7,0.2-0.8,0.6c-0.1,0.6-0.1,1.1-0.2,1.7c-0.1,0.5,0,0.5-0.5,0.7c-0.1,0-0.2,0.1-0.3,0.1
       c-0.2,0.1-0.5,0.2-0.8,0.1c-0.9,0-1.8,0-2.7,0c-0.8,0-1.3,0.5-1.3,1.3c0,1.2,0,0.8,0,2c0,0.5,0.3,0.9,0.8,1.1c0.1,0,0.3,0,0.5,0.1
       c0,0,0,0.4,0,0.8h3.1c0.1-2,0.2-2.4,0.2-3.5c0-0.1,0-0.2,0.2-0.2c0.5-0.2,1-0.4,1.6-0.6c0.3-0.1,0.4-0.3,0.5-0.6
       c0.1-0.9,0.2-1.7,0.3-2.5C27.2,19.6,26.9,19.1,26.5,19.1z"/>
     <g>
       <rect x="34.7" y="24.4"  style="fill: #6A4499;" width="6" height="1"/>
       <rect x="32.8" y="21.4"  style="fill: #6A4499;" width="7.7" height="1"/>
       <rect x="30.6" y="18.4"  style="fill: #6A4499;" width="10" height="1"/>
     </g>
     <g>
       <path  style="fill: #6A4499;" d="M33,31.1L33,31.1c0.8,0,1.5,0,2.2,0c1.3,0,6.7,0,8,0c0.2,0,0.3,0,0.5-0.1c0.4-0.2,0.6-0.6,0.6-1.1
         c0-1.7,0-5.8,0-7.5c0-1.9,0-3.7,0-5.6c0-0.3-0.1-0.5-0.2-0.7c-0.2-0.3-0.6-0.4-1-0.4c-1.5,0-8.1,0-9.7,0c-3.6,0-12.3,0-16,0
         c-0.1,0-0.2,0-0.2,0c-0.4,0.1-0.8,0.5-0.8,1.1c0,1.5,0,3.1,0,4.6c0,1.9,0,6.3,0,8.2c0,0.2,0,0.4,0,0.6c0.1,0.5,0.5,0.9,1.1,0.9
         c1.9,0,7.6,0,9.5,0c0.2,0,0.4,0,0.6,0c-0.1,0.4-0.1,0.4-0.2,0.9c-0.1,0.3-0.3,0.5-0.6,0.5c-0.1,0-0.2,0-0.4,0c-0.1,0-0.1,0-0.2,0
         c-0.2,0-0.3,0.2-0.2,0.4c0.1,0.2,0.2,0.3,0.3,0.3c0.6,0,1.1,0,1.6,0c1.4,0,4.9,0,6.2,0c0.2,0,0.4-0.1,0.4-0.4
         c0-0.2-0.1-0.4-0.4-0.4c-0.1,0-0.2,0-0.3,0c-0.4,0-0.6-0.2-0.7-0.6 M30.3,30.4c-0.6,0-1-0.5-1-1c0-0.6,0.5-1,1-1s1,0.5,1,1
         S30.9,30.4,30.3,30.4z M43.6,24.4v2.9c-5.5,0-21,0-26.6,0c0-2.7,0-7.2,0-9.5c0-0.4,0-0.7,0-1.1c0-0.3,0.1-0.4,0.4-0.4
         c1.6,0,8.3,0,9.9,0s3.2,0,4.8,0c1.6,0,8.2,0,9.8,0c0.4,0,0.8,0,1.3,0c0.3,0,0.4,0.1,0.4,0.4C43.6,18.8,43.6,22.4,43.6,24.4z"/>
     </g>
     <path  style="fill: #4180C1;" d="M48.5,11.6c-1.3-1.2-2.8-1.7-4.5-1.7c-5.5,0-10.9,0-16.3,0c-0.7,0.7-1.5,1.9-2.1,3.7c0.1,0,0.1,0,0.2,0
       c6.2,0,12.3,0,18.4,0c1.1,0,1.9,0.7,2.2,1.7c0,0,0,0,0,0.1s0,0.2,0.1,0.3c0,0.1,0,0.2,0,0.3c0,0,0,0,0,0.1s0,0.1,0,0.2c0,2,0,4,0,6
       c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c1.3,0.2,2.5,0.5,3.8,0.7c0-2.6,0-5.1,0-7.7C50.2,14.2,49.6,12.9,48.5,11.6z"/>
     <g>
       <path  style="fill: #A6A6A6;" d="M62.8,22.5v4.7h1.7c0.2,0,0.4,0,0.5,0.1s0.1,0.2,0.1,0.3c0,0.1,0,0.2-0.1,0.3C64.9,28,64.7,28,64.5,28h-3.8
         c-0.2,0-0.4,0-0.5-0.1c-0.1,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.5-0.1h1.2V16.5h-1.2c-0.2,0-0.4,0-0.5-0.1
         S60,16.1,60,15.9c0-0.1,0-0.2,0.1-0.3s0.2-0.1,0.5-0.1H66c1.1,0,2.1,0.4,2.8,1.1c0.8,0.7,1.2,1.5,1.2,2.4c0,0.6-0.2,1.3-0.7,1.8
         c-0.5,0.6-1.3,1-2.4,1.4c0.6,0.4,1.2,0.9,1.7,1.5s1.2,1.7,2.2,3.4h0.7c0.2,0,0.4,0,0.5,0.1s0.1,0.2,0.1,0.3c0,0.1,0,0.2-0.1,0.3
         c0,0.2-0.2,0.2-0.4,0.2h-1.2c-1.1-2-2-3.3-2.6-4s-1.3-1.2-2.1-1.6h-2.9C62.8,22.4,62.8,22.5,62.8,22.5z M62.8,21.6h2.5
         c0.8,0,1.5-0.1,2.1-0.4s1.1-0.6,1.4-1s0.4-0.8,0.4-1.2c0-0.6-0.3-1.2-0.9-1.7s-1.3-0.8-2.2-0.8h-3.3
         C62.8,16.5,62.8,21.6,62.8,21.6z"/>
       <path  style="fill: #A6A6A6;" d="M81.8,28.1v-1.3c-1.3,1.1-2.7,1.7-4.2,1.7c-1.1,0-1.9-0.3-2.5-0.8s-0.9-1.2-0.9-2c0-0.9,0.4-1.6,1.2-2.3
         c0.8-0.7,2-1,3.5-1c0.4,0,0.9,0,1.4,0.1s1,0.1,1.6,0.3v-1.5c0-0.5-0.2-0.9-0.7-1.3s-1.1-0.5-2.1-0.5c-0.7,0-1.7,0.2-3,0.6
         c-0.2,0.1-0.4,0.1-0.4,0.1c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.1-0.2-0.1-0.3s0-0.2,0.1-0.3s0.5-0.3,1.2-0.4c1.1-0.3,2-0.5,2.5-0.5
         c1.1,0,2,0.3,2.7,0.8c0.6,0.6,1,1.2,1,1.9v6H84c0.2,0,0.4,0,0.5,0.1s0.1,0.2,0.1,0.3c0,0.1,0,0.2-0.1,0.3
         c-0.1,0.1-0.3,0.1-0.5,0.1C83.9,28.1,81.8,28.1,81.8,28.1z M81.8,23.6c-0.4-0.1-0.9-0.2-1.3-0.3c-0.5-0.1-1-0.1-1.5-0.1
         c-1.3,0-2.3,0.3-3.1,0.8c-0.6,0.4-0.8,0.9-0.8,1.5c0,0.5,0.2,1,0.6,1.4s1,0.6,1.9,0.6c0.8,0,1.5-0.2,2.2-0.5s1.4-0.8,2.1-1.5
         L81.8,23.6L81.8,23.6z"/>
       <path  style="fill: #A6A6A6;" d="M93.1,18.8v8.4h3.5c0.2,0,0.4,0,0.5,0.1s0.1,0.2,0.1,0.3c0,0.1,0,0.2-0.1,0.3S96.8,28,96.6,28h-8
         c-0.2,0-0.4,0-0.5-0.1S88,27.6,88,27.6c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.5-0.1h3.5v-7.5h-2.6c-0.2,0-0.4,0-0.5-0.1
         s-0.1-0.2-0.1-0.3s0-0.2,0.1-0.3s0.3-0.1,0.5-0.1h3.6V18.8z M93,14.1v2.3h-1.3v-2.3C91.7,14.1,93,14.1,93,14.1z"/>
       <path  style="fill: #A6A6A6;" d="M103.1,18.8v1.4c0.6-0.6,1.2-1.1,1.7-1.3c0.5-0.2,1.1-0.4,1.7-0.4c0.7,0,1.3,0.1,1.9,0.4
         c0.4,0.2,0.8,0.6,1.1,1.1c0.3,0.5,0.5,1,0.5,1.5v5.7h0.8c0.2,0,0.4,0,0.5,0.1s0.1,0.2,0.1,0.3c0,0.1,0,0.2-0.1,0.3
         s-0.2,0.1-0.5,0.1h-2.4c-0.2,0-0.4,0-0.5-0.1s-0.1-0.2-0.1-0.3c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.3-0.1,0.5-0.1h0.8v-5.5
         c0-0.6-0.2-1.2-0.7-1.6s-1.1-0.7-1.9-0.7c-0.6,0-1.1,0.1-1.5,0.4c-0.4,0.2-1.1,0.8-1.9,1.8v5.7h1c0.2,0,0.4,0,0.5,0.1
         s0.1,0.2,0.1,0.3c0,0.1,0,0.2-0.1,0.3s-0.2,0.1-0.5,0.1h-2.9c-0.2,0-0.4,0-0.5-0.1s-0.1-0.2-0.1-0.3c0-0.1,0-0.2,0.1-0.3
         c0.1-0.1,0.2-0.1,0.5-0.1h1v-7.5h-0.8c-0.2,0-0.4,0-0.5-0.1s-0.1-0.2-0.1-0.3s0-0.2,0.1-0.3s0.2-0.1,0.5-0.1h1.6
         C103.1,19,103.1,18.8,103.1,18.8z"/>
       <path  style="fill: #A6A6A6;" d="M116,14.6v6c1.1-1.4,2.4-2.1,3.9-2.1c1.3,0,2.4,0.5,3.4,1.4s1.4,2.1,1.4,3.5s-0.5,2.6-1.4,3.6
         s-2.1,1.5-3.4,1.5c-1.6,0-2.9-0.7-3.9-2.1v1.8h-2.1c-0.2,0-0.4,0-0.5-0.1s-0.1-0.2-0.1-0.3c0-0.1,0-0.2,0.1-0.3
         c0.1-0.1,0.2-0.1,0.5-0.1h1.2V15.6h-1.2c-0.2,0-0.4,0-0.5-0.1s-0.1-0.2-0.1-0.3s0-0.2,0.1-0.3s0.2-0.1,0.5-0.1h2.1V14.6z
          M123.9,23.5c0-1.1-0.4-2.1-1.2-2.9c-0.8-0.8-1.7-1.2-2.7-1.2s-2,0.4-2.7,1.2c-0.8,0.8-1.2,1.8-1.2,2.9s0.4,2.1,1.2,2.9
         c0.8,0.8,1.7,1.2,2.7,1.2s2-0.4,2.7-1.2C123.5,25.6,123.9,24.7,123.9,23.5z"/>
       <path  style="fill: #A6A6A6;" d="M137.9,23.5c0,1.4-0.5,2.5-1.5,3.5s-2.2,1.5-3.6,1.5c-1.4,0-2.6-0.5-3.6-1.5s-1.5-2.1-1.5-3.5
         s0.5-2.6,1.5-3.5s2.2-1.5,3.6-1.5c1.4,0,2.6,0.5,3.6,1.5S137.9,22.1,137.9,23.5z M136.9,23.5c0-1.1-0.4-2.1-1.2-2.9
         s-1.8-1.2-2.9-1.2c-1.1,0-2.1,0.4-2.9,1.2c-0.8,0.8-1.2,1.8-1.2,2.9s0.4,2.1,1.2,2.9s1.8,1.2,2.9,1.2c1.1,0,2.1-0.4,2.9-1.2
         C136.6,25.6,136.9,24.6,136.9,23.5z"/>
       <path  style="fill: #A6A6A6;" d="M149.4,28.1h-1.1l-2-5.8l-2,5.8h-1.1l-1.9-8.4h-0.4c-0.2,0-0.4,0-0.5-0.1s-0.1-0.2-0.1-0.3s0-0.2,0.1-0.3
         s0.2-0.1,0.5-0.1h2.5c0.2,0,0.4,0,0.5,0.1s0.1,0.2,0.1,0.3s0,0.2-0.1,0.3s-0.2,0.1-0.5,0.1h-1.1l1.6,7.2l1.9-5.7h1.1l2,5.7
         l1.5-7.2h-1.1c-0.2,0-0.4,0-0.5-0.1s-0.1-0.2-0.1-0.3s0-0.2,0.1-0.3s0.3-0.1,0.5-0.1h2.5c0.2,0,0.4,0,0.5,0.1s0.1,0.2,0.1,0.3
         s0,0.2-0.1,0.3s-0.3,0.1-0.5,0.1h-0.4L149.4,28.1z"/>
     </g>
     <g>
       <path  style="fill: #5C5C5C;" d="M68.6,34.2c0.1-0.2,0.3-0.3,0.4-0.4c0.1-0.1,0.3-0.1,0.5-0.1c0.3,0,0.5,0.1,0.7,0.3s0.3,0.5,0.3,1v1.7
         c0,0.5-0.1,0.8-0.3,1C70,37.9,69.8,38,69.5,38s-0.5-0.1-0.6-0.2c-0.1-0.1-0.3-0.4-0.4-0.8c0-0.3-0.1-0.5-0.3-0.6
         c-0.3-0.3-0.6-0.5-1.1-0.7s-1-0.3-1.4-0.3c-0.6,0-1.1,0.1-1.7,0.4s-1,0.7-1.3,1.2S62,38.3,62,39.2v1.3c0,1,0.4,1.8,1,2.4
         s1.7,1,2.9,1c0.7,0,1.4-0.1,1.9-0.3c0.3-0.1,0.6-0.3,1-0.7c0.2-0.2,0.4-0.3,0.5-0.4s0.3-0.1,0.4-0.1c0.3,0,0.5,0.1,0.7,0.3
         s0.3,0.4,0.3,0.7s-0.1,0.6-0.4,0.9c-0.4,0.5-0.9,0.8-1.5,1.1C68,45.8,67,45.9,66,45.9c-1.2,0-2.3-0.2-3.2-0.7
         c-0.8-0.4-1.4-1-2-1.9S60,41.5,60,40.5V39c0-1,0.2-2,0.7-2.9s1.2-1.6,2-2.1s1.8-0.7,2.7-0.7c0.6,0,1.1,0.1,1.6,0.2
         C67.6,33.7,68.2,34,68.6,34.2z"/>
       <path  style="fill: #5C5C5C;" d="M79.1,32.8v10.7h2.4c0.5,0,0.8,0.1,1,0.3s0.3,0.4,0.3,0.7s-0.1,0.5-0.3,0.7s-0.5,0.3-1,0.3h-6.8
         c-0.5,0-0.8-0.1-1-0.3s-0.3-0.4-0.3-0.7s0.1-0.5,0.3-0.7s0.5-0.3,1-0.3h2.4v-8.7h-1.6c-0.5,0-0.8-0.1-1-0.3s-0.4-0.4-0.4-0.7
         s0.1-0.5,0.3-0.7s0.5-0.3,1-0.3H79.1z"/>
       <path  style="fill: #5C5C5C;" d="M92.5,45.5V45c-0.5,0.3-1.1,0.5-1.7,0.6s-1.2,0.2-1.7,0.2c-1.1,0-1.9-0.3-2.6-0.9s-1-1.2-1-1.9
         c0-0.8,0.4-1.6,1.3-2.3s2-1.1,3.5-1.1c0.6,0,1.3,0.1,2.1,0.2v-0.5c0-0.3-0.1-0.5-0.4-0.7s-0.7-0.3-1.5-0.3c-0.6,0-1.4,0.1-2.3,0.4
         c-0.4,0.1-0.6,0.1-0.8,0.1c-0.3,0-0.5-0.1-0.7-0.3s-0.3-0.4-0.3-0.7c0-0.2,0-0.3,0.1-0.4c0.1-0.1,0.2-0.2,0.3-0.3s0.4-0.2,0.7-0.3
         c0.5-0.1,1-0.2,1.5-0.3s1-0.1,1.4-0.1c1.2,0,2.2,0.3,2.9,0.8s1,1.3,1,2.2v4.1h0.3c0.5,0,0.8,0.1,1,0.3s0.3,0.4,0.3,0.7
         s-0.1,0.5-0.3,0.7s-0.5,0.3-1,0.3H92.5z M92.5,42c-0.8-0.2-1.5-0.2-2.2-0.2c-0.8,0-1.5,0.2-2.1,0.6c-0.4,0.3-0.5,0.5-0.5,0.8
         c0,0.2,0.1,0.3,0.3,0.5c0.3,0.2,0.8,0.3,1.3,0.3s1-0.1,1.6-0.3s1.2-0.4,1.6-0.8C92.5,42.8,92.5,42,92.5,42z"/>
       <path  style="fill: #5C5C5C;" d="M105.4,39c-0.3-0.2-0.7-0.4-1-0.5s-0.7-0.2-1.1-0.2c-0.8,0-1.4,0.1-1.8,0.4c-0.3,0.2-0.4,0.3-0.4,0.5
         c0,0.1,0.1,0.3,0.4,0.4c0.2,0.1,0.7,0.2,1.4,0.3c1.3,0.2,2.3,0.4,2.8,0.5c0.7,0.2,1.2,0.6,1.6,1.1s0.6,1,0.6,1.5
         c0,0.7-0.3,1.3-0.9,1.8c-0.9,0.7-2.1,1-3.5,1c-0.6,0-1.1-0.1-1.6-0.2s-0.9-0.2-1.4-0.4c-0.1,0.1-0.2,0.1-0.3,0.2s-0.2,0.1-0.3,0.1
         c-0.3,0-0.6-0.1-0.7-0.3s-0.3-0.5-0.3-1v-0.7c0-0.5,0.1-0.8,0.3-1s0.4-0.3,0.7-0.3c0.2,0,0.4,0.1,0.6,0.2s0.3,0.4,0.4,0.7
         c0.3,0.3,0.7,0.5,1.1,0.6s0.9,0.2,1.5,0.2c0.9,0,1.6-0.1,2.1-0.4c0.2-0.1,0.4-0.3,0.4-0.4c0-0.3-0.2-0.5-0.5-0.6s-1.1-0.3-2.1-0.4
         c-1.6-0.2-2.7-0.5-3.2-1s-0.8-1.1-0.8-1.8s0.3-1.3,0.9-1.8c0.8-0.7,1.9-1,3.2-1c0.5,0,0.9,0,1.3,0.1s0.8,0.2,1.2,0.4
         c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.1,0.3-0.1c0.3,0,0.5,0.1,0.7,0.3s0.3,0.5,0.3,1v0.5c0,0.4-0.1,0.7-0.2,0.9
         c-0.2,0.3-0.5,0.4-0.8,0.4c-0.2,0-0.5-0.1-0.6-0.2C105.9,39.4,105.5,39.2,105.4,39z"/>
       <path  style="fill: #5C5C5C;" d="M118,39c-0.3-0.2-0.7-0.4-1-0.5s-0.7-0.2-1.1-0.2c-0.8,0-1.4,0.1-1.8,0.4c-0.2,0.1-0.3,0.2-0.3,0.4
         c0,0.1,0.1,0.3,0.4,0.4c0.2,0.1,0.7,0.2,1.4,0.3c1.3,0.2,2.3,0.4,2.8,0.5c0.7,0.2,1.2,0.6,1.6,1.1s0.6,1,0.6,1.5
         c0,0.7-0.3,1.3-0.9,1.8c-0.9,0.7-2.1,1-3.5,1c-0.6,0-1.1-0.1-1.6-0.2s-0.9-0.2-1.4-0.4c-0.1,0.1-0.2,0.1-0.3,0.2s-0.2,0.1-0.3,0.1
         c-0.3,0-0.6-0.1-0.7-0.3s-0.3-0.5-0.3-1v-0.7c0-0.5,0.1-0.8,0.3-1s0.4-0.3,0.7-0.3c0.2,0,0.4,0.1,0.6,0.2s0.3,0.4,0.4,0.7
         c0.3,0.3,0.7,0.5,1.1,0.6s0.9,0.2,1.5,0.2c0.9,0,1.6-0.1,2.1-0.4c0.2-0.1,0.4-0.3,0.4-0.4c0-0.3-0.2-0.5-0.5-0.6S117,42,116,41.9
         c-1.6-0.2-2.7-0.5-3.2-1s-0.8-1.1-0.8-1.8s0.3-1.3,0.9-1.8c0.8-0.7,1.9-1,3.2-1c0.5,0,0.9,0,1.3,0.1s0.8,0.2,1.2,0.4
         c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.1,0.3-0.1c0.3,0,0.5,0.1,0.7,0.3s0.3,0.5,0.3,1v0.5c0,0.4-0.1,0.7-0.2,0.9
         c-0.2,0.3-0.5,0.4-0.8,0.4c-0.2,0-0.5-0.1-0.6-0.2C118.6,39.3,118.1,39.2,118,39z"/>
       <path  style="fill: #5C5C5C;" d="M128,36.7v1.2c0.8-0.6,1.5-1,2-1.2s0.9-0.3,1.4-0.3c0.7,0,1.3,0.2,1.9,0.7c0.4,0.3,0.6,0.7,0.6,1
         s-0.1,0.5-0.3,0.7s-0.4,0.3-0.7,0.3c-0.2,0-0.5-0.1-0.8-0.4s-0.5-0.4-0.7-0.4c-0.3,0-0.7,0.2-1.3,0.5s-1.3,0.9-2.1,1.6v3h2.9
         c0.5,0,0.8,0.1,1,0.3s0.3,0.4,0.3,0.7s-0.1,0.5-0.3,0.7s-0.5,0.3-1,0.3h-6c-0.5,0-0.8-0.1-1-0.3s-0.3-0.4-0.3-0.7s0.1-0.5,0.3-0.7
         s0.5-0.3,1-0.3h1.2v-4.9h-0.7c-0.5,0-0.8-0.1-1-0.3s-0.3-0.4-0.3-0.7s0.1-0.5,0.3-0.7s0.5-0.3,1-0.3h2.6
         C128,36.5,128,36.7,128,36.7z"/>
       <path  style="fill: #5C5C5C;" d="M146.2,41.2c0,0.8-0.2,1.5-0.6,2.2s-1.1,1.3-1.9,1.7s-1.7,0.6-2.6,0.6c-0.9,0-1.8-0.2-2.6-0.6s-1.5-1-1.9-1.7
         s-0.7-1.5-0.7-2.3s0.2-1.6,0.7-2.4s1.1-1.4,1.9-1.8c0.8-0.4,1.7-0.7,2.6-0.7c0.9,0,1.8,0.2,2.6,0.7s1.5,1.1,1.9,1.8
         C146,39.5,146.2,40.5,146.2,41.2z M144.2,41.2c0-0.6-0.2-1.2-0.7-1.8c-0.6-0.7-1.5-1.1-2.5-1.1c-0.9,0-1.7,0.3-2.3,0.9
         c-0.6,0.6-0.9,1.3-0.9,2c0,0.6,0.3,1.2,0.9,1.8s1.4,0.8,2.3,0.8c0.9,0,1.7-0.3,2.3-0.8S144.2,41.9,144.2,41.2z"/>
       <path style="fill: #5C5C5C;" d="M158.9,41.2c0,0.8-0.2,1.5-0.6,2.2s-1.1,1.3-1.9,1.7s-1.7,0.6-2.6,0.6c-0.9,0-1.8-0.2-2.6-0.6s-1.5-1-1.9-1.7
         s-0.7-1.5-0.7-2.3s0.2-1.6,0.7-2.4s1.1-1.4,1.9-1.8c0.8-0.4,1.7-0.7,2.6-0.7c0.9,0,1.8,0.2,2.6,0.7s1.5,1.1,1.9,1.8
         C158.6,39.5,158.9,40.5,158.9,41.2z M156.9,41.2c0-0.6-0.2-1.2-0.7-1.8c-0.6-0.7-1.5-1.1-2.5-1.1c-0.9,0-1.7,0.3-2.3,0.9
         c-0.6,0.6-0.9,1.3-0.9,2c0,0.6,0.3,1.2,0.9,1.8s1.4,0.8,2.3,0.8c0.9,0,1.7-0.3,2.3-0.8S156.9,41.9,156.9,41.2z"/>
       <path style="fill: #5C5C5C;" d="M163.2,36.7v0.6c0.3-0.3,0.6-0.5,0.9-0.7s0.6-0.2,0.9-0.2c0.3,0,0.6,0.1,0.9,0.2c0.3,0.1,0.6,0.4,0.8,0.6
         c0.3-0.3,0.7-0.5,1-0.6s0.7-0.2,1.1-0.2c0.7,0,1.3,0.2,1.8,0.6c0.6,0.5,0.9,1.2,0.9,2.1v4.5c0.4,0,0.7,0.1,0.9,0.3
         s0.3,0.4,0.3,0.7s-0.1,0.5-0.3,0.7s-0.5,0.3-1,0.3h-1.9v-6.3c0-0.3-0.1-0.5-0.2-0.6c-0.1-0.1-0.3-0.2-0.5-0.2s-0.4,0.1-0.6,0.2
         c-0.2,0.2-0.5,0.5-0.9,0.9v4c0.4,0,0.7,0.1,0.9,0.3s0.3,0.4,0.3,0.7s-0.1,0.5-0.3,0.7s-0.5,0.3-1,0.3h-1.9v-6.3
         c0-0.3-0.1-0.5-0.2-0.6c-0.1-0.1-0.3-0.2-0.5-0.2s-0.5,0.1-0.7,0.2c-0.2,0.1-0.5,0.4-0.8,0.9v4c0.4,0,0.7,0.1,0.9,0.3
         s0.3,0.4,0.3,0.7s-0.1,0.5-0.3,0.7s-0.5,0.3-1,0.3h-1.8c-0.5,0-0.8-0.1-1-0.3s-0.3-0.4-0.3-0.7s0.1-0.5,0.3-0.7s0.5-0.3,0.9-0.3
         v-4.9c-0.4,0-0.7-0.1-0.9-0.3s-0.3-0.4-0.3-0.7s0.1-0.5,0.3-0.7s0.5-0.3,1-0.3C161.1,36.6,163.2,36.6,163.2,36.7z"/>
     </g>
   </g>
   </svg>`
      var elementClone=element.cloneNode(true);
      elementClone['id']='creator-scrollable-div';
      const descImgToExport=elementClone['querySelectorAll']('#creator-scrollable-div .desc-preview-img');
      if(descImgToExport.length){
        for (let index = 0; index < descImgToExport.length; index++) {
          descImgToExport[index]['classList'].add('exported-description-img');
        }
      }
      const typeName=document.createElement('div');
      typeName.classList.add('cloned-formname-type')
      const header=document.getElementById('lblFormTitle');
      const cloneHeader=header.cloneNode(true);
      if(this.formObject.type==FormType.POLL){
        typeName.innerHTML=this.CurrentLangStrings.default.ed.poll;
      }
      else{
        typeName.innerHTML=this.CurrentLangStrings.default.ed.quiz;
      }
      cloneHeader['classList'].add('cloned-formname');
      parentDiv.append(cloneHeader,typeName);
      elementClone.appendChild(logoDiv);
      elementClone.appendChild(parentDiv);
      elementClone.insertBefore(parentDiv,elementClone.firstChild);
      elementClone.insertBefore(logoDiv,parentDiv);
      const opt = {
        margin: 1,
        filename:  header.innerHTML + '-' + new Date().toLocaleDateString() + "@" + new Date().toLocaleTimeString() + '.pdf',
        image: { type: 'jpeg', quality: 1 },
        html2canvas: { scale: 2, useCORS: true },
        jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' }
      };
      setTimeout(async () => {
        await html2pdf().from(elementClone).set(opt).save();
        this.btnLoader=false;
        elementClone=null;
      });
    } catch (error) {

    }

  }
}
