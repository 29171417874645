/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./survey.analytics.datatables";
var styles_SurveyAnalyticsDatatablesComponent = [];
var RenderType_SurveyAnalyticsDatatablesComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SurveyAnalyticsDatatablesComponent, data: {} });
export { RenderType_SurveyAnalyticsDatatablesComponent as RenderType_SurveyAnalyticsDatatablesComponent };
export function View_SurveyAnalyticsDatatablesComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["id", "surveyAnalyticsDatatablesContainer"]], null, null, null, null, null))], null, null); }
export function View_SurveyAnalyticsDatatablesComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "survey-analytics-datatables", [], null, null, null, View_SurveyAnalyticsDatatablesComponent_0, RenderType_SurveyAnalyticsDatatablesComponent)), i0.ɵdid(1, 114688, null, 0, i1.SurveyAnalyticsDatatablesComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SurveyAnalyticsDatatablesComponentNgFactory = i0.ɵccf("survey-analytics-datatables", i1.SurveyAnalyticsDatatablesComponent, View_SurveyAnalyticsDatatablesComponent_Host_0, { json: "json" }, { surveySaved: "surveySaved" }, []);
export { SurveyAnalyticsDatatablesComponentNgFactory as SurveyAnalyticsDatatablesComponentNgFactory };
